import React from 'react';
import { Card, CardContent, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { BigArrow } from 'app/utils/icon/icon';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useMutation, useQueryClient } from 'react-query';
import authServices from 'app/services/auth/auth.service';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';

const MySwal = withReactContent(Swal)


const ForgotPassword = () => {
    const isMobile = useMediaQuery('(max-width:575px)');
    const { t, i18n } = useTranslation();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [hasMobile, sethasMobile] = React.useState(true);

    const { mutate, isLoading } = useMutation(authServices.forgotPassword, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                data.content.otp_details.registrationWith = hasMobile ? "MOBILE_NO" : "EMAIL";
                navigate("/auth/reset-password", {
                    state: data?.content
                })
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            username: "",
            usernameBy: ""
        },
        onSubmit: values => {
            values.usernameBy = hasMobile ? "MOBILE_NO" : "EMAIL";
            mutate(values)
        }
    })

    return (
        <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: !isMobile && 4 }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#FBAD1D`
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: { md: 320 }
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography fontFamily={getFont(i18n)} variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>
                                {t("forgot-pass.title")}
                            </Typography>
                            <Typography fontFamily={getFont(i18n)} variant={"body1"} mb={1} sx={{ maxWidth: 270 }}>
                                {hasMobile ? t("forgot-pass.p_mobile") : t("forgot-pass.p")}
                            </Typography>
                        </Div>
                        <Div sx={{ mt: 'auto', position: "relative" }}>
                            <Div style={{ transform: "rotate(180deg)", paddingLeft: "130px" }}>
                                <BigArrow height={207} width={167} fill={"white"} />
                            </Div>
                            <Link to="/" component={RouterLink} underline="none" style={{ position: "absolute", top: "95px", left: "15px" }}>
                                <img src={`${ASSET_IMAGES}/logo.png`} height={25} alt="Jumbo React" />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <Div sx={{ mb: 2 }}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={hasMobile}
                                    exclusive
                                    fullWidth
                                    onChange={() => {
                                        sethasMobile(!hasMobile);
                                        formik.setFieldValue("hasMobile", !hasMobile);
                                    }}
                                    aria-label="Platform"
                                    sx={{ mb: 2, borderColor: "#FBAD1D" }}
                                >
                                    <ToggleButton value={true} sx={{ borderColor: hasMobile && "#FBAD1D", fontFamily: getFont(i18n) }} >{t("label.mobile")}</ToggleButton>
                                    <ToggleButton value={false} sx={{ borderColor: !hasMobile && "#FBAD1D", fontFamily: getFont(i18n) }}>{t("placeholder.email")}</ToggleButton>
                                </ToggleButtonGroup>
                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <TextField
                                        fullWidth
                                        id="email-address"
                                        label={hasMobile ? t("label.mobile") : t("placeholder.email")}
                                        placeholder={hasMobile ? t("placeholder.mobile") : t("placeholder.reg-email")}
                                        {...formik.getFieldProps('username')}
                                        inputProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                    />
                                </Div>
                                <LoadingButton fullWidth loading={isLoading} disabled={!formik.isValid || Object.keys(formik.touched).length == 0} sx={{ fontFamily: getFont(i18n) }} type="submit" variant="contained">{t("button.send")}</LoadingButton>
                            </Div>
                        </form>
                        <Typography fontFamily={getFont(i18n)} variant={"body1"} mt={'auto'}>
                            {t("signup.col1.p2")} <Link to={"/auth/login"} component={RouterLink} fontFamily={getFont(i18n)} ml={1} mr={1} underline={'none'}>{t("button.login")}</Link> {i18n.language == "bn-BD" ? "অথবা" : "Or"} <Link
                                to={"/auth/signup/step-1"} component={RouterLink} fontFamily={getFont(i18n)} ml={1} underline={'none'}>{t("button.create New")}</Link>
                        </Typography>
                    </Div>
                </CardContent>
            </Card>

        </Div>
    );
};

export default ForgotPassword;
