import Div from '@jumbo/shared/Div';
import { LoadingButton } from '@mui/lab';
import { Button, TextField, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import authServices from 'app/services/auth/auth.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const MySwal = withReactContent(Swal)

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
    mobileNo: yup
        .string()
        .min(10, "Mobile number must be at least 11 characters")
        .matches(phoneRegExp, 'Mobile number is not valid')
        .when("registrationWith", {
            is: "MOBILE_NO",
            then: yup
                .string()
                .required('Mobile number is required'),
        }),

    regEmail: yup
        .string('Enter your email address')
        .email("Email address is invalid")
        .when("registrationWith", {
            is: "EMAIL",
            then: yup
                .string()
                .required('Email address is required'),
        }),
});

export const NumberVerification = ({ responseData, setResponseData, hasMobile, sethasMobile }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutate, isLoading, data } = useMutation(authServices.sendOTPtoMobile, {
        onSuccess: (data) => {
            setResponseData(data?.content);
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                navigate("/auth/signup/step-2");
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            mobileNo: "",
            regEmail: "",
            password: "",
            secretKey: "",
            registrationWith: "MOBILE_NO"
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            hasMobile ? delete formik.values.regEmail : delete formik.values.mobileNo;
            console.log(values);
            mutate(values)
        }
    })

    return (
        <Div sx={{ mt: 3 }}>
            <form onSubmit={formik.handleSubmit}>
                <Typography fontFamily={getFont(i18n)} variant='h6' sx={{ mt: 3 }}>
                    {t("signup.col2.title")}
                </Typography>
                <Typography fontFamily={getFont(i18n)} variant='caption' color={"#A2B2C3"}>
                    {t("signup.auth")}
                </Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={hasMobile}
                    exclusive
                    fullWidth
                    onChange={() => {
                        sethasMobile(!hasMobile);
                        formik.setFieldValue("registrationWith", hasMobile ? "EMAIL" : "MOBILE_NO");
                        formik.setFieldValue("mobileNo", "");
                        formik.setFieldValue("regEmail", "");
                    }}
                    aria-label="Platform"
                    sx={{ mb: 2, borderColor: "#FBAD1D" }}
                >
                    <ToggleButton value={true} disabled={hasMobile} sx={{ borderColor: hasMobile && "#FBAD1D", fontFamily: getFont(i18n) }} >{t("label.mobile")}</ToggleButton>
                    <ToggleButton value={false} disabled={!hasMobile} sx={{ borderColor: !hasMobile && "#FBAD1D", fontFamily: getFont(i18n) }}>{t("placeholder.email")}</ToggleButton>
                </ToggleButtonGroup>
                <Typography fontFamily={getFont(i18n)} variant='caption' color={"#A2B2C3"}>
                    {hasMobile ? t("signup.col2.p") : t("signup.col2.p_email")}
                </Typography>
                <TextField
                    fullWidth
                    sx={{ mt: 2, mb: 3 }}
                    inputProps={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    type={hasMobile ? "text" : "email"}
                    label={hasMobile ? t("label.mobile") : t("placeholder.email")}
                    placeholder={hasMobile ? t("placeholder.mobile") : t("placeholder.reg-email")}
                    error={hasMobile ? formik.touched.mobileNo && formik.errors.mobileNo ? true : false : formik.touched.regEmail && formik.errors.regEmail ? true : false}
                    helperText={hasMobile ? formik.errors.mobileNo : formik.errors.regEmail}
                    {...formik.getFieldProps(hasMobile ? 'mobileNo' : 'regEmail')}
                />
                <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ fontFamily: getFont(i18n) }}
                    disabled={!formik.dirty || formik.errors.mobileNo || formik.errors.regEmail}
                    loading={isLoading}>
                    {t("button.continue")}
                </LoadingButton>
            </form>
        </Div>
    )
}