import React from 'react';
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import Div from "@jumbo/shared/Div";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import Badge from "@mui/material/Badge";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import useChatApp from "../../hooks/useChatApp";
import { useLocation, useNavigate } from "react-router-dom";
import ContactDetail from '../ContactDetail';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { users } from 'app/services/mock-data/chats';
import { useTranslation } from 'react-i18next';
import returneeServices from 'app/services/returnee.service';
import { useQuery } from 'react-query';
import JumboSearch from '@jumbo/components/JumboSearch/JumboSearch';

const ActiveConversationHeader = ({ onSearch }) => {
    const { activeConversation } = useChatApp();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const location = useLocation();
    const md = useMediaQuery(theme.breakpoints.down('md'));

    const handleCloseConversation = () => {
        navigate(`/app/chats`);
    }

    const { showDialog, hideDialog } = useJumboDialog();

    const showContactDetail = React.useCallback(() => {
        showDialog({
            content: <ContactDetail contact={location?.state?.connectedWithUserProfileId} onClose={hideDialog} />
        })
    }, [showDialog, location?.state?.connectedWithUserProfileId]);

    if (!location?.state)
        return null;
    return (
        <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                borderBottom: 1,
                borderBottomColor: 'divider',
                p: theme => theme.spacing(2, 3),
            }}
        >
            {
                md &&
                <IconButton aria-label="Previous" sx={{ mr: 2 }} onClick={handleCloseConversation}>
                    <ArrowBackIcon />
                </IconButton>
            }
            <Div
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flex: 1,
                    mr: 2
                }}
            >
                <Avatar
                    alt={location?.state?.connectedWithName}
                    src={location?.state?.logoImageServer ? location?.state?.logoImageServer : ""}
                    sx={{ mr: 2 }}
                />
                <Stack direction={"row"} alignItems={"center"} >
                    <Typography variant={'h5'} mb={.5}>
                        {location?.state?.connectedWithName}
                        {/* <StarOutlinedIcon
                            sx={{
                                color: theme => theme.palette.primary.main,
                                verticalAlign: 'middle',
                                fontSize: 20,
                                ml: 1,
                                mt: '-4px'
                            }}
                        /> */}
                    </Typography>
                    {/* <Typography variant={"body1"} color={'text.secondary'} sx={{ textTransform: 'capitalize' }}>
                        <Badge
                            overlap="circular"
                            variant="dot"
                            sx={{
                                m: theme => theme.spacing(-.25, 2, 0, 1),

                                '& .MuiBadge-badge': {
                                    height: 10,
                                    width: 10,
                                    borderRadius: '50%',
                                    backgroundColor: (
                                        activeConversation?.contact?.status === 'offline'
                                            ? '#afa8a8'
                                            : activeConversation?.contact?.status === 'online' ? '#72d63a' : '#F7BB07'
                                    )
                                }
                            }}
                        />
                        {activeConversation?.contact?.status}
                    </Typography> */}
                </Stack>
            </Div>
            <Stack direction={"row"} alignItems={"center"}>
                <JumboSearch onChange={(value) => onSearch(value)} />
                <JumboDdMenu
                    menuItems={[
                        { title: i18n.language == "bn-BD" ? "প্রফাইল দেখুন" : "View Profile", slug: "view-profile" },
                        { title: i18n.language == "bn-BD" ? "ব্লক করুন" : "Block", slug: "block" }
                    ]}
                    onClickCallback={showContactDetail}
                />
            </Stack>
        </Div>
    );
};

export default ActiveConversationHeader;
