import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import Div from '@jumbo/shared/Div';
import Span from '@jumbo/shared/Span';
import { Chip, Divider, Grid, Grow, Typography, useMediaQuery } from '@mui/material';
import SearchBox from 'app/shared/SearchBox';
import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useJumboTheme } from '@jumbo/hooks';
import OrganisationList from '../home/components/OrganisationList';
import AlphabetFilter from './components/AlphabetFilter';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const ServiceProviderList = () => {
    const { theme } = useJumboTheme();
    const { t, i18n } = useTranslation();
    const showDropdownSearch = useMediaQuery('(max-width:575px)');


    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
            <Div sx={{ mx: "8vw", background: "#FFFFFF" }}>
                <Div sx={{ px: showDropdownSearch ? "2vw" : "8vw", py: "50px", mb: 5, background: "#F2F6FD", display: "flex", flexDirection: "column", mx: "auto" }}>
                    <Typography fontFamily={getFont(i18n)} fontSize={"28px"} fontWeight={600} color={"#0C4CA3"} letterSpacing={"-1px"} lineHeight={"35px"} textAlign={"center"}>
                        {i18n.language == "bn-BD" ? "ব্রাউজ" : "Browse"} <Span sx={{ color: "#FBAD1D" }}>{t("home.body.title.service-provider")}</Span>
                    </Typography>
                    <Typography fontFamily={getFont(i18n)} variant='h6' mt={1} px={5} textAlign={"center"}>
                        <strong>{t("title")}</strong>{t("home.description")}
                    </Typography>
                    {/* <Div sx={{ mt: 5 }}>
                        <AlphabetFilter />
                    </Div> */}
                </Div>

                <Div sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography fontFamily={getFont(i18n)} variant='h6' color={"#999999"}>
                        {i18n.language == "bn-BD" ? "সকল সেবাপ্রদানকারী দেখানো হচ্ছে" : "Showing all service providers"}
                    </Typography>
                </Div>

                <Divider sx={{ my: 2 }} />

                <OrganisationList length={48} />

            </Div>
        </Grow>
    )
}

export default ServiceProviderList;