import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useRef } from 'react';

const FileUploader = ({ file, setFile }) => {
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Perform file upload logic or any other necessary operations with the selected file
        console.log(selectedFile);
        setFile(selectedFile);
    };

    return (
        <div style={{
            position: "absolute",
            right: "-13px",
            marginTop: "-3px",
            zIndex: 10,
            background: "#F5F7FA",
            borderRadius: "50%",
            border: "3px solid #FBAD1D"
        }}>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <IconButton color="primary" aria-label="Change profile picture" onClick={handleUploadClick}>
                <Edit />
            </IconButton>
        </div>
    );
};

export default FileUploader;
