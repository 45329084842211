import { axiosIns } from "app/config/apiConfig";

const getCategoryList = async () => {
    const response = await axiosIns.get(`/icmpd/services/category/list`);
    return response.data;
};

const createCategory = async (data) => {
    const response = await axiosIns.post(`/services/category/save`, data);
    return response.data;
};

const updateCategory = async ({ id, isUpdateParent, data }) => {
    const response = await axiosIns.post(`/services/category/save/${id}/${isUpdateParent}`, data);
    return response.data;
};

const deleteCategory = async (id) => {
    const response = await axiosIns.post(`/services/category/delete/${id}`);
    return response.data;
};


const categoryServices = {
    getCategoryList,
    createCategory,
    updateCategory,
    deleteCategory
};

export default categoryServices;