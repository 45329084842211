import React, { useState, useEffect } from 'react';
import Div from '@jumbo/shared/Div/Div';
import { AddCircleOutline, Close, ContentCopy, DragHandle, ModeEdit, RemoveCircleOutline } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import FieldComponent from './FieldComponent';
import Swal from 'sweetalert2'
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { useDrop } from 'react-dnd'

const DragHandleCmp = sortableHandle(() => <span><DragHandle /></span>);

const FieldConfig = SortableElement(({ item, i, fieldList, setfieldList }) => {

    const [update, setupdate] = useState(0);
    const [openAccordion, setopenAccordion] = useState(null);

    const updateState = (index, field, value) => {
        const newArray = fieldList.map((item, i) => {
            if (index === i) {
                return { ...item, [field]: value };
            } else {
                return item;
            }
        });
        setfieldList(newArray);
    };

    const copyField = (item, index) => {
        setfieldList(current => [
            ...current.slice(0, index),
            item,
            ...current.slice(index),
        ]);
    }

    const removeField = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setfieldList(current => current.filter((_, i) => i !== index));
                Swal.fire(
                    'Deleted!',
                    'Field has been removed.',
                    'success'
                )
            }
        })
    }

    const updateOptionState = (parent, index, field, value) => {
        setfieldList(current => {
            current[parent].options = current[parent].options.map((item, i) => {
                if (index === i) {
                    return { ...item, [field]: value };
                } else {
                    return item;
                }
            });
            setupdate(update + 1);
            return current;
        })
    };

    const addOption = (parent, index) => {
        setfieldList(current => {
            console.log(current[parent].options.slice(0, index));
            current[parent].options = [
                ...current[parent].options.slice(0, index),
                {
                    label: "",
                    value: ""
                },
                ...current[parent].options.slice(index),
            ]
            setupdate(update + 1)
            return current;
        });
    }

    const removeOption = (parent, index) => {
        setfieldList(current => {
            current[parent].options = current[parent].options.filter((_, i) => i !== index)
            setupdate(update + 1)
            return current;
        });
    }

    return (
        <Accordion sx={{ mb: 2 }} className='formField' expanded={openAccordion === i ? true : false}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <AccordionActions>
                    <DragHandleCmp />
                </AccordionActions>
                <AccordionSummary sx={{ width: "100%", paddingTop: "10px" }}>
                    <FieldComponent
                        label={item?.fieldLabel}
                        inputType={item?.inputType}
                        fieldType={item?.fieldType}
                        placeHolder={item?.placeHolder}
                        helpText={item?.helperText}
                        options={item?.options}
                        maxRows={item?.maxRows}
                    />
                </AccordionSummary>
                <AccordionActions>
                    <IconButton
                        color='error'
                        onClick={() => removeField(i)}
                    >
                        <Close />
                    </IconButton>
                    <IconButton
                        onClick={() => setopenAccordion(openAccordion === i ? null : i)}
                    >
                        <ModeEdit />
                    </IconButton>
                    <IconButton
                        onClick={() => copyField(item, i)}
                    >
                        <ContentCopy />
                    </IconButton>
                </AccordionActions>
            </Box>
            <AccordionDetails>
                <Div sx={{
                    mb: 3,
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    padding: "20px",
                    background: "#f5f5f5f7"
                }}>
                    <TextField
                        fullWidth
                        label={"Field Label"}
                        sx={{ mb: 3 }}
                        value={item.fieldLabel}
                        onChange={(e) => updateState(i, "fieldLabel", e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label={"Field Name"}
                        sx={{ mb: 3 }}
                        value={item.name}
                        onChange={(e) => updateState(i, "name", e.target.value)}
                    />
                    <FormControlLabel control={<Checkbox
                        onChange={(e) => updateState(i, "isRequired", e.target.checked)}
                        checked={item.isRequired}
                    />}
                        label="Required"
                        sx={{ mb: 3 }}
                    />
                    {
                        (item?.fieldType === "text" || item?.fieldType === "number") && (
                            <TextField
                                fullWidth
                                label={"Place Holder"}
                                sx={{ mb: 3 }}
                                value={item.placeHolder}
                                onChange={(e) => updateState(i, "placeHolder", e.target.value)}
                            />
                        )
                    }
                    {
                        (item?.fieldType === "textarea") && (
                            <TextField
                                fullWidth
                                type='number'
                                label={"Max Rows"}
                                sx={{ mb: 3 }}
                                value={item.maxRows}
                                onChange={(e) => updateState(i, "maxRows", e.target.value)}
                            />
                        )
                    }
                    {
                        item?.fieldType === "text" && (
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <InputLabel id={`demo-simple-select-label${i}`}>Input Type</InputLabel>
                                <Select
                                    labelId={`demo-simple-select-label${i}`}
                                    id={`demo-simple-select${i}`}
                                    label="Input Type"
                                    value={item.inputType}
                                    onChange={(e) => updateState(i, "inputType", e.target.value)}
                                >
                                    <MenuItem value={"text"}>Text Input</MenuItem>
                                    <MenuItem value={"email"}>Email Input</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }

                    {
                        item.fieldType === "select" || item.fieldType === "checkbox" || item.fieldType === "radio" ? (
                            <Div sx={{
                                border: "1px solid lightgray",
                                borderRadius: "10px",
                                padding: "20px",
                                background: "#ffffff"
                            }}>
                                {
                                    item?.options?.length != 0 ? item?.options?.map((option, j) => (
                                        <Grid container
                                            key={j}
                                            alignItems={"center"}
                                            marginBottom={"20px"}
                                            spacing={1}
                                        >
                                            <Grid item sm={12} md={3.5}>
                                                <TextField
                                                    fullWidth
                                                    label="Label"
                                                    value={option.label}
                                                    onChange={(e) => updateOptionState(i, j, "label", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={3.5} >
                                                <TextField
                                                    fullWidth
                                                    label="Value"
                                                    value={option.value}
                                                    onChange={(e) => updateOptionState(i, j, "value", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={5} >
                                                <Div sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Button
                                                        sx={{ mr: 2 }}
                                                        variant='contained'
                                                        color='error'
                                                        startIcon={<RemoveCircleOutline />}
                                                        disabled={fieldList[i]?.options?.length == 1 ? true : false}
                                                        onClick={() => removeOption(i, j)}
                                                    >
                                                        Remove
                                                    </Button>
                                                    <Button
                                                        variant='contained'
                                                        startIcon={<AddCircleOutline />}
                                                        onClick={() => addOption(i, j + 1)}
                                                    >
                                                        Add
                                                    </Button>
                                                </Div>
                                            </Grid>
                                        </Grid>
                                    )) : ""
                                }
                            </Div>
                        ) : ""
                    }

                    <Div sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                        {/* <Button
                                    sx={{ mr: 2 }}
                                    variant='contained'
                                    color='error'
                                    startIcon={<RemoveCircleOutline />}

                                >
                                    Remove
                                </Button>
                                <Button
                                    variant='contained'
                                    startIcon={<AddCircleOutline />}
                                    onClick={() => addField(i + 1)}
                                >
                                    Add
                                </Button> */}
                        <Button
                            variant='contained'
                            startIcon={<Close />}
                            onClick={() => setopenAccordion(null)}
                        >
                            Close
                        </Button>
                    </Div>
                </Div>
            </AccordionDetails>
        </Accordion>
    )
});

const FormComponent = SortableContainer(({ fieldList, setfieldList }) => {

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: "box",
        drop: () => ({ name: 'Dustbin' }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))
    const isActive = canDrop && isOver
    let backgroundColor = 'white'
    if (isActive) {
        backgroundColor = 'lightgray'
    } else if (canDrop) {
        backgroundColor = 'lightgray'
    }

    return (
        <div ref={drop} data-testid="dustbin" style={{ marginTop: "7px", minHeight: "410px" }}>
            {
                fieldList?.map((item, i) => (
                    <FieldConfig
                        i={i}
                        key={i}
                        index={i}
                        item={item}
                        fieldList={fieldList}
                        setfieldList={setfieldList}
                    />
                ))
            }
            {
                fieldList?.length === 0 && (
                    <Div sx={{ p: 3 }} style={{
                        backgroundColor,
                        border: '1px dashed gray',
                        minHeight: "385px",
                        textAlign: "center",
                        fontSize: "17px"
                    }} >
                        {isActive ? 'Release to drop' : 'Drag a field here'}
                    </Div>
                )
            }
        </div>
    )

})


export default FormComponent;