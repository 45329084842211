import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS, SIDEBAR_VIEWS } from "@jumbo/utils/constants";
import { headerTheme as theme4 } from "../../themes/header/theme4";
import { headerTheme as defaultTheme } from "../../themes/header/default";
import useApp from "../../hooks/useApp";
import layoutConfig from "./layoutConfig";
import Footer from "./Footer";
import Header from "./Header";

const PublicPage = ({ children }) => {
    const { setJumboLayoutOptions } = useJumboLayout();
    const { headerTheme, setHeaderTheme } = useJumboHeaderTheme();
    const { theme } = useJumboTheme();
    const appBarBgColor = headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background;
    const { sidebarOptions } = useJumboLayoutSidebar();
    const appData = useApp();


    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig)
    }, []);

    return (
        <JumboLayout
            header={<Header />}
            footer={<Footer />}
            headerSx={{
                height: 80,
                background: "transparent"
            }}
            publicPage={true}
        >
            {children}
        </JumboLayout>
    );
};

export default PublicPage;
