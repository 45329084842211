import Div from '@jumbo/shared/Div';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import authServices from 'app/services/auth/auth.service';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const MySwal = withReactContent(Swal)

const validationSchema = yup.object({
    wageEarnerName: yup
        .string()
        .required('Name is required'),
    password: yup
        .string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .required('Password is required'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export const SignupForm = ({ responseData }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { mutate, isLoading, data } = useMutation(authServices.createUser, {
        onSuccess: (data) => {
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                html: <i>{data?.message}</i>,
            })
            if (data?.statusCode == 200) {
                navigate("/auth/login");
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            wageEarnerName: "",
            mobileNo: "",
            password: "",
            confirmPassword: "",
            otpSecretKey: ""
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log(values);
            mutate(values)
        }
    })

    useEffect(() => {
        const temp = responseData;
        delete temp?.otpExpTime;
        delete temp?.otpExpTimeUnit;
        delete temp?.otpCode;
        formik.setValues(responseData);
    }, []);

    return (
        <Div sx={{ my: 4 }}>
            <form onSubmit={formik.handleSubmit}>
                <Typography fontFamily={getFont(i18n)} variant='h6' sx={{ mb: 3 }}>
                    {i18n.language == "bn-BD" ? "আপনার অ্যাকাউন্ট সেটআপ করুন" : "Setup Your Account"}
                </Typography>
                <Div sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        label={t("label.name")}
                        type="text"
                        inputProps={{
                            style: {
                                fontFamily: getFont(i18n)
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                fontFamily: getFont(i18n)
                            }
                        }}
                        placeholder={t("placeholder.edit-user-profile.contact.name")}
                        error={formik.touched.wageEarnerName && formik.errors.wageEarnerName ? true : false}
                        helperText={formik.errors.wageEarnerName}
                        {...formik.getFieldProps('wageEarnerName')}
                    />
                </Div>

                <Stack direction={"column"} sx={{ mb: 2, ml: 3 }} >
                    <Typography fontSize={16} variant={'caption'} color={'primary'}>Password Validation</Typography>
                    <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>1. Should be minimum 8 letters.</Typography>
                    <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>2. Require atleast a number.</Typography>
                    <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>3. Require atleast a lowercase letter.</Typography>
                    <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>4. Require atleast an uppercase letter.</Typography>
                </Stack>

                <Div sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        label={t("label.password")}
                        type="password"
                        inputProps={{
                            style: {
                                fontFamily: getFont(i18n)
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                fontFamily: getFont(i18n)
                            }
                        }}
                        placeholder={t("placeholder.password")}
                        error={formik.touched.password && formik.errors.password ? true : false}
                        helperText={formik.errors.password}
                        {...formik.getFieldProps('password')}
                    />
                </Div>
                <Div sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        id="confirm_password"
                        label={t("label.confirmPassword")}
                        placeholder={t("placeholder.confirmPassword")}
                        type="password"
                        inputProps={{
                            style: {
                                fontFamily: getFont(i18n)
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                fontFamily: getFont(i18n)
                            }
                        }}
                        error={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
                        helperText={formik.errors.confirmPassword}
                        {...formik.getFieldProps('confirmPassword')}
                    />
                </Div>
                <LoadingButton
                    fullWidth
                    sx={{ fontFamily: getFont(i18n) }}
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={!formik.dirty || (formik.errors.wageEarnerName && formik.errors.password && formik.errors.mobileNo)}
                    loading={isLoading}>
                    {i18n.language == "bn-BD" ? "সাইন আপ" : "Signup"}
                </LoadingButton>
            </form>
        </Div>
    )
}