import React from 'react';
import List from "@mui/material/List";
import MessageItem from "./MessageItem";
import messages from "./fake-data";
import { useQuery } from 'react-query';
import chatServices from 'app/services/chat.service';
import { Typography } from '@mui/material';

const MessagesList = ({ data }) => {


    return (
        <List disablePadding>
            {
                data?.content?.length > 0 ? data?.content?.map((item, index) => {
                    return (
                        <MessageItem key={index} item={item} />
                    )
                }) : (
                    <Typography variant='h5' color={"gray"} textAlign={"center"}>No New Message!</Typography>
                )
            }
        </List>
    );
};

export default MessagesList;
