import React from 'react';
import { Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, useMediaQuery } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { VisibilityOff } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import { BigArrow } from 'app/utils/icon/icon';
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useMutation, useQueryClient } from 'react-query';
import authServices from 'app/services/auth/auth.service';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';

const MySwal = withReactContent(Swal)

const ResetPassword = () => {
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery('(max-width:575px)');
    const [showPasswordNew, setShowPasswordNew] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickShowPasswordNew = () => setShowPasswordNew((show) => !show);
    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { mutate, isLoading } = useMutation(authServices.resetPassword, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                navigate("/auth/login")
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            passwordNew: "",
            userId: location.state?.userId,
            otpCode: "",
            otpSecretKey: "",
            registrationWith: ""
        },
        onSubmit: values => {
            mutate(values)
        }
    })

    React.useEffect(() => {
        if (!location.state) {
            navigate("/auth/forgot-password");
        } else {
            formik.setFieldValue('otpSecretKey', location.state?.otp_details?.otpSecretKey);
            formik.setFieldValue('registrationWith', location.state?.otp_details?.registrationWith);
        }
    }, []);

    return (
        <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: !isMobile && 4 }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        background: `#FBAD1D`,
                        position: 'relative',
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: { md: 320 }
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography fontFamily={getFont(i18n)} variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>
                                {t("button.resetPassword")}
                            </Typography>
                            {/* <Typography variant={"body1"} mb={1} sx={{ maxWidth: 270 }}>
                                Reset your password
                            </Typography> */}
                        </Div>
                        <Div sx={{ mt: 'auto', position: "relative" }}>
                            <Div style={{ transform: "rotate(180deg)", paddingLeft: "130px" }}>
                                <BigArrow height={207} width={167} fill={"white"} />
                            </Div>
                            <Link to="/" component={RouterLink} underline="none" style={{ position: "absolute", top: "95px", left: "15px" }}>
                                <img src={`${ASSET_IMAGES}/logo.png`} height={25} alt="Jumbo React" />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <Div sx={{ mb: 2 }}>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <Typography fontFamily={getFont(i18n)} variant='caption' color={"#A2B2C3"}>
                                        {t("signup.col2.p2")}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type={"text"}
                                        label={t("label.otp")}
                                        placeholder={t("placeholder.otp")}
                                        sx={{ mt: 2 }}
                                        inputProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                        {...formik.getFieldProps('otpCode')}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel style={{ fontFamily: getFont(i18n) }} htmlFor="outlined-adornment-password">{t("placeholder.change-password.new")}</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="outlined-adornment-password"
                                        inputProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                        type={showPasswordNew ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordNew}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={t("placeholder.change-password.new")}
                                        {...formik.getFieldProps('passwordNew')}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel style={{ fontFamily: getFont(i18n) }} htmlFor="outlined-adornment-password">{t("placeholder.change-password.confirm")}</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="outlined-adornment-password"
                                        inputProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                        type={showPasswordConfirm ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordConfirm}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={t("placeholder.change-password.confirm")}
                                    />
                                </FormControl>
                                <LoadingButton fullWidth loading={isLoading} type="submit" sx={{ m: 1, fontFamily: getFont(i18n) }} variant="contained">
                                    {t("button.resetPassword")}
                                </LoadingButton>
                            </Div>
                        </form>
                        {/* <Typography variant={"body1"} mt={'auto'}>
                            Already have an account? <Link href={"#/"} underline={'none'}>Login Now</Link> Or <Link
                                href={"#/"} underline={'none'}>Create New</Link>
                        </Typography> */}
                    </Div>
                </CardContent>
            </Card>

        </Div>
    );
};

export default ResetPassword;
