import React from 'react';
import StyledMenu from "../../../../../shared/StyledMenu";
import { foldersList } from "../../../../../services/mock-data/mails";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import FolderItem from "./FolderItem";
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const FoldersList = () => {
    const { t, i18n } = useTranslation();
    const { category } = useParams();
    return (
        <React.Fragment>
            <Typography
                fontFamily={getFont(i18n)}
                variant={"h6"}
                color={"text.secondary"}
                sx={{
                    textTransform: 'uppercase',
                    letterSpacing: '1px',
                    fontSize: '11px'
                }}
            >
                {i18n.language == "bn-BD" ? "ফোল্ডার" : "Folder"}
            </Typography>
            <StyledMenu sx={{ mb: 2 }}>
                {
                    foldersList.map((folder, index) => (
                        <FolderItem
                            key={index}
                            slug={folder.slug}
                            name={i18n.language == "bn-BD" ? folder.nameBn : folder.name}
                            icon={folder.icon}
                            selected={folder.slug === category}
                        />
                    ))
                }
            </StyledMenu>
        </React.Fragment>
    );
};

export default FoldersList;
