import { CorporateFare, PendingActions, Person2Outlined, WorkOutline } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import adminServices from 'app/services/admin.service';
import RevenueOverview from 'app/shared/metrics/RevenueOverview/RevenueOverview';
import SalesOverview from 'app/shared/metrics/SalesOverview/SalesOverview';
import Documents1 from 'app/shared/widgets/Documents1/Documents1';
import Ideas1 from 'app/shared/widgets/Ideas1/Ideas1';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

const AdimnDashboard = () => {

    const {data} = useQuery(['get-dashboard-data'], () => adminServices.getDashboardData());

    return (
        <Box>
            <Grid container spacing={3.75}>
                <Grid item xs={12} lg={7}>
                    <SalesOverview />
                </Grid>
                <Grid item xs={12} lg={2.5}>
                    <Grid container spacing={3.75}>
                        <Grid item xs={12} sm={6} lg={12}>
                            <Documents1
                                text={"Approval Pending"}
                                number={data?.content?.approvalPending}
                                icon={<PendingActions fontSize={"large"} />}
                                backgroundImage={'linear-gradient(135deg, #FBC79A, #D73E68)'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                            <Documents1
                                text={"Service Provider"}
                                number={data?.content?.serviceProvider}
                                icon={<CorporateFare fontSize={"large"} />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={2.5}>
                    <Grid container spacing={3.75}>
                        <Grid item xs={12} sm={6} lg={12}>
                            <Documents1
                                text={"Returnee"}
                                number={data?.content?.numberOfReturnee}
                                icon={<Person2Outlined fontSize={"large"} />}
                                backgroundImage={'linear-gradient(135deg, #17EAD9, #6078EA)'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                            <Documents1
                                text={"Services"}
                                number={data?.content?.services}
                                icon={<WorkOutline fontSize={"large"} />}
                                backgroundImage={'linear-gradient(135deg, #aaafe3, #dd8ce8);'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <RevenueOverview />
                </Grid>
            </Grid>
        </Box>
    )
}

export default AdimnDashboard