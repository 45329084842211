import React from "react";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

const menus = [
    {
        uri: "/user/profile",
        label: 'sidebar.menuItem.profile',
        type: "nav-item",
        icon: <AccountBoxOutlinedIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/app/chats",
        label: 'sidebar.menuItem.chat',
        type: "nav-item",
        icon: <ChatOutlinedIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/app/inbox",
        label: 'sidebar.menuItem.mail',
        type: "nav-item",
        icon: <EmailOutlinedIcon sx={{ fontSize: 20 }} />
    }
];

export default menus;
