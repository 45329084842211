import axios, { AxiosResponse } from "axios";
import { ACCESS_TOKEN } from "./localStorageConfig";


const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        // Accept: 'multipart/form-data',
        // "Content-type": 'multipart/form-data',
    },
})

instance.interceptors.request.use(
    (config) => {
        const accessToken = window.localStorage.getItem(ACCESS_TOKEN) || null
        // const tempToken = window.localStorage.getItem(TEMP_TOKEN) || null
        // const uid = window.localStorage.getItem(USER_ID) || null
        // const cid = window.localStorage.getItem(COMPANY_ID) || null

        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
        // if (uid) config.headers.uid = uid
        // if (cid) config.headers.cid = cid
        // if (tempToken) config.headers.token = tempToken

        return config
    },
    (error) => {
        if (error.response) {
            return Promise.reject({
                ...error.response.data,
                ...{ code: error.response.data.code || error.status },
            })
        }

        return Promise.reject({
            body: false,
            code: 404,
            msg: 'Server not responding',
        })
    }
)

instance.interceptors.response.use(
    (res) => {

        if (res.data.statusCode === 401 || res.statusCode === 401) {
            window.localStorage.clear();
            console.log(res.data);
            alert(res?.data?.message)
            window.location.assign("/");
        }

        if (res.data.status !== 401 || res.status !== 401) {
            return { ...res };
        }


        return Promise.reject({
            data: res.data,
            header: res.data.header,
            status: res.data.status,
            message: res.data.message,
        })
    },
    (error) => {
        console.log(error);
        if (error?.response) {
            if (error?.response?.data?.status === 401) {
                window.localStorage.clear();
                window.location.reload()
            }

            if (error?.response?.data?.error) {
                return Promise.reject({
                    status: error?.response?.data?.status,
                    message: error?.response?.data?.error,
                    data: {},
                    header: {},
                })
            }

            return Promise.reject({
                ...error?.response?.data,
                ...{ status: error.response.data.status || error.status },
            })
        } else {
            return Promise.reject({
                status: 500,
                message: 'Server not responding',
                data: {},
                header: {},
            })
        }
    }
)




export const axiosIns = instance
