import React, { useCallback, useState } from 'react';
import MessagesTriggerButton from "./MessagesTriggerButton";
import MessagesHeader from "./MessagesList/MessagesHeader";
import SettingHeader from "./MessagesSetting/SettingHeader";
import SettingsList from "./MessagesSetting/SettingsList";
import MessagesList from "./MessagesList";
import SearchMessages from "./SearchMessages";
import Divider from "@mui/material/Divider";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import { CardActions, Stack, ThemeProvider } from "@mui/material";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { ArrowBackOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AUTHORITY } from 'app/config/localStorageConfig';
import { useQuery } from 'react-query';
import chatServices from 'app/services/chat.service';

const MessagesDropdown = () => {
    const [showSettings, setShowSettings] = useState(false);
    const { theme } = useJumboTheme();
    const navigate = useNavigate();
    const [isUpdate, setisUpdate] = useState(0);

    const { data } = useQuery(['get-notification', isUpdate], () => chatServices.getNotifications(), {
        onSuccess: (data) => {
            setTimeout(() => {
                setisUpdate(prev => prev + 1);
            }, 60000);
        }
    })

    const toggleSettingWindow = useCallback(() => {
        setShowSettings(showSettings => !showSettings);
    }, [setShowSettings]);

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={<MessagesTriggerButton hasMsg={data?.content?.length > 0 ? true : false} />}
                disableInsideClick
            >
                {
                    showSettings ?
                        <Div sx={{ width: 360, maxWidth: '100%' }}>
                            <SettingHeader backClickCallback={toggleSettingWindow} />
                            <SettingsList />
                        </Div>
                        :
                        <Div sx={{ width: 360, maxWidth: '100%' }}>
                            <MessagesHeader settingMenuCallback={toggleSettingWindow} />
                            {/* <Div sx={{ m: 2, mt: 0 }}>
                                <SearchMessages />
                            </Div> */}
                            <MessagesList data={data} />
                            <Divider />
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <Stack direction={"row"} justifyContent={"space-between"} spacing={4} >
                                    {
                                        localStorage.getItem(AUTHORITY) != "PROVIDER" && (
                                            <Button
                                                sx={{
                                                    textTransform: "none",
                                                    fontWeight: 'normal',
                                                    '&:hover': { bgcolor: 'transparent' }
                                                }}
                                                onClick={() => navigate('/app/chats')}
                                                size={"small"} variant="text" startIcon={<ArrowBackOutlined />} disableRipple>
                                                View All Chats
                                            </Button>
                                        )
                                    }
                                    <Button
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: 'normal',
                                            '&:hover': { bgcolor: 'transparent' }
                                        }}
                                        onClick={() => navigate('/app/inbox')}
                                        size={"small"} variant="text" endIcon={<ArrowForwardIcon />} disableRipple>
                                        View All Emails
                                    </Button>
                                </Stack>
                            </CardActions>
                        </Div>
                }
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default MessagesDropdown;
