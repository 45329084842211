import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import Div from '@jumbo/shared/Div/Div';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import React, { useState, useEffect } from 'react';
import FieldComponent from '../service-provider/application/components/FieldComponent';
import { Button, Container, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import applicationServices from 'app/services/application.service';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal)

const ApplicationForm = () => {

    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [formData, setformData] = useState({});

    const { data, isLoading } = useQuery(['get-application-form', params?.id], () => applicationServices.getOneApplication(params?.id), {
        enabled: !!params?.id
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    console.log({ formData });

    const updateState = async (field, value, type) => {
        if (type === "file") {
            value = value ? await toBase64(value) : ""
        }
        if (type === "checkbox" && formData.hasOwnProperty(field)) {
            const newdata = { ...formData, [field]: formData[field].concat(", ", value) };
            setformData(newdata);
            formik.setFieldValue("valuesBody", newdata);
        } else {
            const newdata = { ...formData, [field]: value };
            setformData(newdata);
            formik.setFieldValue("valuesBody", newdata);
        }
    };

    const { mutate, isLoading: isLoadingSubmit } = useMutation(applicationServices.saveApplicationValue, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
            setTimeout(() => {
                if (data?.statusCode == 200) {
                    window.location.assign(`/service/details/${params?.serviceid}`)
                }
            }, 3000);
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            valuesBody: "",
            templateId: parseInt(params?.id),
            serviceId: parseInt(params?.serviceid)
        },
        onSubmit: values => {
            const newData = values;
            newData.valuesBody = JSON.stringify(formData);
            console.log({ newData });
            mutate({ data: newData });
        }
    })

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    return (
        <Container sx={{ my: 3 }}>
            <form onSubmit={formik.handleSubmit}>
                <JumboContentLayout
                    header={<PageHeader
                        title={data?.content?.templateName}
                        subheader={""} />}
                >
                    <Stack justifyContent={"center"} alignItems={"center"} >
                        {
                            data?.content?.templateBody && JSON.parse(data?.content?.templateBody)?.map((item, i) => (
                                <Div sx={{ mb: 3, minWidth: "300px", maxWidth: "600px", width: "100%" }} key={i}>
                                    <FieldComponent
                                        label={item?.fieldLabel}
                                        inputType={item?.inputType}
                                        fieldType={item?.fieldType}
                                        placeHolder={item?.placeHolder}
                                        helpText={item?.helperText}
                                        options={item?.options}
                                        maxRows={item?.maxRows}
                                        size='large'
                                        name={item?.name}
                                        onChange={(e) => updateState(item?.name, item?.fieldType == "file" ? e.target.files[0] : e.target.value, item?.fieldType)}
                                    />
                                </Div>
                            ))
                        }
                        <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                            <Button size='large' color='error' variant="outlined" onClick={() => navigate(params?.serviceid != 0 ? `/service/details/${params?.serviceid}` : `/service-provider/application-form`)} >
                                {params?.serviceid == "0" ? "Go back" : "Cancel"}
                            </Button>
                            <Button size='large' type="submit" variant="contained" disabled={params?.serviceid == 0 ? true : false} >
                                Submit
                            </Button>
                        </Stack>
                    </Stack>
                </JumboContentLayout>
            </form>
        </Container>
    )
}

export default ApplicationForm;