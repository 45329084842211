import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import Div from '@jumbo/shared/Div/Div';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress, Slide } from '@mui/material';
import publicServices from 'app/services/public/public.service';
import { getFont } from 'app/hooks/getFont';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const FAQ = () => {

    const { t, i18n } = useTranslation();
    const { data, isLoading } = useQuery(['get-faq'], () => publicServices.getfaq())

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Div sx={{ mx: "8vw", my: 3, background: "#FFFFFF" }}>
            <JumboContentLayout
                header={<PageHeader title={"Frequently Asked Questions"} noMargin={true} />}
            >
                {
                    isLoading ? (
                        <Div sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: "100%",
                            minHeight: "20vh"
                        }}>
                            <CircularProgress color={"warning"} sx={{ m: '-40px auto 0' }} />
                        </Div>
                    ) : data?.content?.map((item, i) => (
                        <Slide direction="left" in={true} timeout={i * 300} mountOnEnter unmountOnExit>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography fontFamily={getFont(i18n)}>{i18n.language == "bn-BD" ? item?.sectionHeaderTitleBn : item?.sectionHeaderTitle}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ fontFamily: getFont(i18n) }} dangerouslySetInnerHTML={{ __html: i18n.language == "bn-BD" ? item?.sectionBodyBn : item?.sectionBody }}>

                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Slide>
                    ))
                }
            </JumboContentLayout>
        </Div>
    )
}

export default FAQ;