import { axiosIns } from "app/config/apiConfig";

const getReturneeDetails = async (id) => {
    const response = await axiosIns.get(`/returnee/profile/get/one`);
    return response.data;
};

const getOneReturnee = async (id) => {
    const response = await axiosIns.get(`/returnee/profile/get/${id}`);
    return response.data;
};

const getReturneeUpdate = async ({ id, data }) => {
    const response = await axiosIns.post(`/returnee/profile/update`, data);
    return response.data;
};

const getReturneeProfilePicture = async (url) => {
    const response = await axiosIns.get(`${url}`);
    return response.data;
};

const uploadReturneeProfilePicture = async ({ id, data }) => {
    const response = await axiosIns.post(`/returnee/profile/upload-image/${id}`, data);
    return response.data;
};

const getReturneeChangePassword = async (data) => {
    const response = await axiosIns.post(`/returnee/change-password`, data);
    return response.data;
};

const returneeChangePhoneNumber = async (data) => {
    const response = await axiosIns.post(`/returnee/change-mobile`, data);
    return response.data;
};

const returneeChangePhoneNumberVerify = async ({ changeHistoryId, data }) => {
    const response = await axiosIns.post(`/returnee/change-mobile/${changeHistoryId}`, data);
    return response.data;
};

const returneeServices = {
    getReturneeDetails,
    getReturneeUpdate,
    getReturneeChangePassword,
    returneeChangePhoneNumber,
    returneeChangePhoneNumberVerify,
    getReturneeProfilePicture,
    uploadReturneeProfilePicture,
    getOneReturnee
};

export default returneeServices;