import Div from "@jumbo/shared/Div";
import Span from "@jumbo/shared/Span";
import {
  Business,
  Edit,
  LanguageOutlined,
  LocationOn,
  Radar,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  Grow,
  Link,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import providerServices from "app/services/provider/provider.service";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import {
  CATEGORY,
  LOCATION,
  L_TEXT,
  PROVIDER,
  SEARCH_TEXT,
  SELECTED_SUBCATEGORY,
  SUBCATEGORY,
  USER_ID,
} from "app/config/localStorageConfig";
import { useTranslation } from "react-i18next";
import { getFont } from "app/hooks/getFont";
import ProviderDetails from "../serviceDetails/components/ProviderDetails";
import { Link as RouterLink } from "react-router-dom";

const imgStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
  padding: "5px",
  width: "120px",
  height: "120px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProviderProfile = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const isMobile = useMediaQuery("(max-width:575px)");

  const [value, setValue] = React.useState(0);
  const [isProfile, setisProfile] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, isLoading } = useQuery(
    ["get-service-provider-profile", i18n.language],
    () =>
      providerServices.getProviderProfile(
        location.pathname.includes("/service-provider/")
          ? localStorage.getItem(USER_ID)
          : params?.id,
        i18n.language == "bn-BD" ? 2 : 1
      )
  );

  // const { data: dataLogo, isLoading: isLoadingLogo } = useQuery(['get-provider-logo', data?.content?.imageLogoUrl], () => publicServices.getImage(data?.content?.imageLogoUrl), {
  //     enabled: !!data?.content?.imageLogoUrl
  // });

  // const { data: dataBanner, isLoading: isLoadingBanner } = useQuery(['get-provider-banner', data?.content?.imageBannerUrl], () => publicServices.getImage(data?.content?.imageBannerUrl), {
  //     enabled: !!data?.content?.imageBannerUrl
  // });

  useEffect(() => {
    if (location.pathname.includes("/profile")) {
      setisProfile(true);
    } else {
      setisProfile(false);
    }
  }, []);

  return (
    <Grow appear={true} in={true} style={{ transformOrigin: "0 0 0" }}>
      {isLoading ? (
        <Div sx={{ mx: isProfile ? "" : "8vw", background: "#FFFFFF" }}>
          <Skeleton variant="rounded" sx={{ width: "100%" }} height={250} />
          <Div style={{ position: "relative", height: "200px" }}>
            <Div
              style={{
                position: "absolute",
                top: "-80px",
                left: "20px",
                width: "100%",
              }}
            >
              <Div style={imgStyle}>
                <Div
                  sx={{
                    width: "115px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="rounded" width={110} height={110} />
                </Div>
              </Div>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Div>
                  <Skeleton
                    variant="text"
                    width={350}
                    sx={{ fontSize: "1.5rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    sx={{ fontSize: "0.875rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    sx={{ fontSize: "0.875rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={50}
                    sx={{ fontSize: "0.875rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={50}
                    sx={{ fontSize: "0.875rem" }}
                  />
                </Div>
                <Stack spacing={2} marginRight={"20px"}>
                  <Skeleton variant="rounded" width={115} height={30} />
                </Stack>
              </Stack>
            </Div>
          </Div>

          <Stack direction={"row"} spacing={2} sx={{ my: 1 }}>
            {Array(5)
              ?.fill(null)
              ?.map((_, i) => (
                <Skeleton
                  key={i}
                  variant="rounded"
                  width={72}
                  height={20}
                  sx={{ borderRadius: "10px" }}
                />
              ))}
          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Skeleton
              variant="rectangular"
              sx={{ width: "100%" }}
              height={250}
            />
          </Box>
        </Div>
      ) : (
        <Div sx={{ mx: isProfile ? "" : "8vw", background: "#FFFFFF" }}>
          <img
            src={
              data?.content?.imageBannerServer
                ? data?.content?.imageBannerServer
                : `${window.location.origin}/images/banner/noBanner.png`
            }
            width={"100%"}
          />
          <Div
            style={{
              position: "relative",
              height: isMobile ? "250px" : "200px",
            }}
          >
            <Div
              style={{
                position: "absolute",
                top: isMobile ? "-30px" : "-80px",
                left: "20px",
                width: "100%",
              }}
            >
              <Div style={imgStyle}>
                <Div
                  sx={{
                    width: "115px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={
                      data?.content?.imageLogoServer
                        ? data?.content?.imageLogoServer
                        : `${window.location.origin}/images/logo/noLogo.png`
                    }
                    width={"100%"}
                    height={"100%"}
                  />
                </Div>
              </Div>
              <Stack
                direction={isMobile ? "column" : "row"}
                justifyContent={"space-between"}
              >
                <Div>
                  <Typography fontFamily={getFont(i18n)} variant="h4">{`${
                    i18n.language == "bn-BD"
                      ? data?.content?.providerInfoDescriptionBn
                      : data?.content?.providerInfoDescription
                  } ${
                    data?.content?.providerInfoDescription !=
                    data?.content?.name
                      ? `(${
                          i18n.language == "bn-BD"
                            ? data?.content?.nameBn
                            : data?.content?.name
                        })`
                      : ""
                  }`}</Typography>
                  <Typography
                    fontFamily={getFont(i18n)}
                    variant="body2"
                    color={"#999999"}
                    lineHeight={"22px"}
                  >
                    <LocationOn fontSize="12px" />
                    <Span sx={{ ml: 0.5 }}>
                      {i18n.language == "bn-BD"
                        ? data?.content?.addressBn
                        : data?.content?.address}
                    </Span>
                  </Typography>
                  <Link
                    href={data?.content?.url}
                    underline="hover"
                    target="_blank"
                  >
                    <Typography
                      variant="body2"
                      color={"primary"}
                      lineHeight={"22px"}
                    >
                      <LanguageOutlined fontSize="12px" />
                      <Span sx={{ ml: 0.5 }}>{data?.content?.url}</Span>
                    </Typography>
                  </Link>
                  <Typography
                    fontFamily={getFont(i18n)}
                    variant="body2"
                    color={"#999999"}
                    lineHeight={"22px"}
                  >
                    <Business fontSize="12px" />
                    <Span sx={{ ml: 0.5 }}>
                      {i18n.language == "bn-BD"
                        ? `সংস্থার ধরণ: ${data?.content?.organizationTypeBn}`
                        : `Organization Type: ${data?.content?.organizationType}`}
                    </Span>
                  </Typography>
                  <Typography
                    fontFamily={getFont(i18n)}
                    variant="body2"
                    color={"#999999"}
                    lineHeight={"22px"}
                  >
                    <Radar fontSize="12px" />
                    <Span sx={{ ml: 0.5 }}>
                      {i18n.language == "bn-BD"
                        ? `অন্তর্ভুক্ত এলাকার পরিধি: ${data?.content?.organizationCoverageBn}`
                        : `Coverage: ${data?.content?.organizationCoverage}`}
                    </Span>
                  </Typography>
                </Div>
                <Stack
                  sx={{ my: isMobile ? 2 : 0 }}
                  spacing={2}
                  marginRight={"20px"}
                >
                  {isProfile && (
                    <Button
                      startIcon={<Edit />}
                      size="small"
                      onClick={() =>
                        navigate(`/service-provider/profile/edit`, {
                          state: data?.content,
                        })
                      }
                    >
                      {t("user.profile.edit-profile.title")}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      navigate(`/service-list`);
                      localStorage.setItem(
                        PROVIDER,
                        data?.content?.providerInfoId
                      );
                      localStorage.setItem(CATEGORY, 0);
                      localStorage.setItem(LOCATION, 0);
                      localStorage.setItem(SEARCH_TEXT, "");
                      localStorage.setItem(L_TEXT, "");
                    }}
                  >
                    {t("button.view-services")}
                  </Button>
                </Stack>
              </Stack>
            </Div>
          </Div>

          <Div sx={{ my: isMobile ? 3 : 1 }}>
            {[
              ...new Set(
                data?.content?.category_list?.map((item) => item.name)
              ),
            ]
              .map((name) => {
                return data?.content?.category_list?.find(
                  (item) => item.name === name
                );
              })
              ?.map((item, i) => (
                <Chip
                  key={i}
                  icon={
                    <div
                      style={{
                        background: "#FBAD1D",
                        borderRadius: "50%",
                        paddingTop: "2px",
                        height: "22px",
                        width: "25px",
                        textAlign: "center",
                        color: "white"
                      }}
                    >
                      {item?.cat_count}
                    </div>
                  }
                  label={i18n.language == "bn-BD" ? item?.nameBn : item?.name}
                  variant="outlined"
                  sx={{ m: 0.5, fontFamily: getFont(i18n) }}
                  onClick={() => {
                    navigate(`/service-list`);
                    localStorage.setItem(
                      PROVIDER,
                      data?.content?.providerInfoId
                    );
                    localStorage.setItem(CATEGORY, item?.cat_id);
                    // localStorage.setItem(
                    //   SELECTED_SUBCATEGORY,
                    //   item?.sub_cat_id
                    // );
                  }}
                />
              ))}
          </Div>

          <Grid container spacing={2} mb={3}>
            <Grid item md={8} xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                >
                  {data?.content?.profileDetails?.map((item, i) => (
                    <Tab
                      key={i}
                      label={
                        i18n.language == "bn-BD"
                          ? item?.sectionHeaderTitleBn
                          : item?.sectionHeaderTitle
                      }
                      {...a11yProps(i)}
                    />
                  ))}
                </Tabs>
              </Box>
              {data?.content?.profileDetails?.map((item, i) => (
                <TabPanel value={value} index={i} key={i}>
                  <div
                    style={{ fontFamily: getFont(i18n) }}
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language == "bn-BD"
                          ? item?.sectionBodyBn
                          : item?.sectionBody,
                    }}
                  ></div>
                </TabPanel>
              ))}
            </Grid>
            <Grid item md={4} xs={12}>
              <ProviderDetails
                address={
                  i18n.language == "bn-BD"
                    ? data?.content?.addressBn
                    : data?.content?.address
                }
                email={data?.content?.email}
                website={data?.content?.url}
                mapUrl={data?.content?.googleMapUrl}
                mobile={data?.content?.mobileNumbers}
              />
            </Grid>
          </Grid>
        </Div>
      )}
    </Grow>
  );
};

export default ProviderProfile;
