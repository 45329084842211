import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import Div from '@jumbo/shared/Div/Div';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import React, { useState, useEffect } from 'react';
// import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertFromRaw, convertFromHTML } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useFormik } from 'formik';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import providerServices from 'app/services/provider/provider.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { stateToHTML } from 'draft-js-export-html';
import serviceServices from 'app/services/service.service';
import { EditorOptions } from 'app/utils/EditorOptions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const MySwal = withReactContent(Swal)

const EditProviderProfile = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [dySection, setdySection] = useState([
        {
            sectionHeaderTitle: "",
            sectionHeaderTitleBn: "",
            sectionBody: "",
            sectionBodyBn: "",
            isActive: 1
        }
    ]);

    const [uploadFile, setuploadFile] = React.useState(null);
    const [uploadFileBanner, setuploadFileBanner] = React.useState(null);

    const updateState = (index, field, value) => {
        const newArray = dySection.map((item, i) => {
            if (index === i) {
                return { ...item, [field]: value };
            } else {
                return item;
            }
        });
        setdySection(newArray);
        formik.setFieldValue("profileDetails", newArray);
    };

    const addSection = (index) => {
        setdySection(current => [
            ...current.slice(0, index),
            {
                sectionHeaderTitle: "",
                sectionBody: "",
                sectionHeaderTitleBn: "",
                sectionBodyBn: "",
                isActive: 1
            },
            ...current.slice(index),
        ]);
    }

    const removeSection = (index) => {
        // setdySection(current => current.filter((_, i) => i !== index));

        setdySection((prevState, i) => {
            const newState = prevState.map(obj => {
                if (obj.profileDetailsId == index) {
                    return { ...obj, isActive: 0 };
                }
                return obj;
            });
            formik.setFieldValue("profileDetails", newState);
            return newState;
        });
    }

    const queryClient = useQueryClient();

    const { mutate } = useMutation(providerServices.updateProviderProfile, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
            if (data?.statusCode == 200) {
                navigate(`/service-provider/profile`)
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    // const { data: dataLogo, isLoading: isLoadingLogo } = useQuery(['get-provider-logo', location?.state?.imageLogoUrl], () => serviceServices.getBannerImage(location?.state?.imageLogoUrl), {
    //     enabled: !!location?.state?.imageLogoUrl
    // });

    // const { data: dataBanner, isLoading: isLoadingBanner } = useQuery(['get-provider-banner', location?.state?.imageBannerUrl], () => serviceServices.getBannerImage(location?.state?.imageBannerUrl), {
    //     enabled: !!location?.state?.imageBannerUrl
    // });

    const formik = useFormik({
        initialValues: {
            address: "",
            addressBn: "",
            url: "",
            email: "",
            mobileNumbers: "",
            googleMapUrl: "",
            logoImage: "",
            bannerImage: "",
            organizationType: "",
            organizationTypeBn: "",
            organizationCoverage: "",
            organizationCoverageBn: "",
            profileDetails: []
        },
        onSubmit: values => {

            // if (values.profileDetails?.length != 0) {
            //     values.profileDetails?.forEach((item) => {
            //         try {
            //             item.sectionBody = stateToHTML(item.sectionBody.getCurrentContent());
            //             item.sectionBodyBn = stateToHTML(item.sectionBodyBn.getCurrentContent());
            //         } catch (error) {
            //             console.log(error);
            //         }
            //     })
            // }

            console.log(values);

            const formData = new FormData();
            formData.append("fileLogo", uploadFile);
            formData.append("fileBanner", uploadFileBanner);
            formData.append("data", JSON.stringify(values));

            mutate({
                id: location.state?.id,
                data: formData
            })
        }
    })



    useEffect(() => {
        if (location?.state) {
            formik.setValues(location?.state)
            if (location?.state?.profileDetails?.length != 0) {
                // location?.state?.profileDetails?.forEach((item) => {
                //     try {
                //         const htmlString = item.sectionBody;
                //         const blocksFromHTML = convertFromHTML(htmlString);
                //         const contentState = ContentState.createFromBlockArray(blocksFromHTML);

                //         item.sectionBody = EditorState.createWithContent(contentState);

                //         const htmlStringBn = item.sectionBodyBn;
                //         const blocksFromHTMLBn = convertFromHTML(htmlStringBn);
                //         const contentStateBn = ContentState.createFromBlockArray(blocksFromHTMLBn);

                //         item.sectionBodyBn = EditorState.createWithContent(contentStateBn);
                //     } catch (error) {
                //         console.log(error);
                //     }
                // })
                setdySection(location?.state?.profileDetails)
            }
        }
    }, []);


    return (
        <Div>
            <form onSubmit={formik.handleSubmit}>
                <JumboContentLayout
                    header={
                        <Grid container style={{
                            position: "fixed",
                            zIndex: 1000,
                            width: "82%",
                            background: "white",
                            marginLeft: "-50px",
                            padding: "30px 50px 0px",
                            marginTop: "-33px"
                        }} >
                            <Grid item sm={12} md={6}>
                                <PageHeader
                                    title={"Edit Profile"}
                                    noMargin={true}
                                />
                            </Grid>
                            <Grid item sm={12} md={6} >
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    <Button onClick={() => navigate(`/service-provider/profile`)} variant="outlined" color='error' >
                                        Cancel
                                    </Button>
                                    <Button type='submit' variant="contained" >
                                        Update Profile
                                    </Button>
                                </Stack>

                            </Grid>
                        </Grid>
                    }
                >

                    {/* <TextField
                    sx={{ mb: 3 }}
                    label="Service Provider Name"
                /> */}

                    <Grid container spacing={3} mt={5}>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Location"
                                {...formik.getFieldProps('address')}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Location (Bangla)"
                                InputProps={{ style: { background: "#e4fde4" } }}
                                {...formik.getFieldProps('addressBn')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Google Map URL"
                                {...formik.getFieldProps('googleMapUrl')}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Website"
                                {...formik.getFieldProps('url')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Email Address"
                                {...formik.getFieldProps('email')}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Phone Number"
                                {...formik.getFieldProps('mobileNumbers')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Organization Type"
                                {...formik.getFieldProps('organizationType')}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Organization Coverage"
                                {...formik.getFieldProps('organizationCoverage')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Organization Type (Bangla)"
                                InputProps={{ style: { background: "#e4fde4" } }}
                                {...formik.getFieldProps('organizationTypeBn')}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Organization Coverage (Bangla)"
                                InputProps={{ style: { background: "#e4fde4" } }}
                                {...formik.getFieldProps('organizationCoverageBn')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item sm={12} md={6}>
                            <Typography sx={{ mb: 1 }}>Service Provider Logo</Typography>

                            <TextField
                                fullWidth
                                type='file'
                                value={uploadFile?.filename}
                                onChange={(e) => setuploadFile(e.target.files[0])}
                            />
                            <Typography sx={{ mb: 3 }} variant='caption' color={"#C9C9C9"} >Image should be at 500x500px in resolution and JPG, JPEG, PNG in format.</Typography>
                            {
                                uploadFile ?
                                    (<img src={URL.createObjectURL(uploadFile)} style={{ height: "150px", marginBottom: "10px", borderRadius: "6px" }} />)
                                    :
                                    (
                                        <img
                                            src={formik?.values?.imageLogoServer ? formik?.values?.imageLogoServer : `${window.location.origin}/images/logo/noLogo.png`} style={{ height: "150px", marginBottom: "10px", borderRadius: "6px" }} />
                                    )
                            }

                            {
                                uploadFile && (
                                    <Button variant='text' sx={{ mb: 2 }} onClick={() => setuploadFile(null)} >Remove this image</Button>
                                )
                            }
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Typography sx={{ mb: 1 }}>Service Provider Banner</Typography>

                            <TextField
                                fullWidth
                                type='file'
                                value={uploadFileBanner?.filename}
                                onChange={(e) => setuploadFileBanner(e.target.files[0])}
                            />
                            <Typography sx={{ mb: 3 }} variant='caption' color={"#C9C9C9"} >Image should be at 1920x580px in resolution and JPG, JPEG, PNG in format.</Typography>
                            {
                                uploadFileBanner ?
                                    (<img src={URL.createObjectURL(uploadFileBanner)} style={{ width: "100%", marginBottom: "10px", borderRadius: "6px" }} />)
                                    :
                                    (
                                        <img
                                            src={formik?.values?.imageBannerServer ? formik?.values?.imageBannerServer : `${window.location.origin}/images/banner/noBanner.png`} style={{ width: "100%", marginBottom: "10px", borderRadius: "6px" }} />
                                    )
                            }

                            {
                                uploadFileBanner && (
                                    <Button variant='text' sx={{ mb: 2 }} onClick={() => setuploadFileBanner(null)} >Remove this image</Button>
                                )
                            }
                        </Grid>
                    </Grid>

                    <Typography variant='h5' sx={{ mb: 3, mt: 2 }}>
                        Dynamic Section
                    </Typography>

                    {
                        dySection?.map((item, i) => item.isActive == 1 && (
                            <Div key={i} sx={{
                                mb: 3,
                                border: "1px solid lightgray",
                                borderRadius: "10px",
                                padding: "20px",
                            }}>
                                <TextField
                                    fullWidth
                                    label={"Section Title"}
                                    sx={{ mb: 3 }}
                                    value={item.sectionHeaderTitle}
                                    onChange={(e) => updateState(i, "sectionHeaderTitle", e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label={"Section Title (Bangla)"}
                                    sx={{ mb: 3 }}
                                    value={item.sectionHeaderTitleBn}
                                    InputProps={{ style: { background: "#e4fde4" } }}
                                    onChange={(e) => updateState(i, "sectionHeaderTitleBn", e.target.value)}
                                />
                                <Div sx={{ border: "1px solid lightgray", borderRadius: "6px" }}>
                                    <Typography sx={{ p: 1 }}>Section Body</Typography>
                                    {/* <Editor
                                        editorStyle={{
                                            width: '100%',
                                            minHeight: 100,
                                            maxHeight: 300,
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'lightgray',
                                        }}
                                        toolbar={EditorOptions}
                                        editorState={item?.sectionBody}
                                        onEditorStateChange={editorState => {
                                            // updateState(i, "sectionBody", draftToHtml(convertToRaw(editorState.getCurrentContent())))
                                            updateState(i, "sectionBody", editorState)

                                        }}
                                    /> */}
                                    <CKEditor
                                        key={`${item?.profileDetailsId}en`}
                                        editor={Editor}
                                        data={item?.sectionBody}
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            console.log({ event, editor, data });
                                            updateState(i, "sectionBody", data)
                                        }}
                                    />
                                </Div>

                                <Div sx={{ border: "1px solid lightgray", borderRadius: "6px", mt: 3 }}>
                                    <Typography sx={{ p: 1 }}>Section Body (Bangla)</Typography>
                                    {/* <Editor
                                        editorStyle={{
                                            width: '100%',
                                            minHeight: 100,
                                            maxHeight: 300,
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'lightgray',
                                            background: "#e4fde4"
                                        }}

                                        editorState={item?.sectionBodyBn}
                                        onEditorStateChange={editorState => {
                                            // updateState(i, "sectionBody", draftToHtml(convertToRaw(editorState.getCurrentContent())))
                                            updateState(i, "sectionBodyBn", editorState)

                                        }}
                                    /> */}
                                    <CKEditor
                                        key={`${item?.profileDetailsId}bn`}
                                        editor={Editor}
                                        data={item?.sectionBodyBn}
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            console.log({ event, editor, data });
                                            updateState(i, "sectionBodyBn", data)
                                        }}
                                    />
                                </Div>

                                <Div sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                    <Button
                                        sx={{ mr: 2 }}
                                        variant='contained'
                                        color='error'
                                        startIcon={<RemoveCircleOutline />}
                                        disabled={dySection?.length == 1 ? true : false}
                                        onClick={() => removeSection(item?.profileDetailsId)}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        variant='contained'
                                        startIcon={<AddCircleOutline />}
                                        onClick={() => addSection(i + 1)}
                                    >
                                        Add
                                    </Button>
                                </Div>
                            </Div>
                        ))
                    }

                </JumboContentLayout>
            </form>
        </Div>
    )
}

export default EditProviderProfile;