import Div from "@jumbo/shared/Div";
import React, { useState, useEffect } from "react";
import { Divider, Link, Typography, useMediaQuery } from "@mui/material";
import ProviderInfo from "app/shared/ProviderInfo";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getFont } from "app/hooks/getFont";

const cardStyle = {
  background: "#FDFDFD",
  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
  borderRadius: "2px",
  padding: "10px",
  marginTop: "20px",
};

const SimilarService = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <Div style={cardStyle}>
      <Typography variant="h4" py={2}>
        {i18n.language == "bn-BD" ? "একই রকম সেবাসমূহ" : "Similar Services"}
      </Typography>

      {data &&
        data?.map((item, i) => (
          <Link
            to={`/service/details/${item?.mapWithServiceId}`}
            component={RouterLink}
            underline="none"
            key={i}
          >
            <ProviderInfo
              id={item?.provider_profile_id}
              name={
                i18n.language == "bn-BD"
                  ? item?.service_provider_info_name_bn
                  : item?.service_provider_info_name
              }
              website={item?.url}
              image={
                item?.providerInfoLogoImageServer
                  ? item?.providerInfoLogoImageServer
                  : `${window.location.origin}/images/logo/noLogo.png`
              }
            />
            <Typography fontFamily={getFont(i18n)} mt={1}>
              {i18n.language == "bn-BD"
                ? item?.service_provider_services_name_bn
                : item?.service_provider_services_name}
            </Typography>
            {i + 1 != data?.length ? <Divider sx={{ my: 1 }} /> : ""}
          </Link>
        ))}
    </Div>
  );
};

export default SimilarService;
