import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import JumboDdPopover from '@jumbo/components/JumboDdPopover/JumboDdPopover';
import Div from '@jumbo/shared/Div/Div';
import { AddCircleOutline, Cancel, CheckCircle, Close, DeleteForeverOutlined, InfoOutlined, ModeEditOutline, MoreVertOutlined } from '@mui/icons-material';
import { Button, Chip, CircularProgress, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import serviceServices from 'app/services/service.service';
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const Services = () => {

    const [list, setlist] = useState([]);
    const [isUpdate, setisUpdate] = useState(0);

    const columns = [
        {
            name: 'Name',
            selector: row => row.service_name,
            cell: row => {
                if (row.service_name) {
                    return row.service_name;
                } else {
                    return row.service_name_bn;
                }
            }
        },
        {
            name: 'Category',
            selector: row => row.category_name,
            width: "110px"
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_category_name,
            width: "130px"
        },
        {
            name: 'Service validity',
            selector: row => row.start_date_ddmonyy,
            cell: (row) => {
                if (row.start_date_ddmonyy && row.end_date_ddmonyy) {
                    return `${row.start_date_ddmonyy} to ${row.end_date_ddmonyy}`
                } else {
                    return "No validate time"
                }

            }
        },
        {
            name: 'Status',
            selector: row => row.is_active,
            width: "110px",
            cell: (row) => {
                if (row.is_active) {
                    return <Chip label="Active" size="small" color="success" />
                } else {
                    return <Chip label="Inactive" size="small" color="error" />
                }
            }
        },
        {
            name: 'Action',
            width: "110px",
            cell: (row, index, column, id) => {
                return (
                    <JumboDdPopover
                        triggerButton={
                            <IconButton>
                                <MoreVertOutlined />
                            </IconButton>
                        }
                    >
                        <List disablePadding sx={{ pb: 1 }}>
                            <ListItemButton>
                                <Link sx={{ display: 'flex' }} to={`/service-provider/service/${row?.service_id}`} color={"inherit"} component={RouterLink} underline={"none"}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <InfoOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Details" sx={{ my: 0 }} />
                                </Link>
                            </ListItemButton>
                            <ListItemButton>
                                <Link sx={{ display: 'flex' }} to={`/service-provider/service/update/${row?.service_id}`} color={"inherit"} component={RouterLink} underline={"none"}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <ModeEditOutline />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" sx={{ my: 0 }} />
                                </Link>
                            </ListItemButton>
                            <ListItemButton sx={{ display: 'flex' }} onClick={() => onStatusChange(row?.service_id, row.is_active ? 0 : 1)} >
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    {row.is_active ? <Cancel /> : <CheckCircle />}
                                </ListItemIcon>
                                <ListItemText primary={row.is_active ? "Inactive" : "Active"} />
                            </ListItemButton>
                            <ListItemButton sx={{ display: 'flex' }} onClick={() => onDelete(row?.service_id)} >
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <DeleteForeverOutlined color='error' />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "#E73145", my: 0 }} primary="Delete" />
                            </ListItemButton>
                        </List>
                    </JumboDdPopover >
                )

            }
        },
    ];

    const { data, isLoading } = useQuery(['get-service-list', isUpdate], () => serviceServices.getServiceList(), {
        onSuccess: data => {
            setlist(data?.content)
        }
    })

    const { mutate } = useMutation(serviceServices.deleteService, {
        onSuccess: data => {
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })

            if (data?.statusCode == 200) {
                setisUpdate(isUpdate + 1)
            }
        }
    })

    const { mutate: mutateStatus } = useMutation(serviceServices.statusChangeService, {
        onSuccess: data => {
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })

            if (data?.statusCode == 200) {
                setisUpdate(isUpdate + 1)
            }
        }
    })

    const onDelete = (id) => {
        MySwal.fire({
            icon: 'warning',
            showConfirmButton: true,
            html: <strong>Are you sure you want to delete?</strong>,
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                mutate(id)
            }
        })
    }


    const onStatusChange = (id, status) => {
        MySwal.fire({
            icon: 'warning',
            showConfirmButton: true,
            html: <strong>Are you sure you want to change status?</strong>,
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                mutateStatus({
                    id: id,
                    status: status
                })
            }
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Div>
            <JumboContentLayout
                header={
                    <Grid container>
                        <Grid item sm={12} md={6}>
                            <PageHeader
                                title={"Service List"}
                                subheader={"All services are here"} />
                        </Grid>
                        <Grid item sm={12} md={6} textAlign={"end"}>
                            <Link to={"/service-provider/service/new-service"} color={"inherit"} component={RouterLink} underline={"none"}>
                                <Button variant="contained" startIcon={<AddCircleOutline />}>
                                    Add New Service
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                }
            >
                {
                    isLoading ? (
                        <Div sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: "100%",
                            minHeight: "20vh"
                        }}>
                            <CircularProgress color={"warning"} sx={{ m: '-40px auto 0' }} />
                        </Div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={list}
                        />
                    )
                }

            </JumboContentLayout>
        </Div>
    )
}

export default Services;