import React from 'react';
import LabelsList from "../LabelsList";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import FoldersList from "../FoldersList";
import FiltersList from "../FiltersList";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ComposeForm from "../ComposeForm";
import useMailsApp from "../../hooks/useMailsApp";
import Div from '@jumbo/shared/Div';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const MailAppSidebar = ({ setShowCompose }) => {
    const { t, i18n } = useTranslation();
    const { hideDialog, showDialog } = useJumboDialog();
    const { setMailsListRefresh } = useMailsApp();

    const handleMailSent = React.useCallback(() => {
        hideDialog();
        setMailsListRefresh(true);
    }, [hideDialog, setMailsListRefresh]);

    const handleComposeForm = React.useCallback(() => {
        showDialog({
            title: "Compose Message",
            content: <ComposeForm onSave={handleMailSent} />
        })
    }, [showDialog]);

    return (
        <React.Fragment>
            <Button
                fullWidth
                disableElevation
                variant={"contained"}
                startIcon={<Edit />}
                sx={{
                    fontFamily: getFont(i18n),
                    mb: 4,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={() => setShowCompose(true)}
            >{t("button.compose")}</Button>
            <FoldersList />
            {/* <FiltersList/> */}
            {/* <LabelsList/> */}
        </React.Fragment>
    );
};

export default MailAppSidebar;
