import React from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import { mailServices } from "../../../../../services/mail-services";
import MailItem from "./MailItem";
import { Card, Typography } from "@mui/material";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";
import { useParams } from "react-router-dom";
import JumboSearch from "@jumbo/components/JumboSearch";
import useMailsApp from "../../hooks/useMailsApp";
import messageServices from 'app/services/message.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import Div from '@jumbo/shared/Div/Div';
import { getBanglaNumber } from 'app/hooks/getBanglaNumber';

const MailsList = ({ isupdated }) => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const listRef = React.useRef();
    const { refreshMailsList, setMailsListRefresh, setSelectedMails } = useMailsApp();
    const [data, setdata] = React.useState(null);
    const [tempData, settempData] = React.useState();

    const renderMailItem = React.useCallback((mailItem) => {
        return <MailItem mailItem={mailItem} />
    });
    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: `mails-list`,
        variable: isupdated,
        queryParams: { category: params.category, id: params.id },
        countKey: "count",
        dataKey: "mailsList"
    });

    const handleOnChange = React.useCallback((keywords) => {
        if (!!keywords) {
            const result = data?.content?.filter((element) => JSON.stringify(element)?.toLowerCase()?.includes(keywords));
            settempData(result);
            console.log({ result });
        } else {
            settempData(data?.content);
        }
    }, [data]);

    React.useEffect(() => {
        settempData(data?.content)
    }, [data]);

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    React.useEffect(() => {
        if (listRef?.current && refreshMailsList) {
            listRef.current.refresh();
            setMailsListRefresh(false);
        }
    }, [refreshMailsList, isupdated])

    React.useEffect(() => {
        setQueryOptions(state => ({
            ...state,
            queryParams: { category: params.category, id: params.id },
        }))
    }, [params, isupdated]);

    return (
        <JumboRqList
            ref={listRef}
            wrapperComponent={Card}
            queryOptions={queryOptions}
            primaryKey={"id"}
            service={params.category == "inbox" ? messageServices.getInboxMesssages : messageServices.getSendMessages}
            setData={setdata}
            filterData={tempData}
            renderItem={renderMailItem}
            itemsPerPage={8}
            itemsPerPageOptions={[8, 12, 15]}
            componentElement={'div'}
            wrapperSx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
            toolbar={
                <JumboListToolbar
                    hideItemsPerPage={true}
                >

                    {
                        data?.totalCount != 0 && (
                            <Div className='tt' sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <JumboSearch
                                    onChange={handleOnChange}
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                        width: "40%"
                                    }}
                                />
                                <Div sx={{ py: 2 }}></Div>
                                <Typography fontFamily={getFont(i18n)} >
                                    {i18n.language == "bn-BD" ? `${getBanglaNumber(data?.totalCount)}টি পাওয়া গিয়েছে` : `${data?.totalCount} found`}
                                </Typography>
                            </Div>

                        )
                    }

                </JumboListToolbar>
            }
            onSelectionChange={setSelectedMails}
            noDataPlaceholder={<Typography fontFamily={getFont(i18n)} variant='h4' color={"#a7a7a7"} letterSpacing={"3px"} >{i18n.language == "bn-BD" ? "খালি বাক্স" : "Empty box"}</Typography>}
        />
    );
};

export default MailsList;
