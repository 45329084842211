import React from 'react';
import Div from "@jumbo/shared/Div";
import Badge from "@mui/material/Badge";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link, ListItem, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Divider from "@mui/material/Divider";
import FlagIcon from "@mui/icons-material/Flag";
import DialpadIcon from "@mui/icons-material/Dialpad";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { ASSET_AVATARS } from "../../../../../utils/constants/paths";
import { getAssetPath } from "../../../../../utils/appHelpers";
import { PROFILE_IMAGE_URL, USERNAME } from 'app/config/localStorageConfig';
import { NAME } from 'app/config/localStorageConfig';
import { useQuery } from 'react-query';
import returneeServices from 'app/services/returnee.service';

const AuthUserSummary = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'user-popover' : undefined;

    return (
        <React.Fragment>
            <Div
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={
                        <React.Fragment>
                            <Div sx={{ p: 3, pb: 2, minWidth: 276 }}>
                                <Div
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <Avatar
                                        sx={{ width: 40, height: 40, mr: 2 }}
                                        alt={"Saidur Rahman"}
                                        src={getAssetPath(`${ASSET_AVATARS}/avatar6.png`, "60x60")}
                                    />
                                    <Div sx={{ flex: '1 1 auto' }}>
                                        {/* <Typography variant={"h5"} mb={.35}>Cory Smith</Typography>
                                        <Typography
                                            variant={"body1"}
                                            color={"text.secondary"}
                                        >Life must be big</Typography> */}
                                    </Div>
                                </Div>
                            </Div>
                        </React.Fragment>
                    }
                    sx={{
                        mr: 2,
                        '& .MuiBadge-badge': {
                            height: 12,
                            width: 12,
                            minWidth: 12,
                            overflow: 'hidden',
                            border: 1,
                            borderColor: 'common.white',
                            color: 'common.white',
                            bgcolor: '#8dcd03',
                            cursor: 'pointer',
                            right: 6,
                            bottom: 6,
                        }
                    }}
                >
                    <Avatar sx={{ width: 40, height: 40 }} alt={localStorage.getItem(NAME)} src={localStorage.getItem(PROFILE_IMAGE_URL) ? localStorage.getItem(PROFILE_IMAGE_URL) : ""} />
                </Badge>
                <Div sx={{ flex: '1 1 auto' }}>
                    <Typography variant={"h5"}>{localStorage.getItem(NAME)}</Typography>
                </Div>
            </Div>
        </React.Fragment>
    );
};

export default AuthUserSummary;
