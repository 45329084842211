import React, { useState, useEffect } from 'react';
import Div from '@jumbo/shared/Div';
import { Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as yup from "yup";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import authServices from 'app/services/auth/auth.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const MySwal = withReactContent(Swal)

const padTime = time => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
};

const format = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${padTime(seconds)}`;
};

const validationSchema = yup.object({
    otpCode: yup
        .string()
        .required('OTP is required'),
});

export const OTPVerification = ({ responseData, setResponseData, hasMobile }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [counter, setCounter] = React.useState(responseData?.otpExpTime ? responseData?.otpExpTime * 60 : 300);

    const { mutate, isLoading, data } = useMutation(authServices.verifyOTP, {
        onSuccess: (data) => {
            setResponseData(data?.content);
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                navigate("/auth/signup/step-3");
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const { mutate: mutateResend, isLoading: isLoadingResend, data: dataResend } = useMutation(authServices.resendOTP, {
        onSuccess: (data) => {
            setResponseData(data?.content);
            formik.setValues(data?.content);
            MySwal.fire({
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                setCounter(data?.content?.otpExpTime * 60)
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            mobileNo: "",
            regEmail: "",
            registrationWith: "MOBILE_NO",
            otpCode: "",
            otpSecretKey: ""
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            values.registrationWith = hasMobile ? "MOBILE_NO" : "EMAIL"
            console.log(values);
            mutate(values)
        }
    })

    React.useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(c => c - 1), 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [counter]);

    useEffect(() => {
        const temp = responseData;
        delete temp?.otpExpTime;
        delete temp?.otpExpTimeUnit;
        formik.setValues(responseData);
    }, []);

    return (
        <Div sx={{ my: 3 }}>
            <form onSubmit={formik.handleSubmit}>
                <Typography fontFamily={getFont(i18n)} variant='caption' color={"#A2B2C3"}>
                    {hasMobile ? t("signup.col2.p2") : t("signup.col2.p2_email")}
                </Typography>
                <TextField
                    fullWidth
                    sx={{ mt: 2, mb: 3 }}
                    inputProps={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    type={"text"}
                    label={t("label.otp")}
                    placeholder={t("placeholder.otp")}
                    error={formik.touched.otpCode && formik.errors.otpCode ? true : false}
                    helperText={formik.errors.otpCode}
                    {...formik.getFieldProps('otpCode')}
                />
                <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3, fontFamily: getFont(i18n) }}
                    disabled={!formik.dirty || formik.errors.otpCode}
                    loading={isLoading}>
                    {t("button.continue")}
                </LoadingButton>
            </form>
            <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {counter === 0 ? (
                    <>
                        <Typography fontFamily={getFont(i18n)} variant='caption' color={"#A2B2C3"} marginRight={"5px"}>
                            {i18n.language == "bn-BD" ? "ওটিপির মেয়াদ শেষ হয়ে গেছে" : "OTP has expired."}
                        </Typography>
                        <Button sx={{ fontFamily: getFont(i18n) }} variant='text' onClick={() => {
                            formik.values.registrationWith = hasMobile ? "MOBILE_NO" : "EMAIL"
                            mutateResend(formik.values)
                            }}>
                            {i18n.language == "bn-BD" ? "ওটিপি পুনরায় পাঠান" : "Resend OTP"}
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography fontFamily={getFont(i18n)} variant='h5' color={"#A2B2C3"} marginRight={"5px"}>
                            {i18n.language == "bn-BD" ? "ওটিপির মেয়াদ শেষ হয়ে যাবে" : "OTP will be expired in"}
                        </Typography>
                        <Typography fontFamily={getFont(i18n)} variant='h5' color={"black"} fontWeight={600}>
                            {format(counter)}
                        </Typography>
                    </>
                )
                }

            </Div >
        </Div >
    )
}