import { axiosIns } from "app/config/apiConfig";

const getDashboardData = async () => {
    const response = await axiosIns.get(`/dashboard/get-summary`);
    return response.data;
};

const getAllUserList = async () => {
    const response = await axiosIns.get(`/users/all`);
    return response.data;
};

const updateFaq = async (data) => {
    const response = await axiosIns.post(`/faq/save`, data);
    return response.data;
};

const adminServices = {
    getDashboardData,
    getAllUserList,
    updateFaq
};

export default adminServices;