import React, { useState, useEffect } from 'react';
import MailAppProvider from "./MailAppProvider";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import MailAppSidebar from "./components/MailAppSidebar";
import MailsList from "./components/MailsList";
import { useParams } from "react-router-dom";
import MailDetail from "./components/MailDetail";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Card, CardHeader, IconButton, Typography, useMediaQuery } from "@mui/material";
import MailFab from "./components/MailFab";
import FolderDropdown from "./components/FolderDropdown";
import FilterDropdown from "./components/FilterDropdown";
import Stack from "@mui/material/Stack";
import LabelDropdown from "./components/LabelDropdown";
import ComposeForm from './components/ComposeForm';
import Div from '@jumbo/shared/Div';
import { CloseOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const MailApp = () => {
    const { t, i18n } = useTranslation();
    const { theme } = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    const { messageID } = useParams();
    const [showCompose, setShowCompose] = React.useState(false);
    const [isExpand, setIsExpand] = React.useState(true);
    const [isupdated, setisupdated] = React.useState(0);

    const floatingMailFormStyle = {
        position: "fixed",
        background: "#fff",
        zIndex: 500,
        bottom: isExpand ? -10 : -21,
        right: 49,
        paddingBottom: 20,
        border: "2px solid #FBAD1D",
        width: "550px",
        height: isExpand ? "410px" : "auto"
    }

    const CardAction = (
        <Div>
            <IconButton onClick={() => setIsExpand(prev => !prev)}>
                {
                    isExpand ? <KeyboardArrowDownOutlined /> : <KeyboardArrowUpOutlined />
                }
            </IconButton>
            <IconButton onClick={() => { setShowCompose(false); setIsExpand(true) }}>
                <CloseOutlined />
            </IconButton>
        </Div>
    )

    const handleMailSent = () => {
        setShowCompose(false);
        setisupdated(prev => prev + 1);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }


    return (
        <MailAppProvider>
            <JumboContentLayout
                sidebar={<MailAppSidebar setShowCompose={setShowCompose} />}
                header={
                    <PageHeader
                        title={t("user.Communication.title")}
                        subheader={t("user.Communication.sub")} />
                }
                layoutOptions={layoutOptions}
            >
                {
                    lg && (
                        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}>
                            {/* <FolderDropdown />
                            <FilterDropdown />
                            <LabelDropdown />
                            <MailFab /> */}
                        </Stack>
                    )
                }
                {
                    messageID ? <MailDetail mailID={messageID} /> : <MailsList isupdated={isupdated} />
                }
                {
                    showCompose && (
                        <Card style={floatingMailFormStyle}>
                            <CardHeader
                                sx={{
                                    background: "#FBAD1D",
                                    padding: "2px 15px"
                                }}
                                title={i18n.language == "bn-BD" ? "নতুন বার্তা লিখুন" : " Compose New Message"}
                                titleTypographyProps={{ style: { fontFamily: getFont(i18n) } }}
                                action={CardAction}
                            />
                            {
                                isExpand && (
                                    <ComposeForm onSave={handleMailSent} />
                                )
                            }
                        </Card>
                    )
                }
            </JumboContentLayout>
        </MailAppProvider>
    );
};
export default MailApp;
