import { Table, TableContainer, Typography, TableHead, TableRow, TableCell, TableBody, Paper, Stack, Button, Modal, Box, FormControl, TextField, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
// import ProviderItem from './components/ProviderItem';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import providerServices from 'app/services/provider/provider.service';
import { CheckCircle, DoNotDisturb, Menu } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Div from '@jumbo/shared/Div/Div';
import JumboSearch from '@jumbo/components/JumboSearch/JumboSearch';
import { useFormik } from 'formik';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid white',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

const ProviderEmployeeList = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [open, setOpen] = useState(false);
    const [list, setlist] = useState([]);
    const [searchText, setsearchText] = useState("");
    const [isedit, setisedit] = useState(null);
    const [isUpdated, setisUpdated] = useState(0);
    const [status, setstatus] = useState("");

    const queryClient = useQueryClient();

    const { mutate } = useMutation(providerServices.approvedProviderEmployee, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
            if (data?.statusCode == 200) {
                handleClose()
                setisUpdated(prev => prev + 1)
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const { data } = useQuery(['get-provider-employee-list', isUpdated], () => providerServices.getProviderEmployeeList(params?.id), {
        enabled: !!params?.id,
        onSuccess: data => {
            setlist(data?.content)
        }
    })

    const SearchAnything = (value) => {
        setsearchText(value);
        try {
            const result = data?.content?.filter((obj) =>
                JSON.stringify(obj)?.toLowerCase()?.includes(value.toLowerCase())
            )
            setlist(result);
        } catch (error) {

        }
    }

    const formik = useFormik({
        initialValues: {
            approvalNote: "",
            regStatus: "",
            emailAddress: ""
        },
        onSubmit: values => {
            mutate({
                id: isedit,
                data: values
            });
            handleClose();
        }
    })

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            event.stopPropagation();
            return false;
        } else {
            setOpen(false);
            formik.resetForm();
            setisedit(null);
        }

    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <Stack sx={{ mb: 2 }} direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                <Typography variant={'h2'} mb={3}>Service Provider's User List</Typography>
            </Stack>
            <TableContainer component={Paper}>
                <Stack justifyContent={"space-between"} alignItems={"center"}>
                    <Div>

                    </Div>
                    <JumboSearch
                        sx={{ ml: "auto", my: 1, mr: 1, width: "25%" }}
                        onChange={SearchAnything}
                    />
                </Stack>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>id#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Designation</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Email Address</TableCell>
                            <TableCell>Provider</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align='center'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: row?.isApproved && "#ddefe6" }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell >{row.name}</TableCell>
                                <TableCell>{row.designation}</TableCell>
                                <TableCell>{row.phoneNumber}</TableCell>
                                <TableCell>{row.emailAddress}</TableCell>
                                <TableCell>{row.providerInfoList[0]?.name}</TableCell>
                                <TableCell>{row.regStatus}</TableCell>
                                <TableCell align='center'>
                                    <Button variant='contained' onClick={() => {
                                        setisedit(row.id);
                                        formik.setFieldValue("emailAddress", row.emailAddress);
                                        formik.setFieldValue("regStatus", row.regStatus);
                                        setstatus(row.regStatus);
                                        formik.setFieldValue("approvalNote", row.approvalNote);
                                        handleOpen();
                                    }}>
                                        <Menu />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ mb: 2 }}>
                        {"Service Provider's User Status"}
                    </Typography>
                    <form onSubmit={formik.handleSubmit} >
                        <Div sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                label="Note"
                                {...formik.getFieldProps('approvalNote')}
                            />
                        </Div>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="status">
                                Status
                            </InputLabel>

                            {
                                status == "APPROVED" ? (
                                    <Select
                                        labelId="status"
                                        id="status-select"
                                        label="Status"
                                        {...formik.getFieldProps('regStatus')}
                                    >
                                        <MenuItem value={0}>Select Organisation</MenuItem>
                                        <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                        <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
                                    </Select>
                                ) : (
                                    <Select
                                        labelId="status"
                                        id="status-select"
                                        label="Status"
                                        {...formik.getFieldProps('regStatus')}
                                    >
                                        <MenuItem value={0}>Select Organisation</MenuItem>
                                        <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                        <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                        <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
                                        <MenuItem value={"UN_REACHABLE"}>UNREACHABLE</MenuItem>
                                        <MenuItem value={"FRAUD_ATTEMPT"}>FRAUD ATTEMPT</MenuItem>
                                    </Select>
                                )
                            }

                        </FormControl>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                fullWidth
                                type="button"
                                variant="contained"
                                size="large"
                                color='error'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Modal>

        </div>
    )
}

export default ProviderEmployeeList;