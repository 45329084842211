import React from 'react';
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import { AvatarGroup, Chip, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import { ASSET_AVATARS } from "../../../../utils/constants/paths";
import { getAssetPath } from "../../../../utils/appHelpers";
import { BadgeOutlined, ConstructionOutlined, FlightOutlined, InterestsOutlined, WcOutlined, WorkOutline } from '@mui/icons-material';
import { PassportIcon } from 'app/utils/icon/icon';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));
const ReturnInfo = ({ data }) => {
    const { t, i18n } = useTranslation();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <JumboCardQuick
            title={t("user.profile.ReturnInfo.title")}
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{ mb: 3.75 }}

        >
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                }}
            >

                <ListItem
                    sx={{
                        width: "100%"
                    }}
                >
                    <StyledListItemIcon>
                        <FlightOutlined fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        disableTypography={true}
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>{t("user.profile.ReturnInfo.from")}</Typography>}
                        secondary={
                            <Stack direction={'row'} spacing={1} flexWrap={"wrap"}>
                                {
                                    data?.returnFromCountry && data?.returnFromCountry?.split(", ")?.map((item, i) => (
                                        <Chip key={i} label={item} color="secondary" />
                                    ))
                                }
                            </Stack>
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{
                        width: "100%"
                    }}
                >
                    <StyledListItemIcon>
                        <ConstructionOutlined fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        disableTypography={true}
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>{t("user.profile.ReturnInfo.skills")}
                        </Typography>}
                        secondary={
                            <Stack direction={'row'} spacing={1} flexWrap={"wrap"}>
                                {
                                    data?.skillInAbroad && data?.skillInAbroad?.split(", ")?.map((item, i) => (
                                        <Chip key={i} label={item} color="info" />
                                    ))
                                }
                            </Stack>
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{
                        width: "100%"
                    }}
                >
                    <StyledListItemIcon>
                        <InterestsOutlined fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        disableTypography={true}
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("user.profile.ReturnInfo.interest")}
                        </Typography>}
                        secondary={
                            <Stack direction={'row'} spacing={1} flexWrap={"wrap"}>
                                {
                                    data?.areaOfInterest && data?.areaOfInterest?.split(", ")?.map((item, i) => (
                                        <Chip key={i} label={item} color="warning" />
                                    ))
                                }
                            </Stack>
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{
                        width: "100%"
                    }}
                >
                    <StyledListItemIcon>
                        <WorkOutline fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        disableTypography={true}
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("user.profile.ReturnInfo.Current Profession")}
                        </Typography>}
                        secondary={
                            <Stack direction={'row'} spacing={1} flexWrap={"wrap"}>
                                {
                                    data?.currentProfession && data?.currentProfession?.split(", ")?.map((item, i) => (
                                        <Chip key={i} label={item} color="success" />
                                    ))
                                }
                            </Stack>
                        }
                    />
                </ListItem>

            </List>
        </JumboCardQuick>
    );
};

export default ReturnInfo;
