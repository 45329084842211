import Div from '@jumbo/shared/Div';
import { Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import NewsCard from '../home/components/NewsCard';
import SectionTitle from '../home/components/SectionTitle';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import blogServices from 'app/services/blog.service';
import { getFont } from 'app/hooks/getFont';
import { useTranslation } from 'react-i18next';
import publicServices from 'app/services/public/public.service';

const imgStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    width: "200px",
    minHeight: "200px",
    maxHeight: "auto",
}

const NewsandEventDetails = () => {

    const location = useLocation();
    const params = useParams();
    const { t, i18n } = useTranslation();

    const { data, isLoading } = useQuery(['get-a-blog'], () => blogServices.getABlog(params?.id), {
        enabled: !!params?.id,
    });

    // const { data: dataBanner, isLoading: isLoadingBanner } = useQuery(['get-blog-banner', data?.content?.imageUrl], () => publicServices.getImage(data?.content?.imageUrl), {
    //     enabled: !!data?.content?.imageUrl
    // });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Div sx={{ mx: "8vw", background: "#FFFFFF", mt: 3, mb: 3 }}>
            <Grid container spacing={{ xs: 2, md: 4 }} alignItems={"flex-start"} >
                <Grid item xs={12} sm={4} md={5} style={imgStyle}>
                    <img src={data?.content?.imageServer ? data?.content?.imageServer : `${window.location.origin}/images/banner/noBanner.png`} height={"auto"} width={"100%"} style={{ borderRadius: "6px" }} />
                </Grid>
                <Grid item xs={12} sm={8} md={7}>
                    <Typography fontFamily={getFont(i18n)} variant='h1' fontWeight={600} fontSize={"25px"}>
                        {i18n.language == "bn-BD" ? data?.content?.articleTitleBn : data?.content?.articleTitle}
                    </Typography>
                    <div style={{ fontFamily: getFont(i18n) }} dangerouslySetInnerHTML={{ __html: i18n.language == "bn-BD" ? data?.content?.articleBodyBn : data?.content?.articleBody }}>

                    </div>
                </Grid>
            </Grid>

            {/* <Div sx={{ mt: 4 }}>
                <SectionTitle title={"More Related"} />
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ sm: 4, md: 8, lg: 8, xl: 12 }}>
                    {Array.from(Array(3)).map((_, index) => (
                        <Grid item xs={2} sm={4} md={4} key={index}>
                            <NewsCard />
                        </Grid>
                    ))}
                </Grid>
            </Div> */}

        </Div>
    )
}

export default NewsandEventDetails;