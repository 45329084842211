import React from 'react';
import { Link, List, ListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Facebook, Instagram, LinkedIn, LocalPhone, MenuBookOutlined, PhoneEnabled, Twitter, YouTube } from '@mui/icons-material';
import { AUTHORITY } from 'app/config/localStorageConfig';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const orgStyle = {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-between'
}

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:575px)');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();


    return (
        <Div sx={{
            bgcolor: 'background.paper',
        }}>
            <Div sx={{
                background: '#FBAD1D',
                py: 7,
                px: { lg: 13, xs: 4 },
            }}>
                <Div id='footer' sx={{
                    display: 'flex',
                    flexWrap: "wrap",
                    justifyContent: 'space-between',
                    alignItems: 'start',
                }}>
                    <Div>
                        <Typography fontFamily={getFont(i18n)} variant='h4' color={"#0C4CA3"} fontWeight={600}>
                            {t("footer.upper.col1.title")}
                        </Typography>
                        <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400} sx={{ width: "180px" }}>
                            {t("footer.upper.col1.p")}
                            <span style={{ marginLeft: "5px", color: "blue" }} onClick={() => navigate(`/about`)} >{t("footer.upper.col1.more")}</span>
                        </Typography>
                    </Div>
                    <Div>
                        <Typography fontFamily={getFont(i18n)} variant='h4' color={"#0C4CA3"} fontWeight={600}>{t("footer.upper.col2.title")}</Typography>
                        <List>
                            <Link to="http://www.probashi.gov.bd/" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} width={"220px"} fontWeight={400}>{t("footer.upper.col2.li1")}</Typography>
                            </Link>
                            <Link to="http://www.wewb.gov.bd/" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col2.li2")}</Typography>
                            </Link>
                            <Link to="http://www.bmet.gov.bd/" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col2.li3")}</Typography>
                            </Link>
                            <Link to="https://www.mrc-bangladesh.org/" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col2.li4")}</Typography>
                            </Link>
                            <Link to="https://reintegrationfacility.eu/ " target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col2.li5")}</Typography>
                            </Link>
                            <Link to="https://www.icmpd.org/" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col2.li6")}</Typography>
                            </Link>
                        </List>
                    </Div>
                    <Div>
                        <Typography fontFamily={getFont(i18n)} variant='h4' color={"#0C4CA3"} fontWeight={600}>{t("footer.upper.col3.title")}</Typography>
                        <List>
                            <Link to="https://www.amiprobashi.com/" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col3.li1")}</Typography>
                            </Link>
                            <Link to="http://www.wewb.gov.bd/site/page/ea2e41f4-27ba-4c57-9b03-601611dccd58/-" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col3.li2")}</Typography>
                            </Link>
                            <Link to="https://bangladesh.iom.int/news/first-ever-digital-data-platform-improved-reintegration-returning-migrants" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col3.li3")}</Typography>
                            </Link>
                            <Link to="https://wewb.portal.gov.bd/site/project/27c6decf-6e5c-4cd9-b4d4-5a0deed6be89" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col3.li4")}</Typography>
                            </Link>
                        </List>
                    </Div>
                    <Div>
                        <Typography fontFamily={getFont(i18n)} variant='h4' color={"#0C4CA3"} fontWeight={600}>{t("footer.upper.col4.title")}</Typography>
                        <List>

                            <Div sx={{ display: "flex" }}>
                                <PhoneEnabled sx={{ fontSize: "18px", fill: "white", mr: 1 }} />
                                <Typography fontFamily={getFont(i18n)} variant='h4' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col4.li1")}</Typography>
                            </Div>
                            <Div sx={{ display: "flex" }}>
                                
                                <Typography fontFamily={getFont(i18n)} variant='h4' color={"#FFFFFF"} fontWeight={400} ml={3.5} >{t("footer.upper.col4.lim1")}</Typography>
                            </Div>
                            <Link to="https://www.mrc-bangladesh.org/en/contact-us" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col4.li3")}</Typography>
                            </Link>
                            <Link to="/faq" component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{t("footer.upper.col4.li4")}</Typography>
                            </Link>
                            <Link to="https://www.youtube.com/watch?v=J0w3NznX-4I" target={"_blank"} component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{(t("login.tutorial"))}</Typography>
                            </Link>

                            {
                                !localStorage.getItem(AUTHORITY) && (
                                    <Link sx={{ ml: "auto" }} to="/auth/provider/login" component={RouterLink} underline="none">
                                        <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{i18n.language == "bn-BD" ? "সেবার প্রদানকারীর লগইন" : "Service Provider Login"}</Typography>
                                    </Link>
                                )
                            }
                            {/* <Link to="/" component={RouterLink} underline="none" sx={{ display: "flex" }}>
                                <MenuBookOutlined sx={{ fill: "white", mr: 1 }} />
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400} sx={{ mt: 0.5 }}>User Manual</Typography>
                            </Link> */}
                        </List>
                    </Div>
                </Div>
                <Div sx={{
                    display: 'flex'
                }}>
                    <Link href='https://www.facebook.com/bangladeshmrc/' target={"_blank"}>
                        <Facebook sx={{ fill: "white", mr: 1 }} />
                    </Link>
                    <Link href='https://twitter.com/mrc_bangladesh' target={"_blank"}>
                        <Twitter sx={{ fill: "white", mr: 1 }} />
                    </Link>
                    {/* <Link href='https://www.linkedin.com/' target={"_blank"}>
                        <LinkedIn sx={{ fill: "white", mr: 1 }} />
                    </Link> */}
                    <Link href='https://www.instagram.com/mrc_bangladesh/?hl=en' target={"_blank"}>
                        <Instagram sx={{ fill: "white" }} />
                    </Link>

                    {/* {
                        !localStorage.getItem(AUTHORITY) && (
                            <Link sx={{ ml: "auto" }} to="/auth/provider/login" component={RouterLink} underline="none">
                                <Typography fontFamily={getFont(i18n)} variant='h6' color={"#FFFFFF"} fontWeight={400}>{i18n.language == "bn-BD" ? "সেবার প্রদানকারীর লগইন" : "Service Provider Login"}</Typography>
                            </Link>
                        )
                    } */}
                </Div>
            </Div>
            <Div sx={{
                display: 'flex',
                flexWrap: "wrap",
                justifyContent: 'space-between',
                alignItems: 'start',
                py: 2,
                px: { lg: 13, xs: 4 },
            }}>
                <Div sx={orgStyle}>
                    <Typography fontFamily={getFont(i18n)} variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>{t("footer.lower.funded-by")}</Typography>
                    <a href='https://european-union.europa.eu/index_en' target="_blank">
                        <img src={window.location.origin + '/images/european-union.png'} width={"33px"} />
                    </a>
                    <Typography fontFamily={getFont(i18n)} variant={"caption"} color={"text.primary"} sx={{ mb: 1 }}>
                        {t("footer.lower.european-union")}
                    </Typography>
                </Div>
                <Div sx={[orgStyle, { marginLeft: isMobile ? "" : "30px" }]}>
                    <Typography fontFamily={getFont(i18n)} variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>{t("footer.lower.implementation-partner")}</Typography>
                    <Stack direction={"row"} spacing={2} alignItems={"center"} >
                        <a href='http://www.wewb.gov.bd/' target="_blank">
                            <img src={window.location.origin + '/images/BD.png'} width={"50px"} />
                        </a>
                        <a href='https://www.icmpd.org/' target="_blank">
                            <img src={window.location.origin + '/images/source.png'} width={"95px"} />
                        </a>
                        <a href='https://reintegrationfacility.eu/' target="_blank">
                            <img src={window.location.origin + '/images/RRF.png'} width={"99px"} />
                        </a>
                    </Stack>
                </Div>
                <Div sx={[orgStyle, { marginLeft: isMobile ? "" : "auto" }]}>
                    <Typography fontFamily={getFont(i18n)} variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>{t("footer.lower.Design & Development Partner")}</Typography>
                    <a href='https://pridesys.com/' target="_blank">
                        <img src={window.location.origin + '/images/pridesys-logo.png'} width={"138px"} />
                    </a>
                </Div>
            </Div>
        </Div>
    );
};

export default Footer;
