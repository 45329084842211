import React from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import ContactItem from "./ContactItem";
import useChatApp from "../../hooks/useChatApp";
import { chatService } from "../../../../../services/chat-services";
import { contacts } from 'app/services/mock-data/contacts';
import { useQuery } from 'react-query';
import chatServices from 'app/services/chat.service';
import { useRef } from 'react';
import JumboSearch from '@jumbo/components/JumboSearch/JumboSearch';
import Div from '@jumbo/shared/Div/Div';

const ContactsList = () => {
    const { contactConversationsListRef } = useChatApp();

    const [data, setdata] = React.useState();
    const [tempData, settempData] = React.useState();

    const renderContact = React.useCallback((contact) => {
        return (
            <ContactItem contactItem={contact} />
        )
    }, []);

    const onSearch = (value) => {
        if (!!value) {
            const result = data?.content?.filter((element) => element?.allForSearch?.includes(value));
            settempData(result);
        } else {
            settempData(data?.content);
        }
    }

    React.useEffect(() => {
        settempData(data?.content)
    }, [data]);

    return (
        <JumboRqList
            ref={contactConversationsListRef}
            service={chatServices.getChatContacts}
            renderItem={renderContact}
            primaryKey={"channelSubscriber_userId"}
            queryOptions={{
                queryKey: "contacts-list",
                dataKey: "contacts"
            }}
            componentElement={"div"}
            toolbar={
                <Div sx={{ px: 2, py: 1 }}>
                    <JumboSearch onChange={(value) => onSearch(value)} />
                </Div>
            }
            filterData={tempData}
            setData={setdata}
        />
    );
};

export default ContactsList;
