import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import Div from '@jumbo/shared/Div/Div';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import applicationServices from 'app/services/application.service';
import React, { useState, useEffect } from 'react';
import { Table, TableContainer, Typography, TableHead, TableRow, TableCell, TableBody, Paper, Stack, Button, IconButton, ButtonGroup } from '@mui/material';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import JumboSearch from '@jumbo/components/JumboSearch/JumboSearch';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ContactDetail from 'app/pages/apps/chat/components/ContactDetail';
import { ArrowBack, GridOn, InfoRounded, PictureAsPdf } from '@mui/icons-material';
import ApplicationDetail from './components/ApplicationDetail';

const SubmittedData = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [list, setlist] = useState([]);
    const [searchText, setsearchText] = useState("");

    const { data, isLoading } = useQuery(["get-submitted-data"], () => applicationServices.getApplicationValueList(params?.id), {
        enabled: !!params?.id,
        onSuccess: data => setlist(data?.content)
    })

    const SearchAnything = (value) => {
        setsearchText(value);
        try {
            const result = data?.content?.filter((obj) =>
                JSON.stringify(obj)?.toLowerCase()?.includes(value.toLowerCase())
            )
            setlist(result);
        } catch (error) {

        }
    }

    const ParsedData = (data, form) => {
        return form?.map((item, i) => (
            <Stack direction={"row"} key={i}>
                <Typography variant='body2' color={"#a7a7a7"}>
                    {item?.fieldLabel}:
                </Typography>
                <Typography sx={{ ml: 1 }}>
                    {data[item?.name]}
                </Typography>
            </Stack>
        ))
    }

    const { showDialog, hideDialog } = useJumboDialog();
    const { showDialog: showDetailsDialog, hideDialog: hideDetailsDialog } = useJumboDialog();

    const showContactDetail = (id) => {
        showDialog({
            content: <ContactDetail contact={id} onClose={hideDialog} />
        })
    };

    const showApplicationDetail = (data) => {
        showDetailsDialog({
            content: <ApplicationDetail data={data} onClose={hideDetailsDialog} />
        })
    };

    const CreateExportTable = () => {

        var css = `<style type="text/css" media="print">
            @page { margin: 20; }
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }
            
            td, th {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
            }

            th:last-child {
                width: 250px;
            }

            .css-m69qwo-MuiStack-root{
                display: flex;
                width: 100%;
            }

            .MuiTypography-body1{
                margin-left: 7px;
            }
        </style>`

        var header = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title></title>${css}</head><body>`;

        var footer = "</body></html>";

        var html = header + document.getElementById("contentToPrint").innerHTML + footer;

        return html;


    }

    const exportHTMLtoPDF = () => {

        var sourceHTML = CreateExportTable();

        if (sourceHTML) {
            var win = window.open('', '', 'height=3508 ,width=2480');
            win.document.write(sourceHTML);
            win.document.close();

            win.print();

        } else {
            alert("কোনো তথ্য পাওয়া যায়নি");
        }

    }


    const exportHTMLtoExcel = () => {

        var sourceHTML = CreateExportTable();
        var tableSelect = document.getElementById("contentToPrint");
        var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

        if (sourceHTML) {
            var source = `data:application/vnd.ms-excel, ` + tableHTML;
            var fileDownload = document.createElement("a");
            document.body.appendChild(fileDownload);
            fileDownload.href = source;
            fileDownload.download = `${list?.[0]?.template?.templateName}.xls`;
            fileDownload.click();
            document.body.removeChild(fileDownload);
        } else {
            alert("কোনো তথ্য পাওয়া যায়নি");
        }

    }

    return (
        <Div>
            <JumboContentLayout
                header={
                    <PageHeader
                        title={"Submitted Data"}
                        subheader={"Application form's submitted data are here"} />
                }
            >
                <TableContainer component={Paper}>
                    <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                        <IconButton sx={{ ml: 1, mt: 1 }} onClick={() => navigate("/service-provider/application-form")}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant='h3' ml={2} mt={2}>{list?.[0]?.template?.templateName}</Typography>
                        <JumboSearch
                            sx={{ ml: "auto", my: 1, mr: 1, width: "25%" }}
                            onChange={SearchAnything}
                        />
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Div sx={{ ml: 2 }}>
                            Found {list?.length}
                        </Div>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography ml={3} mt={1}>Export as: </Typography>
                            <ButtonGroup variant="text" size='sm' aria-label="text button group">
                                <Button sx={{ ml: 1, mt: 1 }} onClick={exportHTMLtoPDF}>
                                    PDF
                                </Button>
                                <Button sx={{ ml: 1, mt: 1 }} onClick={exportHTMLtoExcel}>
                                    EXCEL
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    </Stack>
                    <Div id="contentToPrint">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Serial No.</TableCell>
                                    <TableCell>Service Name</TableCell>
                                    <TableCell>Applicant Name</TableCell>
                                    <TableCell>Date of Application</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>Detail View</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list?.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell >
                                            {row?.services?.name}
                                        </TableCell>
                                        <TableCell style={{ cursor: "pointer" }} onClick={() => showContactDetail(row?.returneeRegistrationProfileId)} >{row.returneeRegistration.wageEarnerName}</TableCell>
                                        <TableCell >{moment(row?.createdAt).format("DD MMMM, YYYY")}</TableCell>
                                        <TableCell style={{ cursor: "pointer", textAlign: "center" }} onClick={() => showApplicationDetail({
                                            template: JSON.parse(row?.template?.templateBody),
                                            value: JSON.parse(row?.valuesBody),
                                            service: row?.services?.name,
                                            submittedDate: moment(row?.createdAt).format("DD MMMM, YYYY"),
                                        })} >
                                            <InfoRounded />
                                            {/* {
                                                ParsedData(JSON.parse(row?.valuesBody), JSON.parse(row?.template?.templateBody))
                                            } */}
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Div>
                </TableContainer>
            </JumboContentLayout>
        </Div>
    )
}

export default SubmittedData;