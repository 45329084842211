import React from 'react';
import Header from "./Header";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import Events from "../../../shared/widgets/Events";
import { alpha } from "@mui/material/styles";
import About from "./components/About";
import Biography from "./components/Biography";
import UserProfileSidebar from "./UserProfileSidebar";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import ReturnInfo from './components/ReturnInfo';
import { useQuery } from 'react-query';
import returneeServices from 'app/services/returnee.service';
import { USER_ID } from 'app/config/localStorageConfig';

const userId = localStorage.getItem(USER_ID);

const UserProfile = () => {
    const { theme } = useJumboTheme();

    const { data, isLoading } = useQuery(['get-returnee-profile'], () => returneeServices.getReturneeDetails(userId))

    return (
        <JumboContentLayout
            header={data && <Header data={data?.content} />}
            sidebar={data && <UserProfileSidebar data={data?.content} />}
            layoutOptions={{
                header: {
                    sx: {
                        mt: -4,
                        mb: -7.25,
                        mx: { xs: -4, lg: -6 },
                        p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                        //background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/profile-bg.jpg`,"1920x580")}) no-repeat center`,
                        // backgroundSize: 'cover',
                        backgroundImage: "linear-gradient(to right, #F53803, #F5D020)",
                        color: 'common.white',
                        position: 'relative',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            // backgroundColor: alpha(theme.palette.common.black, .65)
                        }
                    }
                },
                sidebar: {
                    sx: {
                        mr: 3.75,
                        width: { xs: '100%', lg: '33%' },
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0,
                            mr: 0,
                            order: 2
                        }
                    }
                },
                wrapper: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column'
                        }
                    }
                },
                main: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0
                        }
                    }
                }
            }}
        >
            <About data={data?.content} />
            <ReturnInfo data={data?.content} />
            {/* <Biography /> */}

        </JumboContentLayout>
    );
};

export default UserProfile;
