import React from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import { ListItemButton, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const MessageItem = ({ item }) => {
  const navigate = useNavigate();

  return (
    <ListItemButton
      component={"li"}
      disableRipple
      onClick={() =>
        navigate(
          item.notification_category == "Email" ? `/app/inbox` : `/app/chats`
        )
      }
    >
      {/* <ListItemAvatar>
                <Avatar src={item.user.profile_pic}/>
            </ListItemAvatar> */}
      <ListItemText
        primary={
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant={"h6"} mb={0.25}>
              {item?.message_from}
              {` (${item?.notification_category})`}
            </Typography>
            <Typography variant={"caption"} color={"text.secondary"}>
              {moment(item.created_at).format("DD MMMM, YYYY")}
            </Typography>
          </Stack>
        }
        secondary={
          <Typography noWrap color={"text.secondary"}>
            {item.message_body}
          </Typography>
        }
      />

      {/* <IconButton elevation={1} edge={"end"} size={"small"}><MoreHorizIcon /></IconButton> */}
    </ListItemButton>
  );
};

export default MessageItem;
