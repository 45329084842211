import Div from '@jumbo/shared/Div/Div';
import { ArrowCircleLeftRounded, ArrowCircleRightRounded, Cancel, Help } from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import { CATEGORY, LOCATION, L_TEXT, PROVIDER, SEARCH_TEXT } from 'app/config/localStorageConfig';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Caricuture = ({ data }) => {
    const navigate = useNavigate();
    const [show, setshow] = useState(true);
    const [nowShowing, setnowShowing] = useState({});
    const [index, setindex] = useState(0);

    const showNext = () => {
        setindex(previous => previous < data?.length - 1 ? previous + 1 : 0);
    }

    const showPrevious = () => {
        setindex(previous => previous != 0 ? previous - 1 : data?.length - 1);
    }

    useEffect(() => {
        setnowShowing(data[index])
    }, [index]);

    return (
        <Div sx={{
            position: "fixed",
            zIndex: 1000,
            right: "10px",
            bottom: "0px",
            display: "inline-block",
        }}>
            {
                show ? (
                    <>
                        <Div sx={{
                            width: "200px",
                            backgroundColor: "White",
                            color: "black",
                            textAlign: "center",
                            padding: "15px",
                            borderRadius: "6px",
                            boxShadow: "5px 5px 5px 2px rgba(0,0,0,0.55)",
                            fontFamily: "'Baloo Da 2', cursive",

                            position: "absolute",
                            zIndex: 1,

                            right: 0,
                            bottom: "210px"

                        }} className='tooltiptext' >
                            {nowShowing?.title}
                            <Button
                                sx={{ fontFamily: "'Baloo Da 2', cursive" }}
                                variant='text'
                                onClick={() => {
                                    navigate(nowShowing?.link);
                                    if (nowShowing?.link == "/service-list") {
                                        localStorage.setItem(PROVIDER, 0);
                                        localStorage.setItem(CATEGORY, 0);
                                        localStorage.setItem(LOCATION, 0);
                                        localStorage.setItem(SEARCH_TEXT, "");
                                        localStorage.setItem(L_TEXT, "");
                                    }
                                }}
                            >
                                {nowShowing?.titleLink}
                            </Button>
                            {
                                data?.length > 1 && (
                                    <Stack direction={"row"} justifyContent={"space-between"} >
                                        <IconButton size="small" onClick={() => showPrevious()}>
                                            <ArrowCircleLeftRounded />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => showNext()} >
                                            <ArrowCircleRightRounded />
                                        </IconButton>
                                    </Stack>
                                )
                            }
                        </Div>
                        <Div sx={{
                            borderRadius: "50%",

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <img src={`${window.location.origin}/images/cari-2.gif`} height={"200px"} />
                            <IconButton sx={{
                                position: "absolute",
                                right: 0,
                                bottom: "160px"
                            }}
                                onClick={() => setshow(false)}
                            >
                                <Cancel />
                            </IconButton>
                        </Div>
                    </>
                ) : (
                    <Div sx={{
                        borderRadius: "50%",
                        background: "#FBAD1D",
                        padding: "5px",
                        marginBottom: "10px",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }} onClick={() => setshow(true)}>
                        <Help fontSize='large' />
                    </Div>
                )
            }

        </Div >
    )
}

export default Caricuture;