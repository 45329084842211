import React from "react";

import routes from "./routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
import { AUTHORITY } from "./config/localStorageConfig";
import { useQuery } from "react-query";
import publicServices from "./services/public/public.service";
import YouTube from "@mui/icons-material/YouTube";
import { getFont } from "./hooks/getFont";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";

const AppRoutes = () => {
  const appRoutes = useJumboRoutes(routes);
  const { t, i18n } = useTranslation();
  const [isShow, setisShow] = React.useState(false);

  const { data } = useQuery("mode", () => publicServices.getMode());

  const handleMouseEnter = React.useCallback(() => {
    setisShow(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setisShow(false);
  }, []);

  return (
    <React.Fragment>
      {process.env.REACT_APP_IS_DEV && data?.content == "LOCAL" && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1500,
            height: "100px",
          }}
        >
          <img src={window.location.origin + "/images/dev.png"} height={100} />
        </div>
      )}

      {appRoutes}
      <div
        style={{
          position: "fixed",
          right: 0,
          top: "15%",
          zIndex: 1000,
          background: "#FFFFFF",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          borderTopLeftRadius: "50px",
          borderBottomLeftRadius: "50px",
          padding: "7px 3px 6px 10px",
        }}
      >
        <Link
          underline="none"
          className="tutorial"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          href="https://www.youtube.com/watch?v=J0w3NznX-4I"
          target="_blank"
        >
          <YouTube color="error" fontSize={"large"} />
          {isShow ? (
            <div className="tutorial-text">
              <Typography
                fontFamily={getFont(i18n)}
                variant="h6"
                color={"#FBAD1D"}
                fontWeight={600}
                ml={1}
                mt={0.5}
              >
                {t("login.tutorial")}
              </Typography>
            </div>
          ) : (
            ""
          )}
        </Link>
      </div>
    </React.Fragment>
  );
};
export default AppRoutes;
