import React from 'react';
import Div from "@jumbo/shared/Div";
import AuthUserSummary from "../AuthUserSummary";
import ChatGlobalSearch from "../ChatGlobalSearch";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { TabContext, TabPanel } from "@mui/lab";
import FavoriteConversationsList from "../FavoriteConverstionsList";
import RecentConversationsList from "../RecentConversationsList";
import ContactsList from "../ContactsList";
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const ChatAppSidebar = () => {
    const { t, i18n } = useTranslation();
    const [activeTab, setActiveTab] = React.useState("chat");
    return (
        <React.Fragment>
            <Div sx={{ px: 2, pt: 2 }}>
                <AuthUserSummary />
                {/* <ChatGlobalSearch /> */}
            </Div>
            <TabContext value={activeTab}>
                <Div sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        variant="fullWidth"
                        onChange={(event, newTab) => setActiveTab(newTab)}
                    >
                        <Tab sx={{ fontFamily: getFont(i18n) }} label={i18n.language == "bn-BD" ? "চ্যাট" : "Chats"} value={"chat"} />
                        <Tab sx={{ fontFamily: getFont(i18n) }} label={i18n.language == "bn-BD" ? "কন্টাক্ট" : "Contacts"} value={'contact'} />
                    </TabList>
                </Div>
                <JumboScrollbar
                    style={{ minHeight: 300 }}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                >
                    <TabPanel value={"chat"} sx={{ p: 0 }}>
                        {/* <FavoriteConversationsList/> */}
                        <RecentConversationsList />
                    </TabPanel>
                    <TabPanel value={"contact"} sx={{ p: 0 }}>
                        <ContactsList />
                    </TabPanel>
                </JumboScrollbar>
            </TabContext>
        </React.Fragment>
    );
};

export default ChatAppSidebar;
