import React from 'react';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { BigArrow } from 'app/utils/icon/icon';
import { storeToken } from '@jumbo/components/JumboAuthProvider/authHelpers';
import { useMutation, useQueryClient } from 'react-query';
import authServices from 'app/services/auth/auth.service';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ACCESS_TOKEN, AUTHORITY, NAME, PROFILE_IMAGE_URL, USERNAME, USER_ID, USER_REG_ID } from 'app/config/localStorageConfig';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const MySwal = withReactContent(Swal)

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Login = ({ disableSmLogin }) => {

    const { t, i18n } = useTranslation();
    const { setAuthToken } = useJumboAuth();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:575px)');
    const queryClient = useQueryClient();
    const [showPassword, setShowPassword] = React.useState(false);
    const [hasMobile, sethasMobile] = React.useState(true);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { mutate, isLoading, data } = useMutation(authServices.loginUser, {
        onSuccess: (data) => {
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                html: <i>{data?.message}</i>,
            }).then((result) => {
                if (result.isConfirmed) {
                    if (data?.statusCode == 200) {
                        localStorage.setItem(ACCESS_TOKEN, data?.content?.token);
                        localStorage.setItem(AUTHORITY, data?.content?.authorities?.[0]?.authority);
                        localStorage.setItem(USERNAME, data?.content?.username);
                        localStorage.setItem(NAME, data?.content?.name);
                        localStorage.setItem(USER_ID, data?.content?.userProfileId);
                        localStorage.setItem(USER_REG_ID, data?.content?.userId);
                        localStorage.setItem(PROFILE_IMAGE_URL, data?.content?.userProfileServer);
                        window.location.assign("/user/profile");
                    }
                }
            })

        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: !isMobile && 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#FBAD1D`,
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography fontFamily={getFont(i18n)} variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>
                                {t("login.col1.title")}
                            </Typography>
                            <Typography fontFamily={getFont(i18n)} variant={"body1"} mb={2}>
                                {t("login.col1.p1")}
                            </Typography>
                            <Typography fontFamily={getFont(i18n)} variant={"body1"}>
                                {t("login.col1.p2")}
                                <Link
                                    to={"/auth/forgot-password"}
                                    color={"#42464F"}
                                    underline={"none"}
                                    component={RouterLink}
                                    sx={{ ml: 1, fontFamily: getFont(i18n) }}
                                >
                                    {t("button.recover")}
                                </Link>
                            </Typography>
                        </Div>

                        <Div sx={{ mt: 'auto', position: "relative" }}>
                            <Div style={{ transform: "rotate(180deg)", paddingLeft: "130px" }}>
                                <BigArrow height={207} width={167} fill={"white"} />
                            </Div>
                            <Link to="/" component={RouterLink} underline="none" style={{ position: "absolute", top: "95px", left: "15px" }}>
                                <img src={`${ASSET_IMAGES}/logo.png`} height={25} alt="Jumbo React" />
                            </Link>
                        </Div>

                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            username: '',
                            password: '',
                            usernameBy: 'MOBILE_NO',
                            hasMobile: true,
                        }}
                        validationSchema={yup.object({
                            hasMobile: yup.boolean(),
                            username: yup.string().when("hasMobile", {
                                is: true,
                                then: yup.string('Enter your mobile number')
                                    .min(10, "Mobile number must be at least 11 characters")
                                    .max(11, "Mobile number is not valid")
                                    .matches(phoneRegExp, 'Mobile number is not valid')
                                    .required('mobile number is required'),
                            }).when("hasMobile", {
                                is: false,
                                then: yup.string('Enter your email address')
                                    .email("Email address is invalid")
                                    .required('Email address is required'),
                            }),

                            password: yup
                                .string('Enter your password')
                                .required('Password is required'),
                        })}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            delete data.hasMobile;
                            console.log(data);
                            mutate(data);
                            setSubmitting(false);
                        }}
                    >
                        {(props) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>

                                <ToggleButtonGroup
                                    color="primary"
                                    value={hasMobile}
                                    exclusive
                                    fullWidth
                                    onChange={() => {
                                        sethasMobile(!hasMobile);
                                        props.setFieldValue("hasMobile", !hasMobile);
                                        props.setFieldValue("usernameBy", hasMobile ? "EMAIL" : "MOBILE_NO");
                                    }}
                                    aria-label="Platform"
                                    sx={{ mb: 2, borderColor: "#FBAD1D" }}
                                >
                                    <ToggleButton value={true} disabled={hasMobile} sx={{ borderColor: hasMobile && "#FBAD1D", fontFamily: getFont(i18n) }} >{t("label.mobile")}</ToggleButton>
                                    <ToggleButton value={false} disabled={!hasMobile} sx={{ borderColor: !hasMobile && "#FBAD1D", fontFamily: getFont(i18n) }}>{t("placeholder.email")}</ToggleButton>
                                </ToggleButtonGroup>

                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        type={hasMobile ? "text" : "email"}
                                        name="username"
                                        label={hasMobile ? t("label.mobile") : t("placeholder.email")}
                                        placeholder={hasMobile ? t("placeholder.mobile") : t("placeholder.reg-email")}
                                        inputProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: getFont(i18n)
                                            }
                                        }}
                                    />
                                </Div>
                                <Div sx={{ mt: 1, mb: 2 }}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel style={{ fontFamily: getFont(i18n) }} htmlFor="outlined-adornment-password">{t("label.password")}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            type={showPassword ? 'text' : 'password'}
                                            error={props.touched.password && props.errors.password ? true : false}
                                            {...props.getFieldProps('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={t("label.password")}
                                        />
                                    </FormControl>
                                </Div>
                                {/* <Div sx={{ mb: 2 }}>
                                    <FormControlLabel control={<Checkbox />} label="Remember me" />
                                </Div> */}
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3, fontFamily: getFont(i18n) }}
                                    loading={isLoading}
                                    disabled={!props.isValid}
                                >{t("button.login")}</LoadingButton>

                                <Typography fontFamily={getFont(i18n)} variant={"body1"} mt={'auto'}>
                                    {t("login.col2.p")} <Link underline={"none"} component={RouterLink}
                                        to={"/auth/signup/step-1"}  >{t("button.create New")}</Link>
                                </Typography>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login;
