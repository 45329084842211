import Div from '@jumbo/shared/Div';
import { CalendarMonthOutlined } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import { getFont } from 'app/hooks/getFont';
import publicServices from 'app/services/public/public.service';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const imgStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    overflow: "hidden",
    marginBottom: "10px"
}

const NewsCard = ({
    data,
    isLoading
}) => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isHover, setIsHover] = useState(false);

    // const { data: dataBanner, isLoading: isLoadingBanner } = useQuery(['get-blog-banner-image', data?.imageUrl], () => publicServices.getImage(data?.imageUrl), {
    //     enabled: !!data?.imageUrl
    // });

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const cardStyle = {
        background: "#FFFFFF",
        boxShadow: isHover ? "0px 8px 20px rgb(0 73 203 / 12%)" : "0px 0px 2px rgba(0, 0, 0, 0.25)",
        transform: isHover && "translateY(-3px)",
        transition: isHover && "all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)",
        border: isHover ? "1px solid rgba(0, 0, 0, 0.15)" : "1px solid white",
        borderRadius: "10px",
        padding: "10px",
        cursor: "pointer",
        height: "100%",
        overflow: "hidden",

        display: "flex",
        flexDirection: "column"
    }

    return isLoading ? (
        <Div style={cardStyle}>
            <Skeleton variant="rounded" width={320} height={140} />
            <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            <Skeleton variant="rounded" width={320} height={250} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </Div>
    ) : (
        <Div style={cardStyle} onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave} onClick={() => navigate(`/news-and-events/${data?.articleId}`)} >
            <Div style={imgStyle}>
                <img src={data?.imageServer ? data?.imageServer : `${window.location.origin}/images/banner/noBanner.png`} width={"100%"} height={"auto"} />
            </Div>
            <Typography fontFamily={getFont(i18n)} variant={"h4"} fontWeight={700} color={"#000000"}>
                {i18n.language == "bn-BD" ? data?.articleTitleBn : data?.articleTitle}
            </Typography>
            <Typography variant={"h6"} fontWeight={400} color={"#000000"}>
                <div style={{
                    fontFamily: getFont(i18n),
                    height: "210px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "140%"
                }} dangerouslySetInnerHTML={{ __html: i18n.language == "bn-BD" ? data?.articleBodyBn : data?.articleBody }}>

                </div>
            </Typography>
            <Typography variant={"h4"} fontWeight={500} color={"#0C4CA3"} style={{ marginTop: "auto" }} >
                <CalendarMonthOutlined sx={{ fontSize: "20px" }} />
                {moment(data?.articlePublishDate).format("DD MMMM, YYYY")}
            </Typography>
        </Div>
    )
}

export default NewsCard;