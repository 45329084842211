import Div from '@jumbo/shared/Div';
import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import ServiceFilter from './ServiceFilter';
import ProviderFilter from './ProviderFilter';
import LocationFilter from './LocationFilter';
import { Button, Typography } from '@mui/material';
import { CATEGORY, LOCATION, L_TEXT, PROVIDER, SEARCH_TEXT, SUBCATEGORY } from 'app/config/localStorageConfig';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const ServiceSidebar = ({
    setproviderFilter,
    setcategoryFilter,
    setlocationFilter
}) => {
    const { t, i18n } = useTranslation();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12} marginBottom={"10px"} display={"flex"} justifyContent="space-between">
                <Typography fontFamily={getFont(i18n)} color='inherit' size='small'>
                    {i18n.language == "bn-BD" ? "অ্যাডভান্স ফিল্টার" : "Advanced Filter"}
                </Typography>
                <Button onClick={() => {
                    localStorage.setItem(CATEGORY, "0")
                    localStorage.setItem(LOCATION, "0")
                    localStorage.setItem(PROVIDER, "0")
                    localStorage.setItem(SEARCH_TEXT, "");
                    localStorage.setItem(L_TEXT, "");
                }} color='inherit' size='small' sx={{ fontFamily: getFont(i18n) }}>
                    {i18n.language == "bn-BD" ? "রিসেট" : "Reset"}
                </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <LocationFilter setlocationFilter={setlocationFilter} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <ServiceFilter setcategoryFilter={setcategoryFilter} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <ProviderFilter setproviderFilter={setproviderFilter} />
            </Grid>
        </Grid>
    )
}

export default ServiceSidebar;