import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";
import { LNG } from "app/config/localStorageConfig";
import { useTranslation } from "react-i18next";
import LanguageSwitchDropdown from "app/shared/LanguageSwitch/LanguageSwitchDropdown";
import { Link, Stack } from "@mui/material";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Div from "@jumbo/shared/Div/Div";

const SoloPage = ({ children }) => {
    const { setJumboLayoutOptions } = useJumboLayout();
    const { i18n } = useTranslation();

    const lang = JSON.parse(localStorage.getItem(LNG));

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig);
        if (lang) {
            i18n.changeLanguage(lang.locale);
        }
    }, []);

    return (
        <Div sx={{ width: "100%" }}>
            <nav>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                    <Link to={"/"} component={RouterLink} underline="none">
                        <img src={`${window.location.origin}/images/logo.png`} height={40} />
                    </Link>
                    <LanguageSwitchDropdown />
                </Stack>
            </nav>
            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} height={"80vh"} >
                {children}
            </Stack>
        </Div>
    );
};

export default SoloPage;
