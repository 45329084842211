import { axiosIns } from "app/config/apiConfig";

const getProviderList = async () => {
  const response = await axiosIns.get(`/icmpd/provider/info/all`);
  return response.data;
};

const getProviderListHome = async (categoryId = 0, page, lang = 1) => {
  const response = await axiosIns.get(
    page == "all"
      ? `/icmpd/provider/info/all`
      : `/icmpd/provider/info/all/${categoryId}?lang=${lang}`
  );
  return response.data;
};

const getProviderInfoList = async () => {
  const response = await axiosIns.get(`/provider/info/all`);
  return response.data;
};

const createProvider = async (data) => {
  const response = await axiosIns.post(`/provider/info/save`, data);
  return response.data;
};

const updateProvider = async ({ id, data }) => {
  const response = await axiosIns.post(`/provider/info/save/${id}`, data);
  return response.data;
};

const getProviderEmployeeList = async (params) => {
  const response = await axiosIns.get(
    `/provider/reg/approve/list${!!params ? `/${params}` : ""}`
  );
  return response.data;
};

const approvedProviderEmployee = async ({ id, data }) => {
  const response = await axiosIns.post(`/provider/reg/approve/${id}`, data);
  return response.data;
};

const updateProviderProfile = async ({ id, data }) => {
  const response = await axiosIns.post(`/provider/profile/save/${id}`, data);
  return response.data;
};

const getProviderProfile = async (id, lang = 1) => {
  const response = await axiosIns.get(
    `/icmpd/provider/profile/list/${id}?lang=${lang}`
  );
  return response.data;
};

const providerChangePassword = async (data) => {
  const response = await axiosIns.post(`/provider/reg/changePassword`, data);
  return response.data;
};

const updateRepresentativeProfile = async (data) => {
  const response = await axiosIns.post(`/provider/reg/rep-info/edit`, data);
  return response.data;
};

const getRepresentativeProfile = async () => {
  const response = await axiosIns.get(`/provider/reg/rep-info`);
  return response.data;
};

const providerServices = {
  getProviderList,
  getProviderInfoList,
  createProvider,
  updateProvider,
  getProviderEmployeeList,
  approvedProviderEmployee,
  updateProviderProfile,
  getProviderProfile,
  getProviderListHome,
  providerChangePassword,
  getRepresentativeProfile,
  updateRepresentativeProfile,
};

export default providerServices;
