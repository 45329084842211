import Div from '@jumbo/shared/Div/Div';
import { Grid, Typography } from '@mui/material';
import { getFont } from 'app/hooks/getFont';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const imgStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
}

const About = () => {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Div sx={{ mx: "8vw", background: "#FFFFFF", mt: 3, mb: 3 }}>
            <Grid container spacing={{ xs: 2, md: 4 }} alignItems={"flex-start"} >
                <Grid item xs={12} sm={4} md={5} style={imgStyle}>
                    <img src={`${window.location.origin}/images/about-banner.png`} width={"100%"} style={{ borderRadius: "10px" }} />
                </Grid>
                <Grid item xs={12} sm={8} md={7}>
                    <Typography variant='h1' fontWeight={600} fontSize={"25px"} fontFamily={getFont(i18n)} >
                        {t("about.title")}
                    </Typography>
                    <Typography variant='body1' fontFamily={getFont(i18n)} sx={{ mt: 2 }}>
                        {t("about.para1")}
                    </Typography>
                    <Typography variant='body1' fontFamily={getFont(i18n)} sx={{ mt: 2 }}>
                        {t("about.para2")}
                    </Typography>
                    <Typography variant='body1' fontFamily={getFont(i18n)} sx={{ mt: 2 }}>
                        {t("about.para3")}
                    </Typography>
                    <Typography variant='body1' fontFamily={getFont(i18n)} sx={{ mt: 2 }}>
                        {t("about.para4")}
                    </Typography>
                    <Typography variant='body1' fontFamily={getFont(i18n)} sx={{ mt: 2 }}>
                        {t("about.para5")}
                    </Typography>
                    <Typography variant='body1' fontFamily={getFont(i18n)} sx={{ mt: 2 }}>
                        {t("about.para6")}
                    </Typography>
                    <Typography variant='body1' fontFamily={getFont(i18n)} sx={{ mt: 2 }}>
                        {t("about.para7")}
                    </Typography>
                </Grid>
            </Grid>
        </Div>
    )
}

export default About;