export const ACCESS_TOKEN = "access_token";
export const LNG = "lng";
export const AUTHORITY = "authority";
export const USERNAME = "username";
export const NAME = "name";
export const USER_ID = "user_id";
export const USER_REG_ID = "user_reg_id";
export const PROVIDER_REG_ID = "provider_reg_id";
export const CATEGORY = "category";
export const SUBCATEGORY = "subcategory";
export const SELECTED_SUBCATEGORY = "select_subcategory";
export const PROVIDER = "provider";
export const LOCATION = "location";
export const L_TEXT = "locationText";
export const SEARCH_TEXT = "searchText";
export const PROFILE_IMAGE_URL = "profileImageUrl";