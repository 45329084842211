import React from "react";
import { Box, MenuItem, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useMutation, useQuery } from "react-query";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import providerServices from "app/services/provider/provider.service";
import messageServices from "app/services/message.service";
import { useTranslation } from "react-i18next";
import { getFont } from "app/hooks/getFont";

const ComposeForm = ({ mailItem, onSave }) => {
  const { t, i18n } = useTranslation();
  const Swal = useSwalWrapper();
  const [to, setTo] = React.useState();
  const [subject, setSubject] = React.useState();
  const [message, setMessage] = React.useState(
    mailItem ? mailItem?.message : ""
  );

  const { data } = useQuery(["get-provider-list"], () =>
    providerServices.getProviderList()
  );

  const addMailMutation = useMutation(messageServices.composeMessage, {
    onSuccess: (data) => {
      onSave();
      Swal.fire({
        position: "center",
        icon: data?.statusCode == 200 ? "success" : "error",
        title: data?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
  });

  const onSendMessage = () => {
    addMailMutation.mutate({
      providerInfoIdTo: to,
      communicationSubject: subject,
      communicationBody: message,
    });
  };

  const oncancel = () => {
    setTo("");
    setSubject("");
    setMessage("");
  };

  return (
    <Box
      component={"form"}
      sx={{
        "& > .MuiFormControl-root": {
          marginBottom: 1,
        },
        mt: 2,
        mx: 2,
      }}
    >
      <TextField
        fullWidth
        select
        size={"small"}
        type={"email"}
        label={i18n.language == "bn-BD" ? "বরাবর" : "To"}
        value={to}
        inputProps={{
          style: {
            fontFamily: getFont(i18n),
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: getFont(i18n),
          },
        }}
        onChange={(e) => setTo(e.target.value)}
      >
        {data?.content?.map((item, i) => (
          <MenuItem key={i} value={item?.providerInfoId}>
            {i18n.language == "bn-BD"
              ? item?.info_description_bn
              : item?.info_description}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        size={"small"}
        placeholder={i18n.language == "bn-BD" ? "বিষয়" : "Subject"}
        value={subject}
        inputProps={{
          style: {
            fontFamily: getFont(i18n),
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: getFont(i18n),
          },
        }}
        onChange={(e) => setSubject(e.target.value)}
      />
      <TextField
        fullWidth
        multiline
        rows={7}
        placeholder={i18n.language == "bn-BD" ? "বার্তা" : "Message"}
        value={message}
        inputProps={{
          style: {
            fontFamily: getFont(i18n),
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: getFont(i18n),
          },
        }}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Stack direction={"row"} spacing={2}>
        <Button
          disabled={!to || !subject || !message}
          sx={{ fontFamily: getFont(i18n) }}
          variant={"contained"}
          onClick={onSendMessage}
        >
          {t("button.send")}
        </Button>
        <Button
          sx={{ fontFamily: getFont(i18n) }}
          variant={"outlined"}
          onClick={oncancel}
        >
          {t("button.cancel")}
        </Button>
      </Stack>
    </Box>
  );
};
/* Todo mailItem, onSave prop define */
export default ComposeForm;
