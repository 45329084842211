import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Link from "@mui/material/Link";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import { MyLocationOutlined } from '@mui/icons-material';
import Div from '@jumbo/shared/Div';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const Contacts = ({ data }) => {
    const { t, i18n } = useTranslation();
    return (
        <JumboCardQuick
            title={i18n.language == "bn-BD" ? "যোগাযোগ" : "Contact"}
            headerSx={{
                borderBottom: 1, borderColor: 'divider',
            }}
            noWrapper
        >
            <List disablePadding sx={{ mb: 2 }}>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <LocalPhoneOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary">{t("label.mobile")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.phoneNumber ? data?.phoneNumber : "---"}</Typography>}
                    />
                </ListItem>

                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <EmailOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary">{t("placeholder.email")}</Typography>}
                        secondary={<Link variant="body1" href="#" underline="none">{data?.email ? data?.email : "---"}</Link>}
                    />
                </ListItem>

                {/* <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <MyLocationOutlined />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary">Current Address</Typography>}
                        secondary={<>
                            <Div style={{ display: "flex" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>Village/Street: </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">New road</Typography>
                            </Div>
                            <Div style={{ display: "flex" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>Post Office: </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">Babugonj</Typography>
                            </Div>
                            <Div style={{ display: "flex" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>Upazila: </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">Sreepur</Typography>
                            </Div>
                            <Div style={{ display: "flex" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>District: </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">Gazipur</Typography>
                            </Div>
                            <Div style={{ display: "flex" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>Division: </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">Dhaka</Typography>
                            </Div>
                        </>}
                    />
                </ListItem> */}

                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <LocationOnOutlined />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography={true}
                        primary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary">{t("user.profile.contacts.address-section.address")}</Typography>}
                        secondary={<>
                            <Div style={{ display: "flex", flexWrap: "wrap" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>{t("user.profile.contacts.address-section.v/s/a-line")} </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.addressFull ? data?.addressFull : "---"}</Typography>
                            </Div>
                            <Div style={{ display: "flex", flexWrap: "wrap" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>{t("user.profile.contacts.address-section.post-office")}</Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.addressPostOffice ? data?.addressPostOffice : "---"}</Typography>
                            </Div>
                            <Div style={{ display: "flex", flexWrap: "wrap" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>{t("user.profile.contacts.address-section.upazila")} </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.addressThana ? data?.addressThana : "---"}</Typography>
                            </Div>
                            <Div style={{ display: "flex", flexWrap: "wrap" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>{t("user.profile.contacts.address-section.district")} </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.addressDistrict ? data?.addressDistrict : "---"}</Typography>
                            </Div>
                            <Div style={{ display: "flex", flexWrap: "wrap" }}>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary" sx={{ mr: 1 }}>{t("user.profile.contacts.address-section.division")} </Typography>
                                <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.addressDivision ? data?.addressDivision : "---"}</Typography>
                            </Div>
                        </>}
                    />
                </ListItem>

            </List>
        </JumboCardQuick>
    );
};

export default Contacts;
