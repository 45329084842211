import React from 'react';
import { useMutation } from "react-query";
import Div from "@jumbo/shared/Div";
import { TextField } from "@mui/material";
import useChatApp from "../../hooks/useChatApp";
import { chatService } from "../../../../../services/chat-services";
import chatServices from 'app/services/chat.service';
import { useLocation } from 'react-router-dom';
import { USER_REG_ID } from 'app/config/localStorageConfig';
import { getFont } from 'app/hooks/getFont';
import { useTranslation } from 'react-i18next';

const ActiveConversationFooter = ({ sendMessage }) => {
    const { t, i18n } = useTranslation();

    const {
        activeConversation,
        activeConversationRef,
        favoriteConversationsListRef,
        recentConversationsListRef,
        contactConversationsListRef,
    } = useChatApp();

    const location = useLocation();
    const [message, setMessage] = React.useState('');
    const addMessageMutation = useMutation(chatServices.addConversationMessage, {
        onSuccess: () => {
            activeConversationRef?.current?.refresh();
            favoriteConversationsListRef?.current?.refresh();
            recentConversationsListRef?.current?.refresh();
            contactConversationsListRef?.current?.refresh();
        }
    });
    const onSendMessage = (event) => {
        const message = event.target.value.trim();
        if (event.key === 'Enter' && message) {
            // addMessageMutation.mutate({
            //     channelId: location?.state?.channelId ? location?.state?.channelId : null,
            //     channelSubscriber: location?.state?.channelSubscriber_userId ? location?.state?.channelSubscriber_userId : null,
            //     textFrom: localStorage.getItem(USER_REG_ID),
            //     textTo: location?.state?.channelSubscriber_userId ? location?.state?.channelSubscriber_userId : location?.state?.connectedWithUserId,
            //     chatBody: message
            // })
            setMessage('');

            sendMessage(message);
        }
    };
    return (
        <Div
            sx={{
                display: 'flex',
                alignItems: 'center',
                p: theme => theme.spacing(2, 3),
                borderTop: 1,
                borderTopColor: 'divider',
                bgcolor: theme => theme.palette.action.hover,
            }}
        >
            <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                size={'small'}
                inputProps={{
                    style: {
                        fontFamily: getFont(i18n)
                    }
                }}
                InputLabelProps={{
                    style: {
                        fontFamily: getFont(i18n)
                    }
                }}
                placeholder={i18n.language == "bn-BD" ? "বার্তা লিখুন..." : "Type message...."}
                onKeyPress={onSendMessage}
                fullWidth
                sx={{
                    backgroundColor: theme => theme.palette.background.paper
                }}
            />
        </Div>
    );
};

export default ActiveConversationFooter;
