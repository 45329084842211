import React, { useState, useEffect } from 'react';
import { Card, CardContent, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Stack, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import Header from './Header';
import { useJumboTheme } from '@jumbo/hooks';
import ChipsAddRemove from 'app/pages/components/ChipsAddRemove';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import returneeServices from 'app/services/returnee.service';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { USER_ID } from 'app/config/localStorageConfig';
import publicServices from 'app/services/public/public.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';

const MySwal = withReactContent(Swal)
const userId = localStorage.getItem(USER_ID);

const EditProfile = () => {
    const { t, i18n } = useTranslation();
    const { theme } = useJumboTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [returnFrom, setreturnFrom] = useState('');
    const [interestIn, setinterestIn] = useState('');
    const [currentJob, setcurrentJob] = useState('');
    const [skillHave, setskillHave] = useState('');
    const [selectedDivision, setselectedDivision] = useState({});
    const [uploadFile, setuploadFile] = useState(null);


    // const handleFileChange = (event) => {
    //     setuploadFile(event.target.files[0])
    // };

    const { mutate } = useMutation(returneeServices.getReturneeUpdate, {
        onSuccess: (data) => {

            // if (uploadFile) {
            //     const formData = new FormData();
            //     formData.append("file", uploadFile)
            //     try {
            //         returneeServices.uploadReturneeProfilePicture({
            //             id: localStorage.getItem(USER_ID),
            //             data: formData
            //         })
            //     } catch (error) {

            //     }
            // }

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000,
                didClose: () => {
                    if (data?.statusCode == 200) {
                        window.location.assign(`/user/profile`)
                    }
                }
            })

        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            "profileTitle": "",
            "phoneNumber": "",
            "name": "",
            "nameBn": "",
            "email": "",
            "addressDivisionId": 0,
            "addressDistrictId": 0,
            "addressThana": "",
            "addressThanaBn": "",
            "addressPostOffice": "",
            "addressPostOfficeBn": "",
            "addressFull": "",
            "addressFullBn": "",
            "fathersNameBn": "",
            "fathersName": "",
            "mothersName": "",
            "mothersNameBn": "",
            "spouseName": "",
            "spouseNameBn": "",
            "nidNo": "",
            "dob": "",
            "passportNo": "",
            "gender": "",
            "returnFromCountry": "",
            "skillInAbroad": "",
            "areaOfInterest": "",
            "currentProfession": "",
            "isRegByMobile": true
            // "file": ""
        },
        onSubmit: values => {
            console.log(values);
            values.returnFromCountry = returnFrom;
            values.skillInAbroad = skillHave;
            values.areaOfInterest = interestIn;
            values.currentProfession = currentJob;
            values.dob = moment(values.dob).format('YYYY-MM-DD');
            mutate({
                id: localStorage.getItem(USER_ID),
                data: values
            })
        }
    })

    const { data: dataDist } = useQuery(['get-district', selectedDivision], () => publicServices.getDistrict(selectedDivision?.id));
    const { data: dataDiv } = useQuery(['get-division'], () => publicServices.getDivision());

    const { data, isLoading } = useQuery(['get-returnee-profile'], () => returneeServices.getReturneeDetails(userId), {
        enabled: !!userId,
        onSuccess: data => {
            formik.setValues(data?.content);
            data?.content?.returnFromCountry && setreturnFrom(data?.content?.returnFromCountry);
            data?.content?.areaOfInterest && setinterestIn(data?.content?.areaOfInterest);
            data?.content?.skillInAbroad && setskillHave(data?.content?.skillInAbroad);
            data?.content?.currentProfession && setcurrentJob(data?.content?.currentProfession);
        }
    })


    return (
        <JumboContentLayout
            header={<Header data={data?.content} isEdit={true} />}
            layoutOptions={{
                header: {
                    sx: {
                        mt: -4,
                        mb: -7.25,
                        mx: { xs: -4, lg: -6 },
                        p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                        //background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/profile-bg.jpg`,"1920x580")}) no-repeat center`,
                        // backgroundSize: 'cover',
                        backgroundImage: "linear-gradient(to right, #F53803, #F5D020)",
                        color: 'common.white',
                        position: 'relative',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            // backgroundColor: alpha(theme.palette.common.black, .65)
                        }
                    }
                },
                sidebar: {
                    sx: {
                        mr: 3.75,
                        width: { xs: '100%', lg: '33%' },
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0,
                            mr: 0,
                            order: 2
                        }
                    }
                },
                wrapper: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column'
                        }
                    }
                },
                main: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0
                        }
                    }
                }
            }}
        >
            <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto' }}>
                <Card
                    sx={{
                        minWidth: 0,
                        flexDirection: { xs: 'column', md: 'row' },
                        mb: 3
                    }}
                >
                    <CardContent sx={{ flex: 1, p: 4 }}>
                        <Typography fontFamily={getFont(i18n)} fontSize={20} variant={'body1'} color={'inherit'} mt={.5} mb={2}>
                            {t("user.profile.edit-profile.title")}
                        </Typography>
                    </CardContent>
                </Card>

                <form onSubmit={formik.handleSubmit} >
                    <Card
                        sx={{
                            minWidth: 0,
                            flexDirection: { xs: 'column', md: 'row' },
                            mb: 3
                        }}
                    >
                        <CardContent sx={{ flex: 1, p: 4 }}>
                            <Typography fontFamily={getFont(i18n)} fontSize={20} variant={'body1'} color={'inherit'} mt={.5} mb={2}>
                                {t("user.profile.edit-profile.card-1.title-1")}
                            </Typography>
                            <Div
                                sx={{
                                    display: 'flex',
                                    minWidth: 0,
                                    flex: 1,
                                    flexDirection: 'column',
                                    height: '100%'
                                }}
                            >
                                <Div sx={{ mb: 2 }}>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            id="name"
                                            label={`${t("label.name")} ${t("label.lang")}`}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('name')}
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            id="nameBn"
                                            label={`${t("label.name")} ${t("label.langBn")}`}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('nameBn')}
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            disabled={formik.values.isRegByMobile}
                                            id="phone-number"
                                            label={t("label.mobile")}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('phoneNumber')}
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            disabled={!formik.values.isRegByMobile}
                                            id="email-address"
                                            label={t("placeholder.email")}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('email')}
                                        />
                                    </Div>

                                    {/* <Div sx={{ mt: 1, mb: 3 }}>
                                        <Typography fontFamily={getFont(i18n)} variant='body2'>{i18n.language == "bn-BD" ? "প্রোফাইল ছবি" : "Profile Picture"}</Typography>
                                        <TextField
                                            fullWidth
                                            type='file'
                                            id="pp"
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            onChange={(event) => handleFileChange(event)}
                                        />
                                    </Div> */}
                                    <Typography fontFamily={getFont(i18n)} fontSize={15} variant={'body1'} color={'inherit'} mt={.5} mb={2}>
                                        {t("user.profile.edit-profile.card-1.sub")}
                                    </Typography>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} md={6}>
                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    label={`${t("user.profile.contacts.address-section.post-office")} ${t("label.lang")}`}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('addressPostOffice')}
                                                />
                                            </Div>
                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    label={`${t("user.profile.contacts.address-section.upazila")} ${t("label.lang")}`}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('addressThana')}
                                                />
                                            </Div>
                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    select
                                                    component="li"
                                                    fullWidth
                                                    label={t("user.profile.contacts.address-section.division")}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('addressDivisionId')}
                                                >
                                                    <MenuItem value={"0"}>
                                                        <Typography fontFamily={getFont(i18n)}>
                                                            {i18n.language == "bn-BD" ? "বিভাগ নির্বাচন করুন" : "Select division"}
                                                        </Typography>
                                                    </MenuItem>
                                                    {
                                                        dataDiv?.content?.map((item, i) => (
                                                            <MenuItem key={i} onClick={() => setselectedDivision(item)} value={item?.id}>
                                                                <Typography fontFamily={getFont(i18n)}>
                                                                    {i18n.language == "bn-BD" ? item?.NAME_BN : item?.NAME}
                                                                </Typography>
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            </Div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    label={`${t("user.profile.contacts.address-section.post-office")} ${t("label.langBn")}`}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('addressPostOfficeBn')}
                                                />
                                            </Div>
                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    label={`${t("user.profile.contacts.address-section.upazila")} ${t("label.langBn")}`}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('addressThanaBn')}
                                                />
                                            </Div>
                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    select
                                                    component="li"
                                                    fullWidth
                                                    label={t("user.profile.contacts.address-section.district")}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('addressDistrictId')}
                                                >
                                                    <MenuItem value={"0"}>
                                                        <Typography fontFamily={getFont(i18n)}>
                                                            {i18n.language == "bn-BD" ? "জেলা নির্বাচন করুন" : "Select district"}
                                                        </Typography>
                                                    </MenuItem>
                                                    {
                                                        dataDist?.content?.map((item, i) => (
                                                            <MenuItem key={i} value={item?.id}>
                                                                <Typography fontFamily={getFont(i18n)}>
                                                                    {i18n.language == "bn-BD" ? item?.NAME_BN : item?.NAME}
                                                                </Typography>
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            </Div>
                                        </Grid>
                                    </Grid>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            label={`${t("user.profile.contacts.address-section.v/s/a-line")} ${t("label.lang")}`}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('addressFull')}
                                        />
                                    </Div>

                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            label={`${t("user.profile.contacts.address-section.v/s/a-line")} ${t("label.langBn")}`}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('addressFullBn')}
                                        />
                                    </Div>
                                </Div>
                            </Div>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        minWidth: 0,
                        flexDirection: { xs: 'column', md: 'row' },
                        mb: 3
                    }}>
                        <CardContent sx={{ flex: 1, p: 4 }}>
                            <Typography fontFamily={getFont(i18n)} fontSize={20} variant={'body1'} color={'inherit'} mt={.5} mb={2}>
                                {t("user.profile.edit-profile.card-2.title")}
                            </Typography>

                            <Div sx={{ mt: 1, mb: 3 }}>
                                <TextField
                                    fullWidth
                                    label={`${t("user.profile.biography.Father's Name")} ${t("label.lang")}`}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    {...formik.getFieldProps('fathersName')}
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <TextField
                                    fullWidth
                                    label={`${t("user.profile.biography.Father's Name")} ${t("label.langBn")}`}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    {...formik.getFieldProps('fathersNameBn')}
                                />
                            </Div>

                            <Div sx={{ mt: 1, mb: 3 }}>
                                <TextField
                                    fullWidth
                                    label={`${t("user.profile.biography.Mother's Name")} ${t("label.lang")}`}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    {...formik.getFieldProps('mothersName')}
                                />
                            </Div>

                            <Div sx={{ mt: 1, mb: 3 }}>
                                <TextField
                                    fullWidth
                                    label={`${t("user.profile.biography.Mother's Name")} ${t("label.langBn")}`}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    {...formik.getFieldProps('mothersNameBn')}
                                />
                            </Div>

                            <Div sx={{ mt: 1, mb: 3 }}>
                                <TextField
                                    fullWidth
                                    label={`${t("user.profile.biography.Spouse's Name")} ${t("label.lang")}`}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    {...formik.getFieldProps('spouseName')}
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <TextField
                                    fullWidth
                                    label={`${t("user.profile.biography.Spouse's Name")} ${t("label.langBn")}`}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    {...formik.getFieldProps('spouseNameBn')}
                                />
                            </Div>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        minWidth: 0,
                        flexDirection: { xs: 'column', md: 'row' },
                        mb: 3
                    }}>
                        <CardContent sx={{ flex: 1, p: 4 }}>
                            <Typography fontFamily={getFont(i18n)} fontSize={20} variant={'body1'} color={'inherit'} mt={.5} mb={2}>
                                {t("user.profile.edit-profile.card-3.title")}
                            </Typography>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            label={t("user.profile.about.nid")}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('nidNo')}
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                                            <DatePicker
                                                format='dd/MM/yyyy'
                                                fullWidth
                                                label={t("user.profile.about.birthday")}
                                                inputProps={{
                                                    style: {
                                                        fontFamily: getFont(i18n)
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        fontFamily: getFont(i18n)
                                                    }
                                                }}
                                                onChange={(e) => formik.setFieldValue("dob", e)}
                                                value={formik.values.dob ? new Date(formik.values.dob) : new Date()}
                                            // {...formik.getFieldProps('dob')}
                                            />
                                        </LocalizationProvider>
                                    </Div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            label={t("user.profile.about.passport")}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('passportNo')}
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 3 }}>
                                        <TextField
                                            select
                                            fullWidth
                                            label={t("user.profile.about.gender")}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            {...formik.getFieldProps('gender')}
                                        >
                                            <MenuItem value={"Male"}>Male</MenuItem>
                                            <MenuItem value={"Female"}>Female</MenuItem>
                                            <MenuItem value={"Other"}>Other</MenuItem>
                                        </TextField>
                                    </Div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        minWidth: 0,
                        flexDirection: { xs: 'column', md: 'row' },
                        mb: 3
                    }}>
                        <CardContent sx={{ flex: 1, p: 4 }}>
                            <Typography fontFamily={getFont(i18n)} fontSize={20} variant={'body1'} color={'inherit'} mt={.5} mb={2}>
                                {t("user.profile.edit-profile.card-4.title")}
                            </Typography>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <ChipsAddRemove
                                    label={t("user.profile.edit-profile.card-4.p1")}
                                    field={t("user.profile.edit-profile.card-4.p1-comma-sub")}
                                    data={returnFrom}
                                    setData={setreturnFrom}
                                />
                            </Div>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <ChipsAddRemove
                                    label={t("user.profile.edit-profile.card-4.skill-abroad")}
                                    field={t("user.profile.edit-profile.card-4.p2-comma-sub")}
                                    data={skillHave}
                                    setData={setskillHave} />
                            </Div>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <ChipsAddRemove
                                    label={t("user.profile.edit-profile.card-4.interest")}
                                    field={t("user.profile.edit-profile.card-4.p3-comma-sub")}
                                    data={interestIn}
                                    setData={setinterestIn} />
                            </Div>
                            <Div sx={{ mt: 1, mb: 3 }}>
                                <ChipsAddRemove
                                    label={t("user.profile.edit-profile.card-4.cur-prof")}
                                    field={t("user.profile.edit-profile.card-4.p4-comma-sub")}
                                    data={currentJob}
                                    setData={setcurrentJob} />
                            </Div>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        minWidth: 0,
                        flexDirection: { xs: 'column', md: 'row' },
                        mb: 3
                    }}>
                        <CardContent sx={{ flex: 1, p: 4 }}>
                            <Button sx={{ fontFamily: getFont(i18n) }} type='submit' variant="contained">{t("button.save")}</Button>
                        </CardContent>
                    </Card>
                </form>

            </Div>
        </JumboContentLayout>
    );
};

export default EditProfile;
