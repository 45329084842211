import React from 'react';
import Avatar from "@mui/material/Avatar";
import { Button, ButtonGroup, Link, ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { useTranslation } from 'react-i18next';
import { enUS, bnBD } from '@mui/material/locale';
import { LNG } from 'app/config/localStorageConfig';

export const Languages = [
    {
        id: 2,
        locale: "bn-BD",
        muiLocale: bnBD,
        name: "বাংলা",
        avatar: `${ASSET_IMAGES}/flag/icons8-bangladesh-48.png`
    },
    {
        id: 1,
        locale: "en-US",
        muiLocale: enUS,
        name: "English",
        avatar: `${ASSET_IMAGES}/flag/icons8-usa-48.png`
    },
]

const LanguageSwitchDropdown = () => {
    const { i18n } = useTranslation();
    const { theme, setMuiLocale, setTheme } = useJumboTheme();
    const [selectedLanguage, setSelectedLanguage] = React.useState({
        id: 1,
        locale: "en-US",
        muiLocale: enUS,
        name: "English",
        avatar: `${ASSET_IMAGES}/flag/icons8-usa-48.png`
    });

    const lang = JSON.parse(localStorage.getItem(LNG));

    const handleChange = (item) => {
        i18n.changeLanguage(item.locale).then(() => {
            setSelectedLanguage(item);
            setMuiLocale(item.muiLocale);
        });
    };

    React.useEffect(() => {
        i18n.changeLanguage(selectedLanguage.locale).then(() => {
            setMuiLocale(selectedLanguage.muiLocale);
        });
    }, []);

    React.useEffect(() => {
        if (lang) {
            handleChange(lang)
        }
    }, []);

    // return (
    //     <JumboDdPopover
    //         triggerButton={
    //             <Avatar
    //                 src={selectedLanguage?.avatar}
    //                 sizes={"small"}
    //                 sx={{ boxShadow: 25, cursor: 'pointer' }}
    //             />
    //         }
    //     >
    //         <nav>
    //             <List disablePadding sx={{ pb: 1 }}>
    //                 {
    //                     Languages?.map((item) => (
    //                         <ListItemButton onClick={() => handleChange(item)} key={item?.id} >
    //                             <ListItemIcon sx={{ minWidth: 36 }}>
    //                                 <Avatar
    //                                     src={item?.avatar}
    //                                     sx={{ width: 24, height: 24, cursor: 'pointer' }}
    //                                 />
    //                             </ListItemIcon>
    //                             <ListItemText primary={item?.name} sx={{ my: 0 }} />
    //                         </ListItemButton>
    //                     ))
    //                 }
    //             </List>
    //         </nav>
    //     </JumboDdPopover>
    // );

    return (
        <ButtonGroup size='small' >
            <Button variant={selectedLanguage.id === 2 ? "contained" : "outlined"} color="warning" onClick={() => {
                handleChange(Languages[0]);
                localStorage.setItem(LNG, JSON.stringify(Languages[0]));
            }} sx={{ fontFamily: "'Baloo Da 2', cursive" }} >বাংলা</Button>
            <Button variant={selectedLanguage.id === 1 ? "contained" : "outlined"} color="warning" onClick={() => {
                handleChange(Languages[1]);
                localStorage.setItem(LNG, JSON.stringify(Languages[1]));
            }} >English</Button>
        </ButtonGroup>
    )
};

export default LanguageSwitchDropdown;
