import React from 'react';
import Avatar from "@mui/material/Avatar";
import { authUser } from "./fake-db";
import { Link, ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { Business, ChatOutlined, EmailOutlined, PieChartOutline, WorkOutline, InsertDriveFileOutlined } from '@mui/icons-material';
import { AUTHORITY, NAME, PROFILE_IMAGE_URL, USERNAME, USER_ID } from 'app/config/localStorageConfig';
import authServices from 'app/services/auth/auth.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import { useQuery } from 'react-query';
import returneeServices from 'app/services/returnee.service';

const url = localStorage.getItem(PROFILE_IMAGE_URL);
const role = localStorage.getItem(AUTHORITY);

const AuthUserDropdown = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { setAuthToken } = useJumboAuth();

    const [userRole, setuserRole] = React.useState("");

    // const { data } = useQuery(["get-profile-image", url], () => returneeServices.getReturneeProfilePicture(url), {
    //     enabled: (!!url && role != "ADMIN")
    // })

    React.useEffect(() => {
        const role = localStorage.getItem(AUTHORITY);
        setuserRole(role);
    }, []);

    const onLogout = async () => {
        try {
            const response = await authServices.signOut();
            if (response?.statusCode) {
                localStorage.clear();
                window.location.assign("/");
                setAuthToken(null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={url ? url : ""}
                        sizes={"small"}
                        sx={{ boxShadow: 25, cursor: 'pointer' }}
                    />
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={url ? url : ""} alt={authUser.name} sx={{ width: 60, height: 60, mb: 2 }} />
                    <Typography variant={"h5"}>{localStorage.getItem(NAME)}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{localStorage.getItem(USERNAME)}</Typography>
                </Div>
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        {
                            (userRole == "PROVIDER") && (
                                <>
                                    <ListItemButton >
                                        <Link sx={{ display: 'flex' }} to={`/service-provider/profile`} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <Business />
                                            </ListItemIcon>
                                            <ListItemText primary={t("sidebar.menuItem.profile")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                        </Link>
                                    </ListItemButton>
                                    <ListItemButton >
                                        <Link sx={{ display: 'flex' }} to={`/service-provider/my-account`} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <PersonOutlineIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={t("sidebar.menuItem.account")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                        </Link>
                                    </ListItemButton>
                                    <ListItemButton >
                                        <Link sx={{ display: 'flex' }} to={`/service-provider/service`} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <WorkOutline />
                                            </ListItemIcon>
                                            <ListItemText primary={t("sidebar.menuItem.service")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                        </Link>
                                    </ListItemButton>
                                    <ListItemButton >
                                        <Link sx={{ display: 'flex' }} to={`/service-provider/application-form`} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <InsertDriveFileOutlined />
                                            </ListItemIcon>
                                            <ListItemText primary={t("sidebar.menuItem.application-form")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                        </Link>
                                    </ListItemButton>
                                </>
                            )
                        }
                        {
                            userRole == "RETURNEE_USER" && (
                                <>
                                    <ListItemButton >
                                        <Link sx={{ display: 'flex' }} to={"/user/profile"} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <PersonOutlineIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={t("sidebar.menuItem.profile")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                        </Link>
                                    </ListItemButton>
                                    <ListItemButton>
                                        <Link sx={{ display: 'flex' }} to={"/user/edit-profile"} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <EditOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={t("user.profile.edit-profile.title")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                        </Link>
                                    </ListItemButton>
                                    <ListItemButton>
                                        <Link sx={{ display: 'flex' }} to={"/app/chats"} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <ChatOutlined />
                                            </ListItemIcon>
                                            <ListItemText primary={t("sidebar.menuItem.chat")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                        </Link>
                                    </ListItemButton>
                                </>
                            )
                        }

                        {
                            userRole == "ADMIN" && (
                                <>
                                    <ListItemButton>
                                        <Link sx={{ display: 'flex' }} to={"/admin/dashboard"} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <PieChartOutline />
                                            </ListItemIcon>
                                            <ListItemText primary={t("sidebar.menuItem.dashboard")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                        </Link>
                                    </ListItemButton>
                                </>
                            )
                        }

                        {
                            userRole != "ADMIN" && (<ListItemButton>
                                <Link sx={{ display: 'flex' }} to={"app/inbox"} color={"inherit"} component={RouterLink} underline={"none"}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <EmailOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={t("sidebar.menuItem.mail")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                                </Link>
                            </ListItemButton>)
                        }
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("sidebar.menuItem.logout")} sx={{ my: 0 }} primaryTypographyProps={{ fontFamily: getFont(i18n) }} />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
