import React, { Suspense, useState, useEffect } from 'react';
import { Button, Divider, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SidebarExpandIcon, SidebarShrinkIcon } from 'app/utils/icon/icon';
import menusProvider from './menusProvider';
import menusAdmin from './menusAdmin';
import { AUTHORITY } from 'app/config/localStorageConfig';
import { useTranslation } from 'react-i18next';

const MenuItemList = ({ items }) => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <nav aria-label="main mailbox folders">
            <List>
                {
                    items?.map((elm, i) => (
                        <>
                            <ListItem key={i} disablePadding>
                                <ListItemButton onClick={() => navigate(elm?.uri)}>
                                    <ListItemIcon>
                                        {elm?.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={t(elm?.label)} />
                                </ListItemButton>
                            </ListItem>
                            <Divider variant="middle" component="li" />
                        </>
                    ))
                }

            </List>
        </nav>
    )
}

const MobileMenu = () => {

    const [userRole, setuserRole] = useState(0);

    useEffect(() => {
        const role = localStorage.getItem(AUTHORITY);
        setuserRole(role);
    }, [userRole]);

    return (
        <Div>
            <MenuItemList items={userRole == "PROVIDER" ? menusProvider : userRole == "ADMIN" ? menusAdmin : menus} />
        </Div>
    );
};

export default MobileMenu;
