import React from 'react';
import ActiveConversationChat from "./ActiveConversationChat";
import useChatApp from "../../../hooks/useChatApp";
import SentMessageContent from './SentMessageContent';
import ReceivedMessageContent from './ReceivedMessageContent';
import { USER_ID, USER_REG_ID } from 'app/config/localStorageConfig';

const chatGroupedByDate = (array, key) =>
    Object.entries(
        array.reduce((result, { [key]: k, ...rest }) => {
            (result[k] = result[k] || []).push(rest);
            return result;
        }, {})
    ).map(([sent_date, messages]) => ({
        sent_date,
        messages
    }));


const ConversationChatGroupByDate = ({ conversationQuery }) => {
    // const { activeConversation } = useChatApp();
    // const conversationMessages = React.useMemo(() => {
    //     if (activeConversation)
    //         return chatGroupedByDate(activeConversation?.messages, 'sent_date');

    //     return [];
    // }, [activeConversation]);
    return (
        <React.Fragment>
            {
                conversationQuery?.map((message, index) => (
                    // <ActiveConversationChat key={index} conversation={messagesGroupByDate} />

                    <React.Fragment key={index}>
                        {
                            (message?.from == localStorage.getItem(USER_REG_ID)) ? (
                                <SentMessageContent message={message?.text} sent_at={message?.time} />
                            ) : (
                                <ReceivedMessageContent message={message?.text} sent_at={message?.time} />
                            )
                        }
                    </React.Fragment>
                ))
            }
        </React.Fragment>
    );
};

export default ConversationChatGroupByDate;
