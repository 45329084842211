import Div from "@jumbo/shared/Div";
import {
  Button,
  Divider,
  Grow,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProviderDetails from "./components/ProviderDetails";
import SimilarService from "./components/SimilarService";
import ServiceLocation from "./components/ServiceLocation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import serviceServices from "app/services/service.service";
import { ACCESS_TOKEN, LNG } from "app/config/localStorageConfig";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import { getFont } from "app/hooks/getFont";
import publicServices from "app/services/public/public.service";
import { Languages } from "app/shared/LanguageSwitch/LanguageSwitchDropdown";

const MySwal = withReactContent(Swal);

const hasToken = localStorage.getItem(ACCESS_TOKEN);

const ServiceDetails = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const language = JSON.parse(localStorage.getItem(LNG));

  const [expanded, setExpanded] = React.useState("panel0");
  const [showData, setshowData] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { data, isLoading } = useQuery(
    ["get-service-deatils", params?.id],
    () => serviceServices.getOneService(params?.id),
    {
      enabled: !!params?.id && showData,
    }
  );

  // const { data: dataLogo, isLoading: isLoadingLogo } = useQuery(["get-provider-logo-image-details", data?.content?.providerInfoLogoImageUrl], () => publicServices.getImage(data?.content?.providerInfoLogoImageUrl), {
  //     enabled: !!data?.content?.providerInfoLogoImageUrl
  // });

  // const { data: dataBanner, isLoading: isLoadingBanner } = useQuery(['get-banner', data?.content?.imageUrl], () => serviceServices.getBannerImage(data?.content?.imageUrl), {
  //     enabled: !!data?.content?.imageUrl
  // });

  React.useEffect(() => {
    window.scrollTo(0, 0);

    // if (hasToken) {
    //     setshowData(true)
    // } else {
    //     MySwal.fire({
    //         icon: 'warning',
    //         title: <strong>{"You have to log in to your account to view service details."}</strong>,
    //         showCancelButton: true,
    //         confirmButtonText: 'Login',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             navigate(`/auth/login`);
    //         } else if (result.isDismissed) {
    //             navigate(`/service-list`);
    //         }
    //     })
    // }
  }, [data]);

  React.useEffect(() => {
    if (language?.id) {
      if (
        data?.content?.serviceLanguage &&
        data?.content?.serviceLanguage != 3 &&
        data?.content?.serviceLanguage != language?.id
      ) {
        MySwal.fire({
          icon: "warning",
          title: (
            <strong>
              {language?.id == 1
                ? "This service is not available in the English language."
                : "এই সেবাটি বাংলা ভাষায় পাওয়া যায়নি।"}
            </strong>
          ),
        });
        if (data?.content?.serviceLanguage == 1) {
          localStorage.setItem(LNG, JSON.stringify(Languages[1]));
        }
        if (data?.content?.serviceLanguage == 2) {
          localStorage.setItem(LNG, JSON.stringify(Languages[0]));
        }
      }
    }
  }, [language, data]);

  return (
    showData && (
      <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
        {isLoading ? (
          <Div
            sx={{
              mx: location.pathname.includes("/service-provider") ? "" : "8vw",
              background: "#FFFFFF",
            }}
          >
            <Div>
              <Skeleton variant="rounded" sx={{ width: "100%" }} height={250} />
            </Div>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Skeleton
                variant="text"
                width={400}
                sx={{ fontSize: "1.5rem" }}
              />
            </Divider>
            <Div
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <Div sx={{ width: isMobile ? "100%" : "68.5%" }}>
                {Array(3)
                  .fill(null)
                  .map((_, i) => (
                    <Skeleton
                      key={i}
                      variant="rounded"
                      sx={{ mb: 2, width: "100%" }}
                      height={250}
                    />
                  ))}
              </Div>
              <Div
                sx={{
                  width: isMobile ? "100%" : "30%",
                  marginTop: isMobile ? "20px" : "0",
                }}
              >
                <Skeleton variant="rounded" width={317} height={635} />
              </Div>
            </Div>
          </Div>
        ) : (
          <Div
            sx={{
              mx: location.pathname.includes("/service-provider") ? "" : "8vw",
              background: "#FFFFFF",
            }}
          >
            <Div>
              <img
                src={
                  data?.content?.imageServer
                    ? data?.content?.imageServer
                    : data?.content?.providerInfoImageServer
                    ? data?.content?.providerInfoImageServer
                    : `${window.location.origin}/images/banner/noBanner.png`
                }
                width={"100%"}
                style={{ borderRadius: "6px" }}
              />
            </Div>
            {isMobile ? (
              <Typography
                sx={{ my: 2 }}
                fontFamily={getFont(i18n)}
                variant="h3"
                whiteSpace={"pre-wrap"}
              >
                {i18n.language == "bn-BD"
                  ? data?.content?.service_name_bn
                  : data?.content?.service_name}
              </Typography>
            ) : (
              <Divider textAlign="left" sx={{ my: 2 }}>
                <Typography fontFamily={getFont(i18n)} variant="h3">
                  {i18n.language == "bn-BD"
                    ? data?.content?.service_name_bn
                    : data?.content?.service_name}
                </Typography>
              </Divider>
            )}

            <Div
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <Div sx={{ width: isMobile ? "100%" : "68.5%" }}>
                {data?.content?.serviceProviderServicesDetails?.map(
                  (item, i) => (
                    <Accordion
                      expanded={
                        data?.content?.isServicesDetailsCollapsible == 0
                          ? true
                          : expanded === `panel${i}`
                      }
                      onChange={handleChange(`panel${i}`)}
                      key={i}
                    >
                      <AccordionSummary
                        expandIcon={
                          data?.content?.isServicesDetailsCollapsible == 1 && (
                            <ExpandMoreIcon />
                          )
                        }
                        aria-controls={`panel1bh-content${i}`}
                        id={`panel1bh-header${i}`}
                      >
                        <Typography fontFamily={getFont(i18n)} variant="h3">
                          {i18n.language == "bn-BD"
                            ? item?.sectionHeaderTitleBn
                            : item?.sectionHeaderTitle}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          style={{ fontFamily: getFont(i18n) }}
                          dangerouslySetInnerHTML={{
                            __html:
                              i18n.language == "bn-BD"
                                ? item?.sectionBodyBn
                                : item?.sectionBody,
                          }}
                        ></div>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}

                {data?.content?.locations[0]?.detailsAddress && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography fontFamily={getFont(i18n)} variant="h3">
                        {t("placeholder.edit-sp-profile.location")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {data?.content?.locations?.map((item, i) => (
                        <Typography key={i} color={"#999999"}>
                          <ServiceLocation data={item} />
                          <Divider />
                        </Typography>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}

                {data?.content?.applicationOption == 2 &&
                  data?.content?.serviceTemplateId && (
                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                        border: "1px solid #a7a7a7",
                        borderRadius: "6px",
                        marginTop: "20px",
                      }}
                    >
                      {data?.content?.applicationSubmissionExpireMessage ? (
                        <Typography textAlign={"center"} variant="body2">
                          {data?.content?.applicationSubmissionExpireMessage}
                        </Typography>
                      ) : (
                        <Stack justifyContent={"center"} alignItems={"center"}>
                          <Typography textAlign={"center"} variant="body2">
                            {data?.content?.templateDescription}
                          </Typography>
                          <Typography textAlign={"center"} variant="body2">
                            {`Application Start date:${data?.content?.startDateApplication} End date: ${data?.content?.endDateApplication}`}
                          </Typography>
                          <Button
                            onClick={() => {
                              if (hasToken) {
                                navigate(
                                  `/application-form/${data?.content?.serviceTemplateId}/${params?.id}`
                                );
                              } else {
                                MySwal.fire({
                                  icon: "warning",
                                  title: (
                                    <strong>
                                      {
                                        "You have to login to your account to continue."
                                      }
                                    </strong>
                                  ),
                                  showCancelButton: true,
                                  confirmButtonText: "Login",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    navigate(`/auth/login`);
                                  }
                                });
                              }
                            }}
                            variant="contained"
                            size="large"
                            sx={{ mt: 2 }}
                          >
                            {data?.content?.templateButtonName}
                          </Button>
                        </Stack>
                      )}
                    </Div>
                  )}

                {data?.content?.applicationOption == 3 && (
                  <Div
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                      border: "1px solid #a7a7a7",
                      borderRadius: "6px",
                      marginTop: "20px",
                    }}
                  >
                    {data?.content?.applicationSubmissionExpireMessage ? (
                      <Typography textAlign={"center"} variant="body2">
                        {data?.content?.applicationSubmissionExpireMessage}
                      </Typography>
                    ) : (
                      <Stack justifyContent={"center"} alignItems={"center"}>
                        <Typography textAlign={"center"} variant="body2">
                          {`Application Start date:${data?.content?.startDateApplication} End date: ${data?.content?.endDateApplication}`}
                        </Typography>
                        <Button
                          onClick={() => {
                            if (hasToken) {
                              window.open(
                                data?.content?.applicationUrl,
                                "_blank"
                              );
                            } else {
                              MySwal.fire({
                                icon: "warning",
                                title: (
                                  <strong>
                                    {
                                      "You have to login to your account to continue."
                                    }
                                  </strong>
                                ),
                                showCancelButton: true,
                                confirmButtonText: "Login",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  navigate(`/auth/login`);
                                }
                              });
                            }
                          }}
                          variant="contained"
                          size="large"
                          sx={{ mt: 2 }}
                        >
                          {data?.content?.applicationUrlButtonName}
                        </Button>
                      </Stack>
                    )}
                  </Div>
                )}
              </Div>
              <Div
                sx={{
                  width: isMobile ? "100%" : "30%",
                  marginTop: isMobile ? "20px" : "0",
                }}
              >
                <ProviderDetails
                  id={data?.content?.profile_id}
                  name={
                    i18n.language == "bn-BD"
                      ? data?.content?.service_provider_info_name_bn
                      : data?.content?.service_provider_info_name
                  }
                  address={
                    i18n.language == "bn-BD"
                      ? data?.content?.profile_address_bn
                      : data?.content?.profile_address
                  }
                  email={data?.content?.profile_email}
                  website={data?.content?.profile_website}
                  mapUrl={data?.content?.profile_googleMapUrl}
                  mobile={data?.content?.profile_mobile_numbers}
                  image={
                    data?.content?.providerInfoLogoImageServer
                      ? data?.content?.providerInfoLogoImageServer
                      : `${window.location.origin}/images/logo/noLogo.png`
                  }
                />
                {data?.content?.relatedServices?.length != 0 ? (
                  <SimilarService data={data?.content?.relatedServices} />
                ) : (
                  ""
                )}
              </Div>
            </Div>
          </Div>
        )}
      </Grow>
    )
  );
};

export default ServiceDetails;
