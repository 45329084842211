import React from 'react';
import Div from "@jumbo/shared/Div";
import { Box, Card, CardContent, Typography, useMediaQuery, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { useNavigate, Link as RouterLink, useParams } from "react-router-dom";
import { BigArrow } from 'app/utils/icon/icon';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from "yup";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import authServices from 'app/services/auth/auth.service';
import providerServices from 'app/services/provider/provider.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const MySwal = withReactContent(Swal)

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
    phoneNumber: yup
        .string()
        .min(11, "Mobile number must be at least 11 characters")
        .max(11, "Mobile number must be at least 11 characters")
        .matches(phoneRegExp, 'Mobile number is not valid')
        .required('Mobile number is required'),
    emailAddress: yup
        .string('Enter your email')
        .email("Email address is invalid")
        .required('Email is required'),
    serviceProviderInfoId: yup
        .string()
        .required('Organisation is required'),
    name: yup
        .string()
        .required('Name is required'),
    designation: yup
        .string()
        .required('Designation is required'),
});

const SignupProvider = () => {
    const isMobile = useMediaQuery('(max-width:575px)');

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { t, i18n } = useTranslation();

    const { mutate } = useMutation(authServices.createServiceProvider, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                navigate("/auth/provider/login");
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const { data: providerList } = useQuery(['get-provider-list'], () => providerServices.getProviderList())

    const formik = useFormik({
        initialValues: {
            name: "",
            designation: "",
            phoneNumber: "",
            emailAddress: "",
            serviceProviderInfoId: 0
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log(values);
            mutate(values)
        }
    })

    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: !isMobile && 4
        }}>

            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#42464F`
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography fontFamily={getFont(i18n)} variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>
                                {`${t("home.body.title.service-provider")} ${t("signup.col1.title")}`}
                            </Typography>
                            <Typography fontFamily={getFont(i18n)} variant={"body1"} mb={2}>
                                {t("signup.col1.p1")}
                            </Typography>
                            <Typography fontFamily={getFont(i18n)} variant={"body1"}>
                                {t("signup.col1.p2")}
                                <Link
                                    to={"/auth/provider/login"}
                                    color={"#FBAD1D"}
                                    underline={"none"}
                                    component={RouterLink}
                                    style={{ fontFamily: getFont(i18n) }}
                                > {t("login.col1.title")}</Link>
                            </Typography>
                        </Div>

                        <Div sx={{ mt: 'auto', position: "relative" }}>
                            <Div style={{ transform: "rotate(180deg)", paddingLeft: "130px" }}>
                                <BigArrow height={207} width={167} fill={"white"} />
                            </Div>
                            <Link to="/" component={RouterLink} underline="none" style={{ position: "absolute", top: "95px", left: "15px" }}>
                                <img src={`${ASSET_IMAGES}/logo.png`} height={25} alt="Jumbo React" />
                            </Link>
                        </Div>

                    </Div>
                </CardContent>
                <CardContent
                    sx={{
                        flex: 1,
                        p: 4
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Div sx={{ mt: 1, mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label={t("label.name")}
                                    placeholder={i18n.language == "bn-BD" ? "আপনার নাম লিখুন" : "Enter your name"}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    error={formik.touched.name && formik.errors.name ? true : false}
                                    helperText={formik.errors.name}
                                    {...formik.getFieldProps('name')}
                                />
                            </Div>
                            <Div sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label={i18n.language == "bn-BD" ? "পদবী" : "Designation"}
                                    placeholder={i18n.language == "bn-BD" ? "আপনার পদবী লিখুন" : "Enter your designation"}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    error={formik.touched.designation && formik.errors.designation ? true : false}
                                    helperText={formik.errors.designation}
                                    {...formik.getFieldProps('designation')}
                                />
                            </Div>
                            <Div sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label={t("label.mobile")}
                                    placeholder={t("placeholder.mobile")}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                                    helperText={formik.errors.phoneNumber}
                                    {...formik.getFieldProps('phoneNumber')}
                                />
                            </Div>
                            <Div sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label={t("placeholder.email")}
                                    placeholder={i18n.language == "bn-BD" ? "আপনার অফিসিয়াল ইমেইল লিখুন" : "Enter your official email address"}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    error={formik.touched.emailAddress && formik.errors.emailAddress ? true : false}
                                    helperText={formik.errors.emailAddress}
                                    {...formik.getFieldProps('emailAddress')}
                                />
                            </Div>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="organisation-name">
                                    {i18n.language == "bn-BD" ? "প্রতিষ্ঠানের নাম" : "Organisation Name"}
                                </InputLabel>
                                <Select
                                    labelId="organisation-name"
                                    id="organisation-name-select"
                                    label={i18n.language == "bn-BD" ? "প্রতিষ্ঠানের নাম" : "Organisation Name"}
                                    inputProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: getFont(i18n)
                                        }
                                    }}
                                    error={formik.touched.serviceProviderInfoId && formik.errors.serviceProviderInfoId ? true : false}
                                    helperText={formik.errors.serviceProviderInfoId}
                                    {...formik.getFieldProps('serviceProviderInfoId')}
                                >
                                    <MenuItem value={0} disabled>{i18n.language == "bn-BD" ? "প্রতিষ্ঠান নির্বাচন করুন" : "Select organisation name"}</MenuItem>
                                    {
                                        providerList?.content?.map((item) => (
                                            <MenuItem key={item?.providerInfoId} value={item?.providerInfoId}>{item?.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mb: 3, fontFamily: getFont(i18n) }}
                                disabled={!formik.isValid || Object.keys(formik.touched).length == 0}
                            >
                                {i18n.language == "bn-BD" ? "রিকোয়েস্ট পাঠান" : "Send Request"}
                            </LoadingButton>
                        </form>
                    </Box>
                </CardContent>
            </Card>

        </Div>
    );
};

export default SignupProvider;
