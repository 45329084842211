import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import Div from '@jumbo/shared/Div/Div';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Button, CircularProgress, FormControl, Grid, InputLabel, List, ListItem, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import React, { useState, useEffect } from 'react';
import FormComponent from './components/FormComponent';
import { arrayMoveImmutable } from 'array-move';
import { FieldType } from './components/FieldType';
import AbcIcon from '@mui/icons-material/Abc';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListIcon from '@mui/icons-material/List';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import applicationServices from 'app/services/application.service';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { AttachFile } from '@mui/icons-material';

const MySwal = withReactContent(Swal)

const NewApplicationForm = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [fieldList, setfieldList] = useState([
    ]);
    const [scrollTop, setScrollTop] = useState(0);

    console.log({ fieldList });

    const { data, isLoading } = useQuery(['get-application-form', params?.id], () => applicationServices.getOneApplication(params?.id), {
        enabled: !!params?.id,
        onSuccess: data => {
            formik.setValues(data?.content)
            setfieldList(JSON.parse(data?.content?.templateBody))
        }
    })

    const queryClient = useQueryClient();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(applicationServices.createApplication, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
            setTimeout(() => {
                if (data?.statusCode == 200) {
                    window.location.assign(`/service-provider/application-form`)
                }
            }, 3000);
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(applicationServices.updateApplication, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
            setTimeout(() => {
                if (data?.statusCode == 200) {
                    window.location.assign(`/service-provider/application-form`)
                }
            }, 3000);
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const addField = (item) => {
        // setfieldList(current => [
        //     ...current.slice(0, index),
        //     {
        //         name: "",
        //         fieldLabel: item?.title,
        //         placeHolder: "",
        //         isRequired: false,
        //         type: item?.type,
        //         option: []
        //     },
        //     ...current.slice(index),
        // ]);
        setfieldList(current => [
            ...current,
            {
                name: "",
                fieldLabel: item?.title,
                placeHolder: "",
                isRequired: false,
                fieldType: item?.fieldType,
                inputType: item?.inputType,
                options: [{ label: 'Option 1', value: '1' }]
            },
        ]);
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        console.log({ newIndex });
        setfieldList(arrayMoveImmutable(fieldList, oldIndex, newIndex));
    };

    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    const formik = useFormik({
        initialValues: {
            templateName: "",
            templateBody: "",
            description: "",
            buttonName: "",
            isEditable: 1
        },
        onSubmit: values => {
            const newVal = fieldList.map((obj, i) => ({ ...obj, id: i + 1 }));
            values.templateBody = JSON.stringify(newVal);
            if (params?.id) {
                formik.values.isEditable = 1
                mutateUpdate({
                    id: params?.id,
                    data: values
                });
            } else {
                mutate(values);
            }
        }
    })

    return (
        <Div>
            {
                ((params?.id && isLoading) || isLoadingSubmit || isLoadingUpdate) ? (
                    <Div sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "100%",
                        minHeight: "20vh"
                    }}>
                        <CircularProgress color={"warning"} sx={{ m: '-40px auto 0' }} />
                    </Div>
                ) : (
                    <form onSubmit={formik.handleSubmit} >
                        <JumboContentLayout
                            header={
                                <Grid container style={{
                                    position: "fixed",
                                    zIndex: 1000,
                                    width: "80%",
                                    background: "white",
                                    marginLeft: "-50px",
                                    padding: "30px 50px 0px",
                                    marginTop: "-33px"
                                }}>
                                    <Grid item sm={12} md={6}>
                                        <PageHeader
                                            title={"Add New Form"}
                                            noMargin={true} />
                                    </Grid>
                                    <Grid item sm={12} md={6} >
                                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                                            <Button onClick={() => navigate("/service-provider/application-form")} variant="outlined" color='error' >
                                                Cancel
                                            </Button>
                                            <Button type='submit' variant="contained" disabled={!formik.isValid || Object.keys(formik.touched).length == 0} >
                                                {params?.id ? "Update" : "Save"}
                                            </Button>
                                        </Stack>

                                    </Grid>
                                </Grid>
                            }
                        >
                            <Grid container spacing={4} mt={6}>
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        label="Form Name"
                                        required
                                        {...formik.getFieldProps('templateName')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        label="Button Name"
                                        required
                                        {...formik.getFieldProps('buttonName')}
                                    />
                                </Grid>
                            </Grid>

                            <TextField
                                sx={{ mb: 3 }}
                                multiline
                                rows={4}
                                label="Description"
                                required
                                {...formik.getFieldProps('description')}
                            />

                            <Typography variant='h5' sx={{ mb: 3 }}>
                                Dynamic Form Field
                            </Typography>

                            <Grid container>
                                <Grid item xs={8} spacing={4} >
                                    <FormComponent
                                        fieldList={fieldList}
                                        setfieldList={setfieldList}
                                        onSortEnd={onSortEnd}
                                        useDragHandle={true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <div className='stickyFormSide' >
                                        <List style={{ background: "white", paddingTop: 0, position: "static", top: "90px" }} sx={{ ml: 3 }}>
                                            <ListItem>
                                                <FieldType
                                                    title={"Text Input"}
                                                    fieldType={"text"}
                                                    inputType={"text"}
                                                    icon={<AbcIcon />}
                                                    addField={addField}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <FieldType
                                                    title={"Number Input"}
                                                    fieldType={"number"}
                                                    inputType={"number"}
                                                    icon={<NumbersIcon />}
                                                    addField={addField}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <FieldType
                                                    title={"File Input"}
                                                    fieldType={"file"}
                                                    inputType={"file"}
                                                    icon={<AttachFile />}
                                                    addField={addField}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <FieldType
                                                    title={"Text Area"}
                                                    fieldType={"textarea"}
                                                    inputType={"text"}
                                                    icon={<ArticleOutlinedIcon />}
                                                    addField={addField}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <FieldType
                                                    title={"Date Input"}
                                                    fieldType={"date"}
                                                    inputType={"date"}
                                                    icon={<CalendarMonthIcon />}
                                                    addField={addField}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <FieldType
                                                    title={"Select"}
                                                    fieldType={"select"}
                                                    icon={<ListIcon />}
                                                    addField={addField}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <FieldType
                                                    title={"Checkbox"}
                                                    fieldType={"checkbox"}
                                                    icon={<CheckBoxIcon />}
                                                    addField={addField}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <FieldType
                                                    title={"Radio Button"}
                                                    fieldType={"radio"}
                                                    icon={<RadioButtonCheckedIcon />}
                                                    addField={addField}
                                                />
                                            </ListItem>
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>

                        </JumboContentLayout>
                    </form>
                )}

        </Div>
    )
}

export default NewApplicationForm;