import { Table, TableContainer, Typography, TableHead, TableRow, TableCell, TableBody, Paper, Stack, Button, Modal, Box, FormControl, TextField, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import providerServices from 'app/services/provider/provider.service';
import { AddCircleOutline, Delete, Edit, People } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Div from '@jumbo/shared/Div/Div';
import JumboSearch from '@jumbo/components/JumboSearch/JumboSearch';
import { useFormik } from 'formik';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import blogServices from 'app/services/blog.service';
import moment from 'moment';

const MySwal = withReactContent(Swal)

const Blog = () => {

    const navigate = useNavigate();
    const [list, setlist] = useState([]);
    const [searchText, setsearchText] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { data } = useQuery(['get-blog-list-admin'], () => blogServices.getBlogList(), {
        onSuccess: data => {
            setlist(data?.content)
        }
    })

    const SearchAnything = (value) => {
        setsearchText(value);
        try {
            const result = data?.content?.filter((obj) =>
                JSON.stringify(obj)?.toLowerCase()?.includes(value.toLowerCase())
            )
            setlist(result);
        } catch (error) {

        }
    }


    return (
        <div>
            <Stack sx={{ mb: 2 }} direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                <Typography variant={'h2'} mb={3}>News and Blog</Typography>
                <Div>
                    <Button variant="contained" startIcon={<AddCircleOutline />} onClick={() => navigate(`/admin/blog/new`)} >
                        Add New Post
                    </Button>
                </Div>
            </Stack>
            <TableContainer component={Paper}>
                <Stack justifyContent={"space-between"} alignItems={"center"}>
                    <Div>

                    </Div>
                    <JumboSearch
                        sx={{ ml: "auto", my: 1, mr: 1, width: "25%" }}
                        onChange={SearchAnything}
                    />
                </Stack>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>id#</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Publish Data</TableCell>
                            <TableCell>Published</TableCell>
                            <TableCell align='center'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.articleId}
                                </TableCell>
                                <TableCell >{row.articleTitle}</TableCell>
                                <TableCell >{moment(row.articlePublishDate).format("DD MMMM, YYYY")}</TableCell>
                                <TableCell >{row.isArticlePublished == 1 ? "Yes" : "No"}</TableCell>
                                <TableCell align='center'>
                                    <Button
                                        startIcon={<Edit />}
                                        sx={{ mx: 2 }}
                                        onClick={() => {
                                            navigate(`/admin/blog/update/${row.articleId}`);
                                        }}>
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Blog;