import Div from '@jumbo/shared/Div';
import { Button, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NewsCard from './NewsCard';
import SectionTitle from './SectionTitle';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import blogServices from 'app/services/blog.service';
import { useQuery } from 'react-query';

const NewsSection = () => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { data, isLoading } = useQuery(['get-blog-list-home'], () => blogServices.getBlogListPublic())

    return (
        <Div sx={{ mb: 10 }}>
            <SectionTitle title={t("home.body.title.news-and-blog")} />
            <Grid container alignItems={"stretch"} spacing={{ xs: 2, md: 2 }} columns={{ sm: 4, md: 8, lg: 8, xl: 12 }}>

                <Grid item xs={2} sm={4} md={4}>
                    <NewsCard
                        data={data?.content?.[0]}
                        isLoading={isLoading}
                    />
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                    <NewsCard
                        data={data?.content?.[1]}
                        isLoading={isLoading}
                    />
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                    <Div className='fbPage'>
                        <iframe
                            src={"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbangladeshmrc%2F&tabs=timeline&width=320&height=464&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"}
                            width="320"
                            height="464"
                            style={{ border: "none", overflow: "hidden" }}
                            scrolling="no"
                            frameBorder="0"
                            allowFullScreen={true}
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </Div>
                </Grid>


            </Grid>

            <Button onClick={() => navigate("/news-and-events")} size={"medium"} variant="contained" sx={{ display: "flex", mx: "auto", mt: 3, borderRadius: "20px", fontFamily: getFont(i18n) }}>
                {t("button.load-more-posts")}
            </Button>
        </Div >
    )
}

export default NewsSection;