import React from 'react';
import Div from "@jumbo/shared/Div";
import { Box, Card, CardContent, Step, Stepper, StepLabel, Typography, useMediaQuery } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { useNavigate, Link as RouterLink, useParams } from "react-router-dom";
import { BigArrow } from 'app/utils/icon/icon';
import { SignupForm } from './components/SignupForm';
import { NumberVerification } from './components/NumberVerification';
import { OTPVerification } from './components/OTPVerification';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const steps = ['signup.col2.1-text', 'signup.col2.2-text', 'signup.col2.3-text'];

const Signup = () => {

    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery('(max-width:575px)');
    const params = useParams();

    const [activeStep, setActiveStep] = React.useState(0);
    const [responseData, setResponseData] = React.useState({});
    const [hasMobile, sethasMobile] = React.useState(true);

    React.useEffect(() => {
        if (params?.step === "step-3" && responseData?.otpSecretKey) {
            setActiveStep(2);
        } else if (params?.step === "step-2" && responseData?.otpSecretKey) {
            setActiveStep(1);
        } else if (params?.step === "step-1") {
            setActiveStep(0);
        } else {
            window.history.pushState({}, '', '/auth/signup/step-1');
        }
    }, [params]);

    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: !isMobile && 4
        }}>

            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#FBAD1D`
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography fontFamily={getFont(i18n)} variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>
                                {(t("signup.col1.title"))}
                            </Typography>
                            <Typography fontFamily={getFont(i18n)} variant={"body1"} mb={2} >
                                {(t("signup.col1.p1"))}
                            </Typography>
                            <Typography fontFamily={getFont(i18n)} variant={"body1"}>
                                {(t("signup.col1.p2"))}
                                <Link
                                    to={"/auth/login"}
                                    color={"#42464F"}
                                    underline={"none"}
                                    component={RouterLink}
                                    ml={1}
                                    fontFamily={getFont(i18n)}
                                >
                                    {(t("login.col1.title"))}
                                </Link>
                            </Typography>
                        </Div>

                        <Div sx={{ mt: 'auto', position: "relative" }}>
                            <Div style={{ transform: "rotate(180deg)", paddingLeft: "130px" }}>
                                <BigArrow height={207} width={167} fill={"white"} />
                            </Div>
                            <Link to="/" component={RouterLink} underline="none" style={{ position: "absolute", top: "95px", left: "15px" }}>
                                <img src={`${ASSET_IMAGES}/logo.png`} height={25} alt="Jumbo React" />
                            </Link>
                        </Div>

                    </Div>
                </CardContent>
                <CardContent
                    sx={{
                        flex: 1,
                        p: 4
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>
                                            <Typography variant='h6' fontFamily={getFont(i18n)}>
                                                {t(label)}
                                            </Typography>
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {
                            activeStep === 0 && (<NumberVerification responseData={responseData} setResponseData={setResponseData} hasMobile={hasMobile} sethasMobile={sethasMobile} />)
                        }
                        {
                            activeStep === 1 && (<OTPVerification responseData={responseData} setResponseData={setResponseData} hasMobile={hasMobile} sethasMobile={sethasMobile} />)
                        }
                        {
                            activeStep === 2 && (<SignupForm responseData={responseData} />)
                        }
                    </Box>
                </CardContent>
            </Card>

        </Div>
    );
};

export default Signup;
