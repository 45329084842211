import React, { Suspense } from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../shared/widgets/AuthUserDropdown";
import NotificationsDropdown from "../../../shared/NotificationsDropdown";
import MessagesDropdown from "../../../shared/MessagesDropdown";
import SearchGlobal from "../../../shared/SearchGlobal";
import { Button, CircularProgress, IconButton, Link, Slide, Typography, useMediaQuery } from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../shared/Logo";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import LanguageSwitchDropdown from 'app/shared/LanguageSwitch/LanguageSwitchDropdown';
import LocalizationOptions from 'app/shared/JumboCustomizer/components/LocalizationOptions';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import { AUTHORITY } from 'app/config/localStorageConfig';

const Header = ({ hideLogo }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
    const [dropdownSearchVisibility, setDropdownSearchVisibility] = React.useState(false);
    const { headerTheme } = useJumboHeaderTheme();
    const { authUser, authToken } = useJumboAuth();

    const [openMenu, setOpenMenu] = React.useState(false);

    const showDropdownSearch = useMediaQuery('(max-width:575px)');

    React.useEffect(() => {
        if (!showDropdownSearch) {
            setOpenMenu(true);
        } else {
            setOpenMenu(false);
        }
    }, [showDropdownSearch]);

    return (
        <React.Fragment>
            {
                !hideLogo && (
                    <Link to={"/"} component={RouterLink} underline="none">
                        {/* <Typography fontSize={showDropdownSearch ? "25px" : "32px"} color={"#FBAD1D"} fontWeight={600} sx={{ ml: showDropdownSearch ? "0" : "4.4vw" }}>
                            বিদেশ ফেরত
                        </Typography> */}
                        <img src={`${window.location.origin}/images/logo.png`} height={40} />
                    </Link>
                )
            }

            <Suspense
                fallback={
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            alignItems: 'center',
                            alignContent: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress color={"warning"} sx={{ m: '-40px auto 0' }} />
                    </Div>
                }
            >
                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={1.25}
                    sx={{
                        ml: "auto",
                        mr: showDropdownSearch ? "0" : "4.4vw",
                        mt: showDropdownSearch && openMenu && "290px",
                        background: showDropdownSearch && "white",
                        paddingBottom: showDropdownSearch && openMenu && "20px",
                        borderRadius: showDropdownSearch && "20px",
                        boxShadow: showDropdownSearch && "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}>
                    {
                        showDropdownSearch &&
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{
                                ml: 0,
                            }}
                            onClick={() => setOpenMenu(!openMenu)}
                        >
                            {
                                openMenu ? <MenuOpenIcon /> : <MenuIcon />
                            }
                        </IconButton>
                    }
                    {
                        openMenu && (
                            <>
                                <Link to={"/about"} component={RouterLink} underline="none">
                                    <Button variant="text" sx={{ fontFamily: getFont(i18n), color: "#18181B" }}>
                                        {t("header.about")}
                                    </Button>
                                </Link>
                                <Link to={"/news-and-events"} component={RouterLink} underline="none">
                                    <Button variant="text" sx={{ fontFamily: getFont(i18n), color: "#18181B" }}>
                                        {t("header.blog")}
                                    </Button>
                                </Link>

                                {authToken ? (
                                    <>
                                        {
                                            showDropdownSearch && (
                                                <Button variant='text' color='primary' onClick={() => navigate("/menu")}>
                                                    Menu
                                                </Button>
                                            )
                                        }
                                        {localStorage.getItem(AUTHORITY) != "ADMIN" && <MessagesDropdown />}

                                        {/* <NotificationsDropdown /> */}
                                        <AuthUserDropdown />
                                    </>
                                ) : (
                                    <>
                                        <Link to={"/auth/signup/step-1"} component={RouterLink} underline="none">
                                            <Button variant="text" sx={{ fontFamily: getFont(i18n), color: "#18181B" }}>
                                                {t("header.register")}
                                            </Button>
                                        </Link>
                                        <Link to={"/auth/login"} component={RouterLink} underline="none">
                                            <Button variant="contained" sx={{
                                                background: "#FBAD1D",
                                                '&:hover': {
                                                    backgroundColor: '#FBAD1D',
                                                    color: '#fff',
                                                },
                                                fontFamily: getFont(i18n),
                                            }}>
                                                {t("header.login")}
                                            </Button>
                                        </Link>
                                    </>
                                )}
                                <LanguageSwitchDropdown />
                            </>
                        )
                    }
                </Stack>
            </Suspense>

        </React.Fragment >
    );
};

export default Header;
