import React from "react";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import UserProfile from "app/pages/users/user-profile/UserProfile";
import ChatApp from "app/pages/apps/chat/ChatApp";
import ChangePassword from "app/pages/users/user-profile/ChangePassword";
import EditProfile from "app/pages/users/user-profile/EditProfile";
import Login from "app/pages/auth-pages/login/Login";
import signup from "app/pages/auth-pages/signup";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import MailApp from "app/pages/apps/mail/MailApp";
import ServiceList from "app/pages/serviceList";
import ServiceDetails from "app/pages/serviceDetails";
import ProviderProfile from "app/pages/providerProfile";
import LoginProvider from "app/pages/auth-pages-provider/login-provider/LoginProvider";
import SignupProvider from "app/pages/auth-pages-provider/signup-provider/SignupProvider";
import ForgotPasswordProvider from "app/pages/auth-pages-provider/forgot-password-provider/ForgotPasswordProvider";
import ResetPasswordProvider from "app/pages/auth-pages-provider/reset-password-provider/ResetPasswordProvider";
import NewsandEvent from "app/pages/NewsandEvent";
import NewsandEventDetails from "app/pages/newsandEventDetails";
import ChangePhoneNumber from "app/pages/users/user-profile/ChangePhoneNumber";
import Error404 from "app/pages/extra-pages/Error404/Error404";
import Services from "app/pages/service-provider/service/Services";
import Applications from "app/pages/service-provider/application/Applications";
import NewApplicationForm from "app/pages/service-provider/application/NewApplicationForm";
import NewService from "app/pages/service-provider/service/NewService";
import AdimnDashboard from "app/pages/admin/dashboard/AdimnDashboard";
import { ProviderEmployeeList, ProviderList } from "app/pages/admin/provider";
import { EditProviderProfile } from "app/pages/service-provider/editProviderProfile";
import Category from "app/pages/admin/category/Category";
import UserList from "app/pages/admin/userList/UserList";
import ServiceProviderList from "app/pages/serviceProviderList/ServiceProviderList";
import About from "app/pages/about/about";
import { ChangePasswordProvider } from "app/pages/service-provider/changePassword";
import ApplicationForm from "app/pages/applicationForm/applicationForm";
import { Blog, NewBlog } from "app/pages/admin/blog";
import SubmittedData from "app/pages/service-provider/application/SubmittedData";
import MobileMenu from "app/layouts/shared/sidebars/Sidebar/mobileMenu";
import { ProviderAccount } from "app/pages/service-provider/providerAccount";
import FAQ from "app/pages/FAQ/FAQ";
import FAQupdate from "app/pages/admin/faq/FAQupdate";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: "*",
        element: <Page component={Error404} layout={"solo-page"} />
    },
    {
        path: "/",
        element: <Page component={Home} layout={"public-page"} />
    },
    {
        path: "/about",
        element: <Page component={About} layout={"public-page"} />
    },
    {
        path: "/service-list",
        element: <Page component={ServiceList} layout={"public-page"} />
    },
    {
        path: "/serviceProvider/:id",
        element: <Page component={ProviderProfile} layout={"public-page"} />
    },
    {
        path: "/serviceProvider/list",
        element: <Page component={ServiceProviderList} layout={"public-page"} />
    },
    {
        path: "/news-and-events",
        element: <Page component={NewsandEvent} layout={"public-page"} />
    },
    {
        path: "/news-and-events/:id",
        element: <Page component={NewsandEventDetails} layout={"public-page"} />
    },
    {
        path: "/faq",
        element: <Page component={FAQ} layout={"public-page"} />
    },
    {
        path: "/service/details/:id",
        element: <Page component={ServiceDetails} layout={"public-page"} />
    },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/menu",
        element: <Page component={MobileMenu} />
    },
    {
        path: "/application-form/:id/:serviceid",
        element: <Page component={ApplicationForm} layout={"public-page"} />
    },
    {
        path: "/user/profile",
        element: <Page component={UserProfile} />
    },
    {
        path: "/user/edit-profile",
        element: <Page component={EditProfile} />
    },
    {
        path: "/user/change-password",
        element: <Page component={ChangePassword} />
    },
    {
        path: "/user/change-phone-number",
        element: <Page component={ChangePhoneNumber} />
    },
    {
        path: [
            "/app/chats",
            "/app/chats/:chatBy/:id",
        ],
        element: <Page component={ChatApp} />
    },
    {
        path: [
            "/app/:category",
            "/app/:category/:id",
            "/app/:category/message/:messageID",
            "/app/:category/:id/message/:messageID"
        ],
        element: <Page component={MailApp} />
    },
    {
        path: [
            "/service-provider/profile"
        ],
        element: <Page component={ProviderProfile} />
    },
    {
        path: "/service-provider/profile/edit",
        element: <Page component={EditProviderProfile} />
    },
    {
        path: "/service-provider/my-account/change-password",
        element: <Page component={ChangePasswordProvider} />
    },
    {
        path: "/service-provider/service",
        element: <Page component={Services} />
    },
    {
        path: "/service-provider/service/:id",
        element: <Page component={ServiceDetails} />
    },
    {
        path: [
            "/service-provider/service/new-service",
            "/service-provider/service/update/:id"
        ],
        element: <Page component={NewService} />
    },
    {
        path: "/service-provider/application-form",
        element: <Page component={Applications} />
    },
    {
        path: [
            "/service-provider/application-form/new-form",
            "/service-provider/application-form/update/:id"
        ],
        element: <Page component={NewApplicationForm} />
    },
    {
        path: "/service-provider/application-form/submitted-data/:id",
        element: <Page component={SubmittedData} />
    },
    {
        path: "/service-provider/my-account",
        element: <Page component={ProviderAccount} />
    },
    {
        path: "/admin/dashboard",
        element: <Page component={AdimnDashboard} />
    },
    {
        path: "/admin/service-provider",
        element: <Page component={ProviderList} />
    },
    {
        path: "/admin/service-provider/:id/employee/list",
        element: <Page component={ProviderEmployeeList} />
    },
    {
        path: "/admin/category",
        element: <Page component={Category} />
    },
    {
        path: "/admin/user-list",
        element: <Page component={UserList} />
    },
    {
        path: [
            "/admin/blog"
        ],
        element: <Page component={Blog} />
    },
    {
        path: [
            "/admin/blog/new",
            "/admin/blog/update/:id"
        ],
        element: <Page component={NewBlog} />
    },
    {
        path: [
            "/admin/faq/update",
        ],
        element: <Page component={FAQupdate} />
    },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/auth/login",
        element: <Page component={Login} layout={"solo-page"} />
    },
    {
        path: "/auth/signup/:step",
        element: <Page component={signup} layout={"solo-page"} />
    },
    {
        path: "/auth/forgot-password",
        element: <Page component={ForgotPassword} layout={"solo-page"} />
    },
    {
        path: "/auth/reset-password",
        element: <Page component={ResetPassword} layout={"solo-page"} />
    },
    {
        path: "/auth/provider/login",
        element: <Page component={LoginProvider} layout={"solo-page"} />
    },
    {
        path: "/auth/provider/signup",
        element: <Page component={SignupProvider} layout={"solo-page"} />
    },
    {
        path: "/auth/provider/forgot-password",
        element: <Page component={ForgotPasswordProvider} layout={"solo-page"} />
    },
    {
        path: "/auth/provider/reset-password",
        element: <Page component={ResetPasswordProvider} layout={"solo-page"} />
    },
];

const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };