import Div from '@jumbo/shared/Div';
import { OpenInNewOutlined } from '@mui/icons-material';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { getBanglaNumber } from 'app/hooks/getBanglaNumber';
import { getFont } from 'app/hooks/getFont';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const centerItem = { display: "flex", justifyContent: "center", alignItems: "center" };

const ServiceLocation = ({ data }) => {
    const { i18n } = useTranslation();
    return (
        <Grid container my={2} p={2} >
            <Grid item xs={6} md={3} p={1} sx={centerItem}>
                <Div>
                    <Typography fontFamily={getFont(i18n)} variant='h4'>{i18n.language == "bn-BD" ? `${data?.generalDistrictBn}, ${data?.generalDivisionBn}` : `${data?.generalDistrict}, ${data?.generalDivision}`}</Typography>
                    <Typography fontFamily={getFont(i18n)} variant='caption' color={"#555555"}>
                        {i18n.language == "bn-BD" ? `ঠিকানা: ${data?.detailsAddressBn}` : `Address: ${data?.detailsAddress}`}
                    </Typography>
                </Div>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={6} md={3} p={1} sx={centerItem}>
                <Div>
                    <Typography fontFamily={getFont(i18n)} variant='h4'>{i18n.language == "bn-BD" ? "যোগাযোগ" : "Contact"}</Typography>
                    <Typography fontFamily={getFont(i18n)} variant='caption' color={"#555555"}>
                        {i18n.language == "bn-BD" ? getBanglaNumber(data?.contractNo) : data?.contractNo}
                    </Typography>
                </Div>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={6} md={3.8} p={1} sx={centerItem}>
                <Div >
                    <Typography fontFamily={getFont(i18n)} variant='h4'>{i18n.language == "bn-BD" ? "ইমেল" : "Email"}</Typography>
                    <Typography fontFamily={getFont(i18n)} variant='caption' color={"#555555"}>
                        {data?.emailAddress}
                    </Typography>
                </Div>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={6} md={2} p={1} sx={centerItem}>
                <Button onClick={() => window.open(data?.googleMapUrl, '_blank')} variant='outlined' endIcon={<OpenInNewOutlined />} sx={{ borderRadius: "50px", fontFamily: getFont(i18n) }} >
                    {i18n.language == "bn-BD" ? "ম্যাপ" : "Map"}
                </Button>
            </Grid>
        </Grid>
    )
}

export default ServiceLocation;