import Div from '@jumbo/shared/Div/Div';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import blogServices from 'app/services/blog.service';
import publicServices from 'app/services/public/public.service';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const NewBlog = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [uploadFile, setuploadFile] = React.useState(null);

    const queryClient = useQueryClient();

    const { data } = useQuery(['get-a-blog-admin'], () => blogServices.getABlogAdmin(params?.id), {
        enabled: !!params?.id,
        onSuccess: (data) => {
            try {
                const htmlString = data.content.articleBody;
                const blocksFromHTML = convertFromHTML(htmlString);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML);

                data.content.articleBody = EditorState.createWithContent(contentState);

                const htmlStringBn = data.content.articleBodyBn;
                const blocksFromHTMLBn = convertFromHTML(htmlStringBn);
                const contentStateBn = ContentState.createFromBlockArray(blocksFromHTMLBn);

                data.content.articleBodyBn = EditorState.createWithContent(contentStateBn);

            } catch (error) {
                console.log(error);
            }
            formik.setValues(data?.content);
        }
    });

    const { data: dataBanner, isLoading: isLoadingBanner } = useQuery(['get-blog-banner-admin', data?.content?.imageUrl], () => publicServices.getImage(data?.content?.imageUrl), {
        enabled: !!data?.content?.imageUrl
    });

    const { mutate } = useMutation(blogServices.createNewBlog, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            setTimeout(() => {
                if (data?.statusCode == 200) {
                    window.location.assign(`/admin/blog`)
                }
            }, 3000);
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const { mutate: mutateUpdate } = useMutation(blogServices.updateNewBlog, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })

            setTimeout(() => {
                if (data?.statusCode == 200) {
                    window.location.assign(`/admin/blog`)
                }
            }, 3000);

        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            articleTitle: "",
            articleTitleBn: "",
            articleBody: "",
            articleBodyBn: "",
            isArticlePublished: "0",
            articlePublishDate: new Date()
        },
        onSubmit: values => {
            const temp = values;
            temp.articlePublishDate = moment(values.articlePublishDate).format('MM-DD-YYYY');
            try {
                temp.articleBody = stateToHTML(temp?.articleBody.getCurrentContent());
                temp.articleBodyBn = stateToHTML(temp?.articleBodyBn.getCurrentContent());
            } catch (error) {
                console.log(error);
            }

            console.log(temp);

            const formData = new FormData();
            formData.append("file", uploadFile);
            formData.append("data", JSON.stringify(temp));

            if (params?.id) {
                mutateUpdate({ id: params?.id, data: formData })
            } else {
                mutate(formData)
            }
        }
    })

    return (
        <Box>
            <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ mb: 2 }}>
                {params?.id ? "Update Post" : "Add New Post"}
            </Typography>
            <form onSubmit={formik.handleSubmit} >
                <Div sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Title (English)"
                        {...formik.getFieldProps('articleTitle')}
                    />
                </Div>
                <Div sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Title (Bangla)"
                        {...formik.getFieldProps('articleTitleBn')}
                    />
                </Div>

                <Typography sx={{ mb: 1 }}>Blog Banner</Typography>

                <TextField
                    fullWidth
                    type='file'
                    sx={{ mb: 3 }}
                    value={uploadFile?.filename}
                    onChange={(e) => setuploadFile(e.target.files[0])}
                />

                {
                    uploadFile ?
                        (<img src={URL.createObjectURL(uploadFile)} style={{ width: "100%", marginBottom: "10px", borderRadius: "6px" }} />)
                        :
                        (
                            <img
                                src={dataBanner?.content ? `data:image/png;base64, ${dataBanner?.content}` : `${window.location.origin}/images/banner/noBanner.png`} style={{ width: "100%", marginBottom: "10px", borderRadius: "6px" }} />
                        )
                }

                {
                    uploadFile && (
                        <Button variant='text' sx={{ mb: 2 }} onClick={() => setuploadFile(null)} >Remove this image</Button>
                    )
                }

                <Div sx={{ border: "1px solid lightgray", borderRadius: "6px", mb: 3 }}>
                    <Typography sx={{ p: 1 }}>{"Description (English)"}</Typography>
                    <Editor
                        editorStyle={{
                            width: '100%',
                            minHeight: 100,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'lightgray',
                        }}
                        editorState={formik.values.articleBody}
                        onEditorStateChange={editorState => {
                            formik.setFieldValue("articleBody", editorState)
                        }}
                    />
                </Div>

                <Div sx={{ border: "1px solid lightgray", borderRadius: "6px", mb: 3 }}>
                    <Typography sx={{ p: 1 }}>{"Description (Bangla)"}</Typography>
                    <Editor
                        editorStyle={{
                            width: '100%',
                            minHeight: 100,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'lightgray',
                        }}
                        editorState={formik.values.articleBodyBn}
                        onEditorStateChange={editorState => {
                            formik.setFieldValue("articleBodyBn", editorState)
                        }}
                    />
                </Div>

                <Grid container>
                    <Grid item sm={12} md={6} >
                        <FormControl >
                            <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                sx={{ mb: 3 }}
                                {...formik.getFieldProps('isArticlePublished')}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Published" />
                                <FormControlLabel value="0" control={<Radio />} label="Unpublished" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                sx={{ width: "100%" }}
                                format='dd/MM/yyyy'
                                label={"Publish Date"}
                                onChange={(e) => formik.setFieldValue("articlePublishDate", e)}
                                value={formik.values.articlePublishDate ? formik.values.articlePublishDate : new Date()}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Stack direction={"row"} spacing={2}>
                    <Button
                        fullWidth
                        type="button"
                        variant="contained"
                        size="large"
                        color='error'
                        onClick={() => navigate("/admin/blog")}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                    >
                        {params?.id ? "Update" : "Post"}
                    </LoadingButton>
                </Stack>
            </form>
        </Box>
    )
}

export default NewBlog;