import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const FolderItem = ({ icon, name, selected, slug }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleFolderItem = () => {
        navigate(`/app/${slug}`)
    }
    return (
        <MenuItem disableRipple sx={{ p: 0, mb: 2 }} selected={selected}>
            <Link underline={"none"} onClick={handleFolderItem}>
                {
                    icon &&
                    <ListItemIcon sx={{ color: 'inherit' }}>
                        {icon}
                    </ListItemIcon>
                }
                <ListItemText primary={name} primaryTypographyProps={{
                    style: {
                        fontFamily: getFont(i18n)
                    }
                }} />
            </Link>
        </MenuItem>
    );
};
/* Todo prop define */
export default FolderItem;
