import Div from '@jumbo/shared/Div';
import { Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocationOn } from '@mui/icons-material';
import publicServices from 'app/services/public/public.service';
import { useQuery } from 'react-query';
import { LOCATION } from 'app/config/localStorageConfig';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const LocationFilter = ({ setlocationFilter }) => {

    const { t, i18n } = useTranslation();
    const [selectlocation, setselectlocation] = useState(0);
    const { data: dataDist } = useQuery(['get-district'], () => publicServices.getDistrict());

    const locationParams = localStorage.getItem(LOCATION)
    useEffect(() => {

        if (locationParams) {
            setselectlocation(locationParams);
            const getLocation = dataDist?.content?.filter((item) => item?.id == locationParams)?.[0];
            setlocationFilter(i18n.language == "bn-BD" ? getLocation?.NAME_BN : getLocation?.NAME);
            localStorage.setItem(LOCATION, locationParams);
        } else {
            setselectlocation(0)
            setlocationFilter("");
            localStorage.setItem(LOCATION, "0");
        }
    }, [locationParams]);

    return (
        <Div>
            <FormControl fullWidth >
                <InputLabel id="demo-select-small" size="small" sx={{ display: "flex", alignItems: "center" }}>
                    <LocationOn fontSize='20px' />
                    <Typography fontFamily={getFont(i18n)} >{t("placeholder.edit-sp-profile.location")}</Typography>
                </InputLabel>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    label={`... ${t("placeholder.edit-sp-profile.location")}`}
                    size="medium"
                    value={selectlocation}
                    onChange={(e) => {
                        setselectlocation(e.target.value);
                        const getLocation = dataDist?.content?.filter((item) => item?.id == e.target.value)?.[0];
                        setlocationFilter(i18n.language == "bn-BD" ? getLocation?.NAME_BN : getLocation?.NAME);
                        localStorage.setItem(LOCATION, e.target.value);
                    }}
                >
                    <MenuItem sx={{ fontFamily: getFont(i18n) }} value={0}>
                        <Typography fontFamily={getFont(i18n)} >
                            {i18n.language == "bn-BD" ? "অবস্থান নির্বাচন করুন" : "Select location"}
                        </Typography>
                    </MenuItem>
                    {
                        dataDist?.content?.map((item, i) => (
                            <MenuItem key={i} value={item?.id}>
                                <Typography fontFamily={getFont(i18n)} >{i18n.language == "bn-BD" ? item?.NAME_BN : item?.NAME}</Typography>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Div>
    )
}

export default LocationFilter;