import { axiosIns } from "app/config/apiConfig";

const getApplicationList = async () => {
    const response = await axiosIns.get(`/custom-application/template/all`);
    return response.data;
};

const getOneApplication = async (id) => {
    const response = await axiosIns.get(`/custom-application/template/all/${id}`);
    return response.data;
};

const createApplication = async (data) => {
    const response = await axiosIns.post(`/custom-application/template/save`, data);
    return response.data;
};

const updateApplication = async ({ id, data }) => {
    const response = await axiosIns.post(`/custom-application/template/save/${id}`, data);
    return response.data;
};

const saveApplicationValue = async ({ data }) => {
    const response = await axiosIns.post(`/custom-application/values/save`, data);
    return response.data;
};

const getOneApplicationValue = async (id) => {
    const response = await axiosIns.get(`/custom-application/values/all/${id}`);
    return response.data;
};

const getApplicationValueList = async (id) => {
    const response = await axiosIns.get(`/custom-application/template/all-submitted-data/${id}`);
    return response.data;
};

const statusChangeApplication = async ({ id, status }) => {
    const response = await axiosIns.post(`activity/change/application/${id}/${status}`);
    return response.data;
};

const applicationServices = {
    getApplicationList,
    createApplication,
    updateApplication,
    getOneApplication,
    saveApplicationValue,
    getOneApplicationValue,
    getApplicationValueList,
    statusChangeApplication
};

export default applicationServices;