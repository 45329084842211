import React from "react";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Category, CorporateFare, Feed, People, PieChartOutline, Quiz } from "@mui/icons-material";

const menusAdmin = [
    {
        uri: "/admin/dashboard",
        label: 'sidebar.menuItem.dashboard',
        type: "nav-item",
        icon: <PieChartOutline sx={{ fontSize: 20 }} />
    },
    {
        uri: "/admin/category",
        label: 'sidebar.menuItem.category',
        type: "nav-item",
        icon: <Category sx={{ fontSize: 20 }} />
    },
    {
        uri: "/admin/service-provider",
        label: 'sidebar.menuItem.provider',
        type: "nav-item",
        icon: <CorporateFare sx={{ fontSize: 20 }} />
    },
    {
        uri: "/admin/user-list",
        label: 'sidebar.menuItem.userList',
        type: "nav-item",
        icon: <People sx={{ fontSize: 20 }} />
    },
    {
        uri: "/admin/blog",
        label: 'sidebar.menuItem.blog',
        type: "nav-item",
        icon: <Feed sx={{ fontSize: 20 }} />
    },
    {
        uri: "/admin/faq/update",
        label: 'sidebar.menuItem.faq',
        type: "nav-item",
        icon: <Quiz sx={{ fontSize: 20 }} />
    },
];

export default menusAdmin;
