import Div from "@jumbo/shared/Div";
import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  useMediaQuery,
  Button,
  Link,
  Stack,
} from "@mui/material";
import { HelpCenterOutlined, Language, LocationOn } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getFont } from "app/hooks/getFont";

const imgStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
  borderRadius: "2px",
  padding: "5px",
  width: "60px",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ProviderInfo = ({ id, name, location, website, service, fee, image }) => {
  const { i18n } = useTranslation();

  return (
    <Div sx={{ display: "flex", alignItems: "center" }}>
      <Link
        to={`/serviceProvider/${id}`}
        component={RouterLink}
        underline="none"
      >
        <Div style={imgStyle}>
          <Div
            sx={{
              width: "55px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={image} width={"100%"} height={"100%"} />
          </Div>
        </Div>
      </Link>
      <Div sx={{ ml: 2 }}>
        <Link
          to={`/serviceProvider/${id}`}
          component={RouterLink}
          underline="none"
        >
          <Typography
            fontFamily={getFont(i18n)}
            variant="h5"
            lineHeight={"2px"}
          >
            {name}
          </Typography>
        </Link>
        {website && (
          <Link href={website} underline="hover" target="_blank" mt={1}>
            <Stack direction={"row"} alignItems={"center"}>
              <Language fontSize="10px" color="disabled" />
              <Typography
                fontFamily={getFont(i18n)}
                variant="caption"
                fontSize="13px"
                color={"#999999"}
                lineHeight={"12px"}
              >
                {website}
              </Typography>
            </Stack>
          </Link>
        )}
        {location && (
          <Typography
            fontFamily={getFont(i18n)}
            variant="caption"
            color={"#999999"}
            lineHeight={"12px"}
            style={{ wordBreak: "break-word" }}
          >
            <LocationOn fontSize="12px" />
            {location}
          </Typography>
        )}
        {service && (
          <Typography
            fontFamily={getFont(i18n)}
            display={"block"}
            variant="caption"
            color={"#0C4CA3"}
            lineHeight={"12px"}
          >
            {service == "1" ? `1 service` : `${service} Services`}
          </Typography>
        )}
        {fee && (
          <Button sx={{ p: "4px" }} startIcon={<HelpCenterOutlined />}>
            <Typography
              fontFamily={getFont(i18n)}
              fontSize={"12px"}
              color={"#0C4CA3"}
            >
              {fee}
            </Typography>
          </Button>
        )}
      </Div>
      <Div></Div>
    </Div>
  );
};

export default ProviderInfo;
