import React from 'react';
import useChatApp from "../../hooks/useChatApp";
import ContentPlaceholder from "./ActiveConversationChat/ContentPlaceholder";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import ActiveConversationHeader from "./ActiveConversationHeader";
import ActiveConversationFooter from "./ActiveConversationFooter";
import ConversationChatGroupByDate from "./ActiveConversationChat/ConversationChatGroupByDate";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { chatService } from "../../../../../services/chat-services";
import Div from "@jumbo/shared/Div";
import { CircularProgress, Typography } from "@mui/material";
import { conversations } from 'app/services/mock-data/chats';
import chatServices from 'app/services/chat.service';

import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { USER_ID, USER_REG_ID } from 'app/config/localStorageConfig';

import AbstractXHRObject from 'sockjs-client/lib/transport/browser/abstract-xhr';
const _start = AbstractXHRObject.prototype._start;
AbstractXHRObject.prototype._start = function (method, url, payload, opts) {
    if (!opts) {
        opts = { noCredentials: true };
    }
    return _start.call(this, method, url, payload, opts);
};

var stompClient = null;
const userId = localStorage.getItem(USER_REG_ID);

const ActiveConversation = React.forwardRef(({ onRefresh }, ref) => {
    const { setActiveConversation } = useChatApp();
    const [isRefresh, setisRefresh] = React.useState(0);
    const [queryKey, setQueryKey] = React.useState(["active-conversation"]);
    const scrollbarRef = React.useRef();
    const { chatBy, id } = useParams();
    const [channelID, setChannelID] = React.useState(null);
    const [chatData, setchatData] = React.useState([]);
    const [chatDataTemp, setchatDataTemp] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const { mutate } = useMutation(chatServices.getChannelId, {
        onSuccess: (data) => {
            if (data?.content) {
                connect(data?.content?.channelId);
                setChannelID(data?.content?.channelId);
                setchatData(data?.content?.history);
                setchatDataTemp(data?.content?.history);
                setIsLoading(false);
            }
        }
    });

    const queryClient = useQueryClient();

    const connect = (channelId) => {

        var socket = new SockJS(`${process.env.REACT_APP_SOCKET_URL}/chat/${channelId}`);
        stompClient = over(socket);
        stompClient.connect({}, (frame) => {

            //setConnected(true);
            console.log('Connected: ' + frame);
            stompClient.subscribe('/topic/messages/' + channelId, (messageOutput) => {
                showMessageOutput(JSON.parse(messageOutput.body));
            });
        });
    }

    const sendMessage = (txt) => {
        var from = userId;
        var text = txt;
        var channelId = channelID;
        stompClient.send(`/app/chat/${channelID}`, {}, JSON.stringify({ 'from': from, 'text': text, 'channelId': channelId }));
    }

    const showMessageOutput = (response) => {
        setchatData(previous => [...previous, response])
        setchatDataTemp(previous => [...previous, response])
    }

    const onSearch = (value) => {
        if (!!value) {
            const result = chatDataTemp.filter((element) => element?.text?.includes(value));
            console.log({ result });
            setchatData(result);
        } else {
            setchatData(chatDataTemp);
        }
    }

    React.useEffect(() => {
        setActiveConversation(chatData);
        if (scrollbarRef)
            scrollbarRef.current?.scrollToBottom();
    }, [chatData, scrollbarRef]);

    React.useEffect(() => {
        if (id) {
            setQueryKey(["active-conversation", isRefresh, { id: id }]);
        } else
            setQueryKey(["active-conversation", isRefresh]);
    }, [id, isRefresh]);

    React.useEffect(() => {
        if (id) {
            mutate({
                "from": userId,
                "to": id
            })
        } else {
            setIsLoading(false);
        }

        // connect("asfasdfsd23434-sdf34");
        // setChannelID("asfasdfsd23434-sdf34");

    }, [id]);

    React.useImperativeHandle(ref, () => ({
        async refresh() {
            await queryClient.invalidateQueries(["active-conversation"]);
            onRefresh();
        },
    }));

    if (isLoading) {
        return (
            <Div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: theme => theme.spacing(3),
                    m: 'auto',
                }}
            >
                <CircularProgress />
                <Typography variant={'h6'} color={'text.secondary'} mt={2}>Loading messages</Typography>
            </Div>
        )
    }

    if (!id) {
        return (
            <React.Fragment>
                <ContentPlaceholder />
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <ActiveConversationHeader onSearch={onSearch} />
                <JumboScrollbar
                    ref={scrollbarRef}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                    style={{ minHeight: 200 }}
                >
                    <ConversationChatGroupByDate conversationQuery={chatData} />
                </JumboScrollbar>
                <ActiveConversationFooter sendMessage={sendMessage} />
            </React.Fragment>
        );
    }

});

ActiveConversation.defaultProps = {
    onRefresh: () => {
    }
};
export default ActiveConversation;
