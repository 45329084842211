import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState, useEffect } from 'react';

const FieldComponent = ({ inputType, fieldType, label, helpText, placeHolder, name, options, maxRows, size = 'small', ...props }) => {

    return (
        <FormControl fullWidth>
            {
                (fieldType === "text" || fieldType === "textarea" || fieldType === "number" ) && (
                    <TextField
                        size={size}
                        type={inputType}
                        name={name}
                        label={label}
                        placeholder={placeHolder}
                        helperText={helpText}
                        multiline={fieldType === "textarea" ? true : false}
                        maxRows={maxRows}
                        {...props}
                    />
                )
            }
            {
                (fieldType === "date" || fieldType === "file") && (
                    <>
                        <Typography >{label}</Typography>
                        <TextField
                            size={size}
                            type={fieldType}
                            name={name}
                            {...props}
                        />
                    </>
                )
            }
            {
                fieldType === "select" && (<>
                    <InputLabel id={label}>{label}</InputLabel>
                    <Select
                        labelId={label}
                        name={name}
                        label={label}
                        {...props}
                    >
                        {
                            options?.map((item) => (
                                <MenuItem value={item?.value}>
                                    {item?.label}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </>)
            }
            {
                fieldType === "checkbox" && (<>
                    <FormLabel id={label}>{label}</FormLabel>
                    <FormGroup
                        row
                        aria-labelledby={label}
                        name={name}
                        {...props}
                    >
                        {
                            options?.map((item) => (
                                <FormControlLabel value={item?.value} control={<Checkbox size={size} />} label={item?.label} />
                            ))
                        }
                    </FormGroup>
                </>)
            }
            {
                fieldType === "radio" && (<>
                    <FormLabel id={label}>{label}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby={label}
                        name={name}
                        {...props}
                    >
                        {
                            options?.map((item) => (
                                <FormControlLabel value={item?.value} control={<Radio size={size} />} label={item?.label} />
                            ))
                        }
                    </RadioGroup>
                </>)
            }
        </FormControl>
    )
}

export default FieldComponent;
