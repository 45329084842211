import Div from "@jumbo/shared/Div";
import React, { useState, useEffect } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ProviderInfo from "app/shared/ProviderInfo";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { getFont } from "app/hooks/getFont";
import { getBanglaNumber } from "app/hooks/getBanglaNumber";

const cardStyle = {
  background: "#FDFDFD",
  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
  borderRadius: "2px",
  padding: "10px",
};

const containerStyle = {
  width: "100%",
  height: "300px",
};

const ProviderDetails = ({
  id,
  name,
  address,
  email,
  website,
  mobile,
  mapUrl,
  image,
}) => {
  const { t, i18n } = useTranslation();
  const [emails, setemails] = useState([]);

  useEffect(() => {
    if (email) {
      let temp = email?.split(",");
      setemails(temp);
    }
  }, [email]);

  return (
    <Div style={cardStyle}>
      {name && (
        <Div sx={{ pt: 3 }}>
          <ProviderInfo
            id={id}
            name={name}
            location={address}
            website={website}
            image={image}
          />
          <Divider sx={{ my: 2 }} />
        </Div>
      )}

      <Div>
        {/* {
                    isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={position}
                            zoom={12}
                        >
                            <Marker
                                position={position}
                                animation={"BOUNCE"}
                            />
                        </GoogleMap>
                    ) : <></>
                } */}

        <div
          style={{
            overflow: "hidden",
          }}
          dangerouslySetInnerHTML={{ __html: mapUrl }}
        ></div>
      </Div>
      <Div>
        <List>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ fontFamily: getFont(i18n) }}
              secondaryTypographyProps={{ fontFamily: getFont(i18n) }}
              primary={i18n.language == "bn-BD" ? "ঠিকানা" : "Address"}
              secondary={address}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ fontFamily: getFont(i18n) }}
              secondaryTypographyProps={{ fontFamily: getFont(i18n) }}
              primary={i18n.language == "bn-BD" ? "ইমেইল" : "Email"}
              secondary={
                emails.length != 0 ? (
                  emails.map((item, i) => (
                    <div key={i}>
                      <a href={`mailto:${item.trim()}`}>{item.trim()}</a>
                    </div>
                  ))
                ) : (
                  <a href={`mailto:${email}`}>{email}</a>
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ fontFamily: getFont(i18n) }}
              secondaryTypographyProps={{ fontFamily: getFont(i18n) }}
              primary={
                i18n.language == "bn-BD"
                  ? "যোগাযোগঃ ফোন নাম্বার"
                  : "Contact: Tel"
              }
              secondary={
                i18n.language == "bn-BD" ? getBanglaNumber(mobile) : mobile
              }
            />
          </ListItem>
        </List>
      </Div>
    </Div>
  );
};

export default ProviderDetails;
