import Div from '@jumbo/shared/Div';
import React, { useState, useEffect } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import providerServices from 'app/services/provider/provider.service';
import { Radio, Typography } from '@mui/material';
import { PROVIDER } from 'app/config/localStorageConfig';
import { getFont } from 'app/hooks/getFont';
import { useTranslation } from 'react-i18next';

const ProviderFilter = ({ setproviderFilter }) => {

    const { t, i18n } = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams();

    const [state, setState] = React.useState({
        "0": false,
    });

    const { data } = useQuery(['get-service-provider-list'], () => providerServices.getProviderList())

    const providerParams = localStorage.getItem(PROVIDER)
    useEffect(() => {
        if (providerParams) {
            setState({
                [providerParams]: true,
            });
            const getProvider = data?.content?.filter((item) => item?.providerInfoId == providerParams)?.[0];
            setproviderFilter(i18n.language == "bn-BD" ? getProvider?.nameBn : getProvider?.name);
            localStorage.setItem(PROVIDER, providerParams);
        } else {
            setState({
                "0": true,
            });
            setproviderFilter("")
            localStorage.setItem(PROVIDER, "0");
        }
    }, [providerParams]);

    const handleChange = (event) => {
        setState({
            [event.target.name]: event.target.checked,
        });
        const getProvider = data?.content?.filter((item) => item?.providerInfoId == event.target.name)?.[0];
        setproviderFilter(i18n.language == "bn-BD" ? getProvider?.nameBn : getProvider?.name);
        localStorage.setItem(PROVIDER, event.target.name);
    };

    return (
        <Div>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend" color='info' sx={{ py: 2, fontFamily: getFont(i18n) }}>{t("home.body.title.service-provider")}</FormLabel>
                <FormGroup sx={{
                    flexWrap: "nowrap",
                    maxHeight: "400px",
                    minWidth: "215px",
                    overflow: "hidden auto"
                }}>
                    <FormControlLabel
                        control={
                            <Radio checked={state[0]} onChange={handleChange} name="0" />
                        }
                        label={<Typography fontFamily={getFont(i18n)}>
                            {i18n.language == "bn-BD" ? "সবগুলো" : "All"}
                        </Typography>}
                    />

                    {
                        data?.content?.map((item, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Radio checked={state[item?.providerInfoId] ? true : false} onChange={handleChange} name={item?.providerInfoId?.toString()} />
                                }
                                label={<Typography fontFamily={getFont(i18n)}>
                                    {i18n.language == "bn-BD" ? item?.nameBn : item?.name}
                                </Typography>}
                            />
                        ))
                    }


                </FormGroup>
            </FormControl>
        </Div>
    )
}

export default ProviderFilter;