import React from 'react';
import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import useChatApp from "../../hooks/useChatApp";
import ConversationItem from "../ConversationItem";
import { chatService } from "../../../../../services/chat-services";
import chatServices from 'app/services/chat.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import JumboSearch from '@jumbo/components/JumboSearch/JumboSearch';

const RecentConversationsList = () => {
    const { t, i18n } = useTranslation();
    const [data, setdata] = React.useState();
    const [tempData, settempData] = React.useState();
    const { recentConversationsListRef } = useChatApp();
    const renderContact = React.useCallback((contact) => {
        return (
            <ConversationItem conversationItem={contact} />
        );
    }, []);

    const onSearch = (value) => {
        if (!!value) {
            const result = data?.content?.filter((element) => element?.allForSearch?.includes(value));
            settempData(result);
        } else {
            settempData(data?.content);
        }
    }

    React.useEffect(() => {
        settempData(data?.content)
    }, [data]);

    return (
        <React.Fragment>
            <Div
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: theme => theme.spacing(1.5, 2),
                    bgcolor: theme => theme.palette.action.hover,
                }}
            >
                <Typography
                    sx={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
                    variant="h6"
                    color="text.secondary" mb={0}
                    fontFamily={getFont(i18n)}
                >
                    <small>{i18n.language == "bn-BD" ? "সাম্প্রতিক চ্যাট" : "Recent chats"}</small>
                </Typography>
            </Div>
            <JumboRqList
                ref={recentConversationsListRef}
                service={chatServices.getChatList}
                renderItem={renderContact}
                primaryKey={"channelId"}
                queryOptions={{
                    queryKey: "recent-conversations-list",
                    dataKey: "conversations"
                }}
                componentElement={"div"}
                toolbar={
                    <Div sx={{ px: 2, py: 1 }}>
                        <JumboSearch onChange={(value) => onSearch(value)} />
                    </Div>
                }
                filterData={tempData}
                setData={setdata}
            />
        </React.Fragment>

    );
};

export default RecentConversationsList;
