import { axiosIns } from "app/config/apiConfig";
import axios from "axios";

const getChatContacts = async () => {
    const response = await axiosIns.get(`/chat-bot/get/returnee/all`);
    return response.data;
};

const getChatList = async () => {
    const response = await axiosIns.get(`/chat-bot/get/channel/all`);
    return response.data;
};

const addConversationMessage = async (data) => {
    const response = await axios.post(`http://203.83.188.246:8090/icmpd-web/send-message`, data);
    return response.data;
};

const getConversationByContactID = async (chatChannelId) => {
    const response = await axiosIns.get(`/chat-bot/get/chat-text/all/${chatChannelId}`);
    return response.data;
};

const getChannelId = async (data) => {
    const response = await axiosIns.post(`/chat-bot/chat/channel/get-id`, data);
    return response.data;
};

const getNotifications = async () => {
    const response = await axiosIns.get(`/chat-bot/get/notification`);
    return response.data;
};


const chatServices = {
    getChatContacts,
    getChatList,
    addConversationMessage,
    getConversationByContactID,
    getChannelId,
    getNotifications
};

export default chatServices;