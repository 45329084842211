import { Typography } from '@mui/material'
import { useDrag } from 'react-dnd'
const style = {
    border: '1px dashed gray',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    cursor: 'move',
    width: "100%",
    display: "flex",
    alignItems: "center"
}
export const FieldType = function FieldType({ fieldType, inputType, title, icon, addField }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "box",
        item: { fieldType, inputType, title },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                addField(item)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))
    const opacity = isDragging ? 0.4 : 1
    return (
        <div ref={drag} style={{ ...style, opacity }} data-testid={`box`}>
            {icon}
            <Typography variant='body2' marginLeft={"10px"}>
                {title}
            </Typography>
        </div>
    )
}