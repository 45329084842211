import React from 'react';
import { Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { ArrowBackIos, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import { useJumboTheme } from '@jumbo/hooks';
import { useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import returneeServices from 'app/services/returnee.service';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import * as yup from "yup";
import { USER_ID } from 'app/config/localStorageConfig';
import providerServices from 'app/services/provider/provider.service';

const userId = localStorage.getItem(USER_ID);

const MySwal = withReactContent(Swal)

const validationSchema = yup.object({
    passwordNew: yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .required('Password is required'),
    passwordCon: yup.string()
        .oneOf([yup.ref('passwordNew'), null], 'Passwords must match')
});

const ChangePassword = () => {

    const { theme } = useJumboTheme();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [showPasswordOld, setShowPasswordOld] = React.useState(false);
    const [showPasswordNew, setShowPasswordNew] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

    const handleClickShowPassword = () => setShowPasswordOld((show) => !show);
    const handleClickShowPasswordNew = () => setShowPasswordNew((show) => !show);
    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { mutate } = useMutation(providerServices.providerChangePassword, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
            if (data?.statusCode == 200) {
                navigate(`/`)
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            passwordOld: "",
            passwordNew: "",
            passwordCon: ""
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log(values);
            delete values.passwordCon
            mutate(values)
        }
    })

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto' }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent sx={{ flex: 1, p: 4 }}>
                    <Stack direction={"row"} alignItems={"center"} mb={3} spacing={2} >
                        <IconButton onClick={() => navigate("/service-provider/my-account")} sx={{ ml: -1.25 }}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography fontSize={20} variant={'body1'} color={'inherit'} >Change Password</Typography>
                    </Stack>

                    <Stack direction={"column"} sx={{ mb: 2, ml: 3 }} >
                        <Typography fontSize={16} variant={'caption'} color={'primary'}>Password Validation</Typography>
                        <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>1. Should be minimum 8 letters.</Typography>
                        <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>2. Require atleast a number.</Typography>
                        <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>3. Require atleast a lowercase letter.</Typography>
                        <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>4. Require atleast an uppercase letter.</Typography>
                    </Stack>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <form onSubmit={formik.handleSubmit} >
                            <Div sx={{ mb: 2 }}>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="outlined-adornment-password"
                                        type={showPasswordOld ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Old Password"
                                        {...formik.getFieldProps('passwordOld')}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="outlined-adornment-password"
                                        type={showPasswordNew ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordNew}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="New Password"
                                        error={formik.touched.passwordNew && formik.errors.passwordNew ? true : false}
                                        helperText={formik.errors.passwordNew}
                                        {...formik.getFieldProps('passwordNew')}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="outlined-adornment-password"
                                        type={showPasswordConfirm ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordConfirm}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                        error={formik.touched.passwordCon && formik.errors.passwordCon ? true : false}
                                        helperText={formik.errors.passwordCon}
                                        {...formik.getFieldProps('passwordCon')}
                                    />
                                </FormControl>
                                <Button disabled={!formik.isValid || Object.keys(formik.touched).length == 0} sx={{ m: 1 }} type='submit' variant="contained">Change Password</Button>
                            </Div>
                        </form>
                    </Div>
                </CardContent>
            </Card>

        </Div>
    );
};

export default ChangePassword;
