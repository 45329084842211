import React from 'react';
import Chip from '@mui/material/Chip';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { MenuItem, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import Div from '@jumbo/shared/Div';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const ChipsAddRemove = ({ data, setData, label, field }) => {
    const { t, i18n } = useTranslation();
    const [chipData, setChipData] = React.useState([]);
    const [item, setItem] = React.useState("");
    const [isDone, setisDone] = React.useState(false);

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => {
            const temp = chips.filter((chip) => chip.key !== chipToDelete.key);
            let result = temp.map(({ label }) => label);
            setData(result.join(", "));
            return temp;
        });
    };
    const addChipItem = (value) => {
        // const message = event.target.value.trim();
        const newItem = chipData.concat({
            key: Math.floor(Math.random() * 100),
            label: value
        });
        setChipData(newItem);
        console.log({ data });
        setData(prev => prev?.length == 0 ? value : prev.concat(", ", value))
        setisDone(true)
    }

    const handleKeyPress = (e) => {
        if (e.key === ',') {
            addChipItem(item)
        } else {
            setisDone(false)
        }
    }

    React.useEffect(() => {
        if (data?.length != 0) {
            const newData = [];
            data?.split(", ")?.map((item) => {
                newData.push({
                    key: Math.floor(Math.random() * 100),
                    label: item
                })
            })
            setChipData(newData)
        } else {
            setChipData([])
        }

    }, [data]);


    return (
        <Paper
            sx={{
                p: 1,
                m: 0,
            }}
            component="ul"
        >
            <Typography fontFamily={getFont(i18n)} sx={{ pl: 1.5 }} color="gray" variant='h6'>{label}</Typography>
            <Div
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    alignItems: 'center',
                    p: 1,
                    m: 0,
                }}>
                {chipData.map((data) => {
                    let icon;
                    return (
                        <ListItem key={data.key}>
                            <Chip
                                icon={icon}
                                variant={"outlined"}
                                label={data.label}
                                onDelete={handleDelete(data)}
                            />
                        </ListItem>
                    );
                })}
                <TextField
                    // select
                    fullWidth
                    label={i18n.language == "bn-BD" ? `আরো ${field} যোগ করুন` : `Add more ${field}`}
                    value={item}
                    onChange={(e) => {
                        if (!isDone) {
                            setItem(e.target.value)
                        } else {
                            setItem('')
                        }
                    }}
                    onKeyDown={handleKeyPress}
                    // component="li"
                    sx={{
                        mx: 1,
                        mt: 1
                    }}
                    inputProps={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                >
                    {/* <MenuItem value={"JavaScript"}>JavaScript</MenuItem>
                    <MenuItem value={"TypeScript"}>TypeScript</MenuItem>
                    <MenuItem value={"Java"}>Java</MenuItem> */}
                </TextField>
            </Div>
            <Typography fontFamily={getFont(i18n)} sx={{ pl: 2 }} color="gray" variant='caption'>{i18n.language == "bn-BD" ? `একাধিক ${field} যোগ করতে কমা ( , ) ব্যবহার করুন` : `Use comma ( , ) to add multiple ${field}`}</Typography>
        </Paper>
    );
};
export default ChipsAddRemove;
