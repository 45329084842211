import Div from '@jumbo/shared/Div';
import React, { useState, useEffect } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useQuery } from 'react-query';
import categoryServices from 'app/services/category.service';
import { useSearchParams } from 'react-router-dom';
import { Radio, Typography } from '@mui/material';
import { CATEGORY, SUBCATEGORY } from 'app/config/localStorageConfig';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const ServiceFilter = ({ setcategoryFilter }) => {

    let [searchParams, setSearchParams] = useSearchParams();
    const { t, i18n } = useTranslation();

    const [state, setState] = React.useState({
        "0": false,
    });

    const { data } = useQuery(['get-categoty-list'], () => categoryServices.getCategoryList());

    const handleChange = (event) => {
        setState({
            [event.target.name]: event.target.checked,
        });
        localStorage.setItem(CATEGORY, event.target.name);
        const getCategory = data?.content?.filter((item) => item?.id == event.target.name)?.[0];
        const subCategory = getCategory?.child;
        setcategoryFilter(i18n.language == "bn-BD" ? getCategory?.nameBn : getCategory?.name);
        !!subCategory && localStorage.setItem(SUBCATEGORY, JSON.stringify(subCategory));
    };

    const categoryParams = localStorage.getItem(CATEGORY)

    useEffect(() => {

        if (categoryParams) {
            setState({
                [categoryParams]: true,
            });
            localStorage.setItem(CATEGORY, categoryParams);
            const getCategory = data?.content?.filter((item) => item?.id == categoryParams)?.[0];
            const subCategory = getCategory?.child;
            setcategoryFilter(i18n.language == "bn-BD" ? getCategory?.nameBn : getCategory?.name);
            !!subCategory && localStorage.setItem(SUBCATEGORY, JSON.stringify(subCategory));
        } else {
            setState({
                "0": true,
            });
            setcategoryFilter("All");
            localStorage.setItem(CATEGORY, "0");
            localStorage.setItem(SUBCATEGORY, "[]");
        }
    }, [categoryParams]);

    return (
        <Div>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend" color='info' sx={{ py: 2, fontFamily: getFont(i18n) }}>{t("home.body.title.service-category")}</FormLabel>
                <FormGroup sx={{
                    flexWrap: "nowrap",
                    maxHeight: "400px",
                    minWidth: "215px",
                    overflow: "hidden auto"
                }}>
                    <FormControlLabel
                        control={
                            <Radio checked={state[0]} onChange={handleChange} name="0" />
                        }
                        label={<Typography fontFamily={getFont(i18n)}>
                            {i18n.language == "bn-BD" ? "সবগুলো" : "All"}
                        </Typography>}
                    />

                    {
                        data?.content?.map((item, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Radio checked={state[item?.id] ? true : false} onChange={handleChange} name={item?.id?.toString()} />
                                }
                                label={<Typography fontFamily={getFont(i18n)}>
                                    {i18n.language == "bn-BD" ? item?.nameBn : item?.name}
                                </Typography>}
                            />
                        ))
                    }


                </FormGroup>
                {/* <FormHelperText>Be careful</FormHelperText> */}
            </FormControl>
        </Div>
    )
}

export default ServiceFilter;