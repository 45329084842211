import React from 'react';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import { alpha, Button, ButtonGroup, Grid, ListItem, ListItemAvatar, ListItemText, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import { getBanglaNumber } from 'app/hooks/getBanglaNumber';

const ApplicationDetail = ({ data, onClose }) => {

    const { t, i18n } = useTranslation();

    const CreateExportTable = () => {

        var css = `<style type="text/css" media="print">
            @page { margin: 20; }
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }
            
            td, th {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
            }

            th:last-child {
                width: 250px;
            }

            .css-m69qwo-MuiStack-root{
                display: flex;
                width: 100%;
            }

            .MuiTypography-body1{
                margin-left: 7px;
            }
        </style>`

        var header = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title></title>${css}</head><body>`;

        var footer = "</body></html>";

        var html = header + document.getElementById("dataPrint").innerHTML + footer;

        return html;


    }

    const exportHTMLtoPDF = () => {

        var sourceHTML = CreateExportTable();

        if (sourceHTML) {
            var win = window.open('', '', 'height=3508 ,width=2480');
            win.document.write(sourceHTML);
            win.document.close();

            win.print();

        } else {
            alert("কোনো তথ্য পাওয়া যায়নি");
        }

    }


    const exportHTMLtoExcel = () => {

        var sourceHTML = CreateExportTable();
        var tableSelect = document.getElementById("dataPrint");
        var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

        if (sourceHTML) {
            var source = `data:application/vnd.ms-excel, ` + tableHTML;
            var fileDownload = document.createElement("a");
            document.body.appendChild(fileDownload);
            fileDownload.href = source;
            fileDownload.download = `application_details.xls`;
            fileDownload.click();
            document.body.removeChild(fileDownload);
        } else {
            alert("কোনো তথ্য পাওয়া যায়নি");
        }

    }

    return (
        <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={"Application Details"}
                action={
                    <Stack direction={"row"} alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography >Export as: </Typography>
                            <Button sx={{ ml: 1 }} onClick={exportHTMLtoPDF}>
                                PDF
                            </Button>
                        </Stack>
                        <IconButton onClick={onClose}><CloseIcon /></IconButton>
                    </Stack>
                }
            />
            <Div id='dataPrint' style={{ margin: "5px 50px 50px" }}>

                <Table style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} style={{ background: "#e8e9ff", textAlign: "center" }}>
                                <Typography variant='h4'>
                                    Application for {data?.service}
                                </Typography>
                                <Typography variant='caption'>
                                    Date of application: {data?.submittedDate}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        data?.template?.map((item, i) => (
                            <TableBody key={i}>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='body1' color={"#a7a7a7"}>
                                            {item?.fieldLabel}
                                        </Typography>
                                    </TableCell>
                                    {
                                        item?.inputType == "file" ? (
                                            <Stack direction={"column"}>
                                                <a style={{ marginLeft: "auto", padding: "3px 5px" }} download={item?.fieldLabel} href={data?.value?.[item?.name]}>Download</a>
                                                <TableCell>
                                                    <object data={data?.value?.[item?.name]} width={"100%"} height={"100%"} ></object>
                                                </TableCell>
                                            </Stack>
                                        ) : (
                                            <TableCell>
                                                <Typography variant='body1' sx={{ ml: 1 }}>
                                                    {data?.value?.[item?.name]}
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableBody>
                        ))
                    }
                </Table>
            </Div >
        </Div >
    );
};

export default ApplicationDetail;
