import JumboContentLayout from "@jumbo/components/JumboContentLayout/JumboContentLayout";
import Div from "@jumbo/shared/Div/Div";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  AddCircleOutline,
  CheckBox,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { useFormik } from "formik";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useMutation, useQuery, useQueryClient } from "react-query";
import providerServices from "app/services/provider/provider.service";
import serviceServices from "app/services/service.service";
import categoryServices from "app/services/category.service";
import { parseISO } from "date-fns";
import { date } from "yup";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { stateToHTML } from "draft-js-export-html";
import publicServices from "app/services/public/public.service";
import applicationServices from "app/services/application.service";

import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import RelatedService from "./components/RelatedService";

const MySwal = withReactContent(Swal);

const NewService = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isRendered, setisRendered] = useState(false);
  const [list, setlist] = useState([]);
  const [subCategoryList, setsubCategoryList] = useState([]);
  const [selectedCategory, setselectedCategory] = useState(0);
  const [dySection, setdySection] = useState([
    {
      sectionHeaderTitle: "",
      sectionHeaderTitleBn: "",
      sectionBody: "",
      sectionBodyBn: "",
      sectionPriority: "",
      isActive: 1,
    },
  ]);
  const [locationSection, setLocationSection] = useState([
    {
      generalDivisionId: "",
      generalDistrictId: "",
      detailsAddress: "",
      detailsAddressBn: "",
      contractNo: "",
      emailAddress: "",
      googleMapUrl: "",
      isActive: 1,
    },
  ]);
  const [selectedDivision, setselectedDivision] = useState({});
  const [uploadFile, setuploadFile] = React.useState(null);
  const [open, setOpen] = useState(false);

  const { data: dataAppList } = useQuery(
    ["get-application-list-admin-service-list"],
    () => applicationServices.getApplicationList()
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      event.stopPropagation();
      return false;
    } else {
      setOpen(false);
    }
  };

  const getCategories = async () => {
    try {
      const response = await categoryServices.getCategoryList();
      setlist(response?.content);
      return response?.content;
    } catch (error) {}
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getSubcategory = (parent) => {
    setselectedCategory(parent);
    const subList = list?.filter((item) => item?.id == parent);
    if (subList?.length != 0) {
      setsubCategoryList(subList[0]?.child);
    }
  };

  const updateState = (index, field, value) => {
    const newArray = dySection.map((item, i) => {
      if (index === i) {
        return { ...item, [field]: value };
      } else {
        return item;
      }
    });
    setdySection(newArray);
    formik.setFieldValue("serviceProviderServicesDetails", newArray);
  };

  const addSection = (index) => {
    setdySection((current) => [
      ...current.slice(0, index),
      {
        sectionHeaderTitle: "",
        sectionBody: "",
        sectionHeaderTitleBn: "",
        sectionBodyBn: "",
        sectionPriority: null,
        isActive: 1,
      },
      ...current.slice(index),
    ]);
  };

  const removeSection = (index) => {
    // setdySection(current => current.filter((_, i) => i !== index));

    setdySection((prevState, i) => {
      const newState = (newState = JSON.parse(JSON.stringify(prevState)));
      newState[index].isActive = 0;
      formik.setFieldValue("serviceProviderServicesDetails", newState);
      return newState;
    });

    // setdySection((prevState, i) => {
    //     const newState = prevState.map(obj => {
    //         if (obj.serviceDetailsId == index) {
    //             return { ...obj, isActive: 0 };
    //         }
    //         return obj;
    //     });
    //     formik.setFieldValue("serviceProviderServicesDetails", newState);
    //     return newState;
    // });
  };

  const updateStateLocation = (index, field, value) => {
    const newArray = locationSection.map((item, i) => {
      if (index === i) {
        return { ...item, [field]: value };
      } else {
        return item;
      }
    });
    setLocationSection(newArray);
    formik.setFieldValue("locations", newArray);
  };

  const addSectionLocation = (index) => {
    setLocationSection((current) => [
      ...current.slice(0, index),
      {
        generalDivisionId: "",
        generalDistrictId: "",
        detailsAddress: "",
        detailsAddressBn: "",
        contractNo: "",
        emailAddress: "",
        googleMapUrl: "",
        isActive: 1,
      },
      ...current.slice(index),
    ]);
  };

  const removeSectionLocation = (index) => {
    setLocationSection((prevState, i) => {
      const newState = prevState;
      newState[index].isActive = 0;
      formik.setFieldValue("locations", newState);
      return newState;
    });

    // setLocationSection((prevState, i) => {
    //     const newState = prevState.map(obj => {
    //         if (obj.serviceLocationId == index) {
    //             return { ...obj, isActive: 0 };
    //         }
    //         return obj;
    //     });
    //     formik.setFieldValue("locations", newState);
    //     return newState;
    // });
  };

  const queryClient = useQueryClient();

  const { data: dataDist } = useQuery(["get-district", selectedDivision], () =>
    publicServices.getDistrict(selectedDivision?.id)
  );
  const { data: dataDiv } = useQuery(["get-division"], () =>
    publicServices.getDivision()
  );

  const { data: dataService, isLoading } = useQuery(
    ["get-service-details-admin", params?.id],
    () => serviceServices.getOneService(params?.id),
    {
      onSuccess: (data) => {
        formik.setValues(data?.content);
        formik.setFieldValue("name", data?.content?.service_name);
        formik.setFieldValue("nameBn", data?.content?.service_name_bn);
        formik.setFieldValue(
          "serviceCategory",
          data?.content?.sub_category_name_id
            ? data?.content?.sub_category_name_id
            : 0
        );
        // formik.setFieldValue("startDate", new Date(data?.content?.startDate));
        // formik.setFieldValue("endDate", new Date(data?.content?.endDate));

        getCategories().then((result) => {
          setselectedCategory(data?.content?.category_name_id);
          const subList = result?.filter(
            (item) => item?.id == data?.content?.category_name_id
          );
          if (subList?.length != 0) {
            setsubCategoryList(subList[0]?.child);
          }
        });

        if (data?.content?.serviceProviderServicesDetails?.length != 0) {
          // data?.content?.serviceProviderServicesDetails?.forEach((item) => {
          //     try {
          //         const htmlString = item.sectionBody;
          //         const blocksFromHTML = convertFromHTML(htmlString);
          //         const contentState = ContentState.createFromBlockArray(blocksFromHTML);

          //         item.sectionBody = EditorState.createWithContent(contentState);

          //     } catch (error) {
          //         console.log(error);
          //     }
          //     try {

          //         const htmlStringBn = item.sectionBodyBn;
          //         const blocksFromHTMLBn = convertFromHTML(htmlStringBn);
          //         const contentStateBn = ContentState.createFromBlockArray(blocksFromHTMLBn);

          //         item.sectionBodyBn = EditorState.createWithContent(contentStateBn);
          //     } catch (error) {
          //         console.log(error);
          //     }
          // })
          setdySection(data?.content?.serviceProviderServicesDetails);
        }
        if (data?.content?.locations?.length != 0) {
          // setLocationSection(data?.content?.locations?.filter((item) => item?.isActive != 0))
          setLocationSection(data?.content?.locations);
        }
      },
      enabled: !!params?.id,
    }
  );

  // const { data: dataBanner, isLoading: isLoadingBanner } = useQuery(['get-banner', dataService?.content?.imageUrl], () => serviceServices.getBannerImage(dataService?.content?.imageUrl), {
  //     enabled: !!dataService?.content?.imageUrl
  // });

  const { mutate, isLoading: isLoadingSubmit } = useMutation(
    serviceServices.createService,
    {
      onSuccess: (data) => {
        MySwal.fire({
          icon: data?.statusCode == 200 ? "success" : "error",
          showConfirmButton: false,
          html: <i>{data?.message}</i>,
          timer: 5000,
        });
        if (data?.statusCode == 200) {
          navigate(`/service-provider/service`);
        }
      },
      onError: () => {
        MySwal.fire({
          title: <strong>{"There was an error"}</strong>,
          html: <i>{"Please, contact with customer support."}</i>,
          icon: "error",
          allowOutsideClick: false,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("create");
      },
    }
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    serviceServices.updateService,
    {
      onSuccess: (data) => {
        MySwal.fire({
          icon: data?.statusCode == 200 ? "success" : "error",
          showConfirmButton: false,
          html: <i>{data?.message}</i>,
          timer: 5000,
        });

        if (data?.statusCode == 200) {
          navigate(`/service-provider/service`);
        }
      },
      onError: () => {
        MySwal.fire({
          title: <strong>{"There was an error"}</strong>,
          html: <i>{"Please, contact with customer support."}</i>,
          icon: "error",
          allowOutsideClick: false,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("create");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      serviceLanguage: "3",
      name: "",
      nameBn: "",
      serviceCategory: "",
      shortDescription: "",
      shortDescriptionBn: "",
      serviceCostingDetails: "",
      serviceCostingDetailsBn: "",
      hasServiceDuration: 0,
      startDate: "",
      endDate: "",
      applicationOption: "1",
      applicationUrl: "",
      applicationUrlButtonName: "",
      serviceTemplateId: 0,
      hasApplicationDuration: 0,
      startDateApplication: "",
      endDateApplication: "",
      isServicesDetailsCollapsible: "1",
      serviceProviderServicesDetails: [],
      locations: [],
      relatedServices: [],
    },
    onSubmit: (values) => {
      const newVal = values;
      // if (newVal.serviceProviderServicesDetails?.length != 0) {
      //     newVal.serviceProviderServicesDetails?.forEach((item) => {
      //         try {
      //             item.sectionBody = stateToHTML(item.sectionBody.getCurrentContent());
      //         } catch (error) {
      //             console.log(error);
      //         }
      //         try {
      //             item.sectionBodyBn = stateToHTML(item.sectionBodyBn.getCurrentContent());
      //         } catch (error) {
      //             console.log(error);
      //         }
      //     })
      // }

      newVal.endDate = moment(values.endDate).format("MM-DD-YYYY");
      newVal.startDate = moment(values.startDate).format("MM-DD-YYYY");
      newVal.startDateApplication = moment(values.startDateApplication).format(
        "MM-DD-YYYY"
      );
      newVal.endDateApplication = moment(values.endDateApplication).format(
        "MM-DD-YYYY"
      );
      const tempLocation = locationSection?.filter(
        (item) => item?.generalDivisionId != null || item?.isActive != 0
      );
      newVal.locations = tempLocation?.filter(
        (item) => !!item?.generalDivisionId
      );
      newVal.serviceProviderServicesDetails = dySection?.filter(
        (item) => item?.isActive != 0
      );
      if (newVal.serviceCategory == 0) {
        newVal.serviceCategory = selectedCategory;
      }
      if (!values.serviceTemplateId) {
        newVal.serviceTemplateId = 0;
      }

      console.log(newVal);
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("data", JSON.stringify(newVal));

      if (params?.id) {
        mutateUpdate({
          id: params?.id,
          data: formData,
        });
      } else {
        mutate(formData);
      }
    },
  });
  // console.log(formik.values);
  // useEffect(() => {
  //     setEditorState(EditorState.createEmpty());
  // }, []);

  return (
    <Div>
      {(params?.id && isLoading) || isLoadingSubmit || isLoadingUpdate ? (
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "100%",
            minHeight: "20vh",
          }}
        >
          <CircularProgress color={"warning"} sx={{ m: "-40px auto 0" }} />
        </Div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <JumboContentLayout
            header={
              <Grid
                container
                style={{
                  position: "fixed",
                  zIndex: 1000,
                  width: "82%",
                  background: "white",
                  marginLeft: "-50px",
                  padding: "30px 50px 0px",
                  marginTop: "-33px",
                }}
              >
                <Grid item sm={12} md={6}>
                  <PageHeader
                    title={
                      !params?.id ? "Add New Service" : "Update New Service"
                    }
                    noMargin={true}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Button
                      onClick={() => navigate(`/service-provider/service`)}
                      variant="outlined"
                      color="error"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !formik.isValid ||
                        (!params?.id && Object.keys(formik.touched).length == 0)
                      }
                      type="submit"
                      variant="contained"
                    >
                      {params?.id ? "Update" : "Save"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            }
          >
            <FormControl sx={{ mt: 7 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Service Language
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ mb: 3 }}
                {...formik.getFieldProps("serviceLanguage")}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="English"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Bangla"
                />
                <FormControlLabel value="3" control={<Radio />} label="Both" />
              </RadioGroup>
            </FormControl>

            {(formik.values.serviceLanguage == "1" ||
              formik.values.serviceLanguage == "3") && (
              <TextField
                sx={{ mb: 3 }}
                label="Service Name"
                required
                {...formik.getFieldProps("name")}
              />
            )}

            {(formik.values.serviceLanguage == "2" ||
              formik.values.serviceLanguage == "3") && (
              <TextField
                sx={{ mb: 3 }}
                label="Service Name (Bangla)"
                InputProps={{ style: { background: "#e4fde4" } }}
                required
                {...formik.getFieldProps("nameBn")}
              />
            )}

            <Grid container spacing={3}>
              <Grid item sm={12} md={6}>
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Category"
                    required
                    value={selectedCategory}
                    onChange={(e) => getSubcategory(e.target.value)}
                  >
                    <MenuItem value={0}>Select Category</MenuItem>
                    {list?.map((item, i) => (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Div sx={{ mb: 3 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formik.values.hasServiceDuration == 1 ? true : false
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            "hasServiceDuration",
                            e.target.checked ? 1 : 0
                          )
                        }
                      />
                    }
                    label="Service Duration"
                  />
                </Div>
                {formik.values.hasServiceDuration == 1 && (
                  <Div sx={{ mb: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        format="dd/MM/yyyy"
                        label={"Start Date"}
                        onChange={(e) => formik.setFieldValue("startDate", e)}
                        value={
                          formik.values.startDate
                            ? new Date(formik.values.startDate)
                            : new Date()
                        }
                      />
                    </LocalizationProvider>
                  </Div>
                )}
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel id="demo-simple-select-label">
                    Sub Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Sub Category"
                    {...formik.getFieldProps("serviceCategory")}
                    required
                  >
                    {subCategoryList?.length == 0 && (
                      <MenuItem value={0}>No Sub-category</MenuItem>
                    )}
                    {subCategoryList?.map((item, i) => (
                      <MenuItem key={i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Div sx={{ mb: 8 }}></Div>
                {formik.values.hasServiceDuration == 1 && (
                  <Div sx={{ mb: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        format="dd/MM/yyyy"
                        label={"End Date"}
                        onChange={(e) => formik.setFieldValue("endDate", e)}
                        value={
                          formik.values.endDate
                            ? new Date(formik.values.endDate)
                            : new Date()
                        }
                      />
                    </LocalizationProvider>
                  </Div>
                )}
              </Grid>
            </Grid>

            {(formik.values.serviceLanguage == "1" ||
              formik.values.serviceLanguage == "3") && (
              <TextField
                sx={{ mb: 3 }}
                label="Short Description"
                required
                multiline
                rows={5}
                {...formik.getFieldProps("shortDescription")}
              />
            )}

            {(formik.values.serviceLanguage == "2" ||
              formik.values.serviceLanguage == "3") && (
              <TextField
                sx={{ mb: 3 }}
                label="Short Description (Bangla)"
                required
                multiline
                rows={5}
                InputProps={{ style: { background: "#e4fde4" } }}
                {...formik.getFieldProps("shortDescriptionBn")}
              />
            )}
            {(formik.values.serviceLanguage == "1" ||
              formik.values.serviceLanguage == "3") && (
              <TextField
                sx={{ mb: 3 }}
                label="service Costing Details"
                required
                {...formik.getFieldProps("serviceCostingDetails")}
              />
            )}

            {(formik.values.serviceLanguage == "2" ||
              formik.values.serviceLanguage == "3") && (
              <TextField
                sx={{ mb: 3 }}
                label="service Costing Details (Bangla)"
                required
                InputProps={{ style: { background: "#e4fde4" } }}
                {...formik.getFieldProps("serviceCostingDetailsBn")}
              />
            )}

            <Typography sx={{ mb: 1 }}>Service Banner</Typography>

            <TextField
              fullWidth
              type="file"
              value={uploadFile?.filename}
              onChange={(e) => setuploadFile(e.target.files[0])}
            />
            <Typography sx={{ mb: 3 }} variant="caption" color={"#C9C9C9"}>
              Image should be at 1920x580px in resolution and JPG, JPEG, PNG in
              format.
            </Typography>
            {uploadFile ? (
              <img
                src={URL.createObjectURL(uploadFile)}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "6px",
                }}
              />
            ) : (
              <img
                src={
                  dataService?.content?.imageServer
                    ? dataService?.content?.imageServer
                    : `${window.location.origin}/images/banner/noBanner.png`
                }
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "6px",
                }}
              />
            )}

            {uploadFile && (
              <Button
                variant="text"
                sx={{ mb: 2 }}
                onClick={() => setuploadFile(null)}
              >
                Remove this image
              </Button>
            )}

            <FormControl sx={{ mt: 3, mb: 3 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Application Form
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                {...formik.getFieldProps("applicationOption")}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="No form"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Application form"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="Application URL"
                />
              </RadioGroup>
            </FormControl>

            {formik.values.applicationOption == "2" && (
              <TextField
                fullWidth
                sx={{ mb: 3 }}
                select
                required
                label="Application Form"
                {...formik.getFieldProps("serviceTemplateId")}
              >
                <MenuItem value={0}>Select application form</MenuItem>
                {dataAppList?.content?.map((item, i) => (
                  <MenuItem key={i} value={item?.id}>
                    {item?.templateName}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {formik.values.applicationOption == "3" && (
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    sx={{ mb: 3 }}
                    label="URL"
                    required
                    {...formik.getFieldProps("applicationUrl")}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Button Name"
                    required
                    {...formik.getFieldProps("applicationUrlButtonName")}
                  />
                </Grid>
              </Grid>
            )}

            {formik.values.applicationOption != "1" && (
              <>
                <Div sx={{ mb: 3 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formik.values.hasApplicationDuration == 1
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            "hasApplicationDuration",
                            e.target.checked ? 1 : 0
                          )
                        }
                      />
                    }
                    label="Application Duration"
                  />
                </Div>
                {formik.values.hasApplicationDuration == "1" && (
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <Div sx={{ mb: 3 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            format="dd/MM/yyyy"
                            label={"Start Date"}
                            onChange={(e) =>
                              formik.setFieldValue("startDateApplication", e)
                            }
                            value={
                              formik.values.startDateApplication
                                ? new Date(formik.values.startDateApplication)
                                : new Date()
                            }
                          />
                        </LocalizationProvider>
                      </Div>
                    </Grid>
                    <Grid item sm={6}>
                      <Div sx={{ mb: 3 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            format="dd/MM/yyyy"
                            label={"End Date"}
                            onChange={(e) =>
                              formik.setFieldValue("endDateApplication", e)
                            }
                            value={
                              formik.values.endDateApplication
                                ? new Date(formik.values.endDateApplication)
                                : new Date()
                            }
                          />
                        </LocalizationProvider>
                      </Div>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <Button variant={"outlined"} onClick={handleOpen}>
              Related Services
            </Button>

            <Typography variant="h5" sx={{ mb: 3, mt: 3 }}>
              Dynamic Section
            </Typography>

            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label-dy">
                Dynamic Section Style
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label-dy"
                sx={{ mb: 3 }}
                {...formik.getFieldProps("isServicesDetailsCollapsible")}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Non-collapsible"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Collapsible"
                />
              </RadioGroup>
            </FormControl>

            {dySection?.map(
              (item, i) =>
                item?.isActive == 1 && (
                  <Div
                    key={i}
                    sx={{
                      mb: 3,
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      padding: "20px",
                    }}
                  >
                    {(formik.values.serviceLanguage == "1" ||
                      formik.values.serviceLanguage == "3") && (
                      <TextField
                        fullWidth
                        label={"Section Title"}
                        sx={{ mb: 3 }}
                        value={item.sectionHeaderTitle}
                        required={
                          formik.values.isServicesDetailsCollapsible == "1"
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          updateState(i, "sectionHeaderTitle", e.target.value)
                        }
                      />
                    )}
                    {(formik.values.serviceLanguage == "2" ||
                      formik.values.serviceLanguage == "3") && (
                      <TextField
                        fullWidth
                        label={"Section Title (Bangla)"}
                        sx={{ mb: 3 }}
                        value={item.sectionHeaderTitleBn}
                        required={
                          formik.values.isServicesDetailsCollapsible == "1"
                            ? true
                            : false
                        }
                        InputProps={{ style: { background: "#e4fde4" } }}
                        onChange={(e) =>
                          updateState(i, "sectionHeaderTitleBn", e.target.value)
                        }
                      />
                    )}
                    <TextField
                      fullWidth
                      type="number"
                      label={"Section Priority"}
                      required
                      sx={{ mb: 3 }}
                      value={item.sectionPriority}
                      onChange={(e) =>
                        updateState(
                          i,
                          "sectionPriority",
                          parseInt(e.target.value)
                        )
                      }
                    />
                    {(formik.values.serviceLanguage == "1" ||
                      formik.values.serviceLanguage == "3") && (
                      <Div
                        sx={{
                          border: "1px solid lightgray",
                          borderRadius: "6px",
                        }}
                      >
                        <Typography sx={{ p: 1 }}>Section Body</Typography>
                        {/* <Editor
                                                        editorStyle={{
                                                            width: '100%',
                                                            minHeight: 100,
                                                            maxHeight: 300,
                                                            borderWidth: 1,
                                                            borderStyle: 'solid',
                                                            borderColor: 'lightgray'
                                                        }}
                                                        editorState={item?.sectionBody}
                                                        onEditorStateChange={editorState => {
                                                            // updateState(i, "sectionBody", draftToHtml(convertToRaw(editorState.getCurrentContent())))
                                                            updateState(i, "sectionBody", editorState)

                                                        }}
                                                    /> */}
                        <CKEditor
                          key={`${i}en`}
                          editor={Editor}
                          data={item?.sectionBody}
                          onReady={(editor) => {
                            console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            updateState(i, "sectionBody", data);
                          }}
                        />
                      </Div>
                    )}
                    {(formik.values.serviceLanguage == "2" ||
                      formik.values.serviceLanguage == "3") && (
                      <Div
                        className="bodyBn"
                        sx={{
                          border: "1px solid lightgray",
                          borderRadius: "6px",
                          mt: 3,
                        }}
                      >
                        <Typography sx={{ p: 1 }}>
                          Section Body (Bangla)
                        </Typography>
                        {/* <Editor
                                                        editorStyle={{
                                                            width: '100%',
                                                            minHeight: 100,
                                                            maxHeight: 300,
                                                            borderWidth: 1,
                                                            borderStyle: 'solid',
                                                            borderColor: 'lightgray',
                                                            background: "#e4fde4"
                                                        }}
                                                        editorState={item?.sectionBodyBn}
                                                        onEditorStateChange={editorState => {
                                                            // updateState(i, "sectionBody", draftToHtml(convertToRaw(editorState.getCurrentContent())))
                                                            updateState(i, "sectionBodyBn", editorState)

                                                        }}
                                                    /> */}
                        <CKEditor
                          key={`${i}bn`}
                          editor={Editor}
                          data={item?.sectionBodyBn}
                          onReady={(editor) => {
                            console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            updateState(i, "sectionBodyBn", data);
                          }}
                        />
                      </Div>
                    )}
                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Button
                        sx={{ mr: 2 }}
                        variant="contained"
                        color="error"
                        startIcon={<RemoveCircleOutline />}
                        disabled={
                          dySection?.filter((item) => item?.isActive != 0)
                            ?.length == 1
                            ? true
                            : false
                        }
                        onClick={() => removeSection(i)}
                      >
                        Remove
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<AddCircleOutline />}
                        onClick={() => addSection(i + 1)}
                      >
                        Add
                      </Button>
                    </Div>
                  </Div>
                )
            )}

            <Typography variant="h5" sx={{ mb: 3 }}>
              Location Section
            </Typography>

            {locationSection?.map(
              (item, i) =>
                item?.isActive == 1 && (
                  <Div
                    key={i}
                    sx={{
                      mb: 3,
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      padding: "20px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                          <TextField
                            select
                            component="li"
                            fullWidth
                            label="Division"
                            value={item.generalDivisionId}
                            onChange={(e) =>
                              updateStateLocation(
                                i,
                                "generalDivisionId",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value={""}>Select division</MenuItem>
                            {dataDiv?.content?.map((item, i) => (
                              <MenuItem
                                key={i}
                                onClick={() => setselectedDivision(item)}
                                value={item?.id}
                              >
                                {item?.NAME}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Div>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                          <TextField
                            select
                            component="li"
                            fullWidth
                            label="District"
                            value={item.generalDistrictId}
                            onChange={(e) =>
                              updateStateLocation(
                                i,
                                "generalDistrictId",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value={""}>Select district</MenuItem>
                            {dataDist?.content?.map((item, i) => (
                              <MenuItem key={i} value={item?.id}>
                                {item?.NAME}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      {(formik.values.serviceLanguage == "1" ||
                        formik.values.serviceLanguage == "3") && (
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label={"Address"}
                            sx={{ mb: 3 }}
                            value={item.detailsAddress}
                            onChange={(e) =>
                              updateStateLocation(
                                i,
                                "detailsAddress",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                      )}
                      {(formik.values.serviceLanguage == "2" ||
                        formik.values.serviceLanguage == "3") && (
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label={"Address (Bangla)"}
                            sx={{ mb: 3 }}
                            value={item.detailsAddressBn}
                            InputProps={{ style: { background: "#e4fde4" } }}
                            onChange={(e) =>
                              updateStateLocation(
                                i,
                                "detailsAddressBn",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                      )}
                    </Grid>

                    <TextField
                      fullWidth
                      label={"Google Map URL"}
                      sx={{ mb: 3 }}
                      value={item.googleMapUrl}
                      onChange={(e) =>
                        updateStateLocation(i, "googleMapUrl", e.target.value)
                      }
                    />

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label={"Contract Number"}
                          sx={{ mb: 3 }}
                          value={item.contractNo}
                          onChange={(e) =>
                            updateStateLocation(i, "contractNo", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label={"Email Address"}
                          sx={{ mb: 3 }}
                          value={item.emailAddress}
                          onChange={(e) =>
                            updateStateLocation(
                              i,
                              "emailAddress",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>

                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Button
                        sx={{ mr: 2 }}
                        variant="contained"
                        color="error"
                        startIcon={<RemoveCircleOutline />}
                        disabled={
                          locationSection?.filter((item) => item?.isActive != 0)
                            ?.length == 1
                            ? true
                            : false
                        }
                        onClick={() => removeSectionLocation(i)}
                      >
                        Remove
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<AddCircleOutline />}
                        onClick={() => addSectionLocation(i + 1)}
                      >
                        Add
                      </Button>
                    </Div>
                  </Div>
                )
            )}
          </JumboContentLayout>
        </form>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
      >
        <RelatedService handleClose={handleClose} formik={formik} />
      </Modal>
    </Div>
  );
};

export default NewService;
