import Div from '@jumbo/shared/Div';
import { ArrowForwardIos, HelpCenterOutlined } from '@mui/icons-material';
import LocationOn from '@mui/icons-material/LocationOn';
import { Button, Chip, Grid, Link, Skeleton, Stack, Typography } from '@mui/material';
import { ACCESS_TOKEN } from 'app/config/localStorageConfig';
import { getFont } from 'app/hooks/getFont';
import publicServices from 'app/services/public/public.service';
import ProviderInfo from 'app/shared/ProviderInfo';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const cardStyle = {
    background: "#FFFFFF",
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "2px",
    padding: "15px"
}

const ServiceCard = ({ data, isLoading }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    // const { data: dataLogo, isLoading: isLoadingLogo } = useQuery(["get-provider-logo-image-list", data?.providerInfoLogoImageUrl], () => publicServices.getImage(data?.providerInfoLogoImageUrl), {
    //     enabled: !!data?.providerInfoLogoImageUrl
    // });

    const ViewDetails = () => {
        const hasToken = localStorage.getItem(ACCESS_TOKEN);
        navigate(`/service/details/${data?.service_id}`);
        // if (hasToken) {
        //     navigate(`/service/details/${data?.service_id}`);
        // } else {
        //     MySwal.fire({
        //         icon: 'warning',
        //         title: <strong>{i18n.language == "bn-BD" ? "সেবার বিস্তারিত দেখতে আপনাকে আপনার অ্যাকাউন্টে লগ ইন করতে হবে।" : "You have to log in to your account to view service details."}</strong>,
        //         showCancelButton: true,
        //         confirmButtonText: 'Login',
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             navigate(`/auth/login`);
        //         }
        //     })
        // }
    }

    return isLoading ? (
        <Div style={cardStyle}>
            <Div sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", mb: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <Skeleton variant="rounded" width={60} height={60} />
                    </Grid>
                    <Grid item xs={3} sx={{ ml: 1 }}>
                        <Skeleton variant="text" sx={{ fontSize: '1.3rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '0.75rem' }} />
                    </Grid>
                </Grid>
            </Div>
            <Typography fontFamily={getFont(i18n)} variant='h6' >
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </Typography>
            <Skeleton variant="text" sx={{ fontSize: '0.75rem' }} />
            <Typography fontFamily={getFont(i18n)} variant='body1' color={"#999999"}>
                <Skeleton variant="text" sx={{ fontSize: '0.75rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '0.75rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '0.75rem' }} />
            </Typography>
            <Div sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexWrap: "wrap", mt: 1 }} >
                <Skeleton variant="rounded" width={130} height={40} />
            </Div>
        </Div >
    ) : i18n.language == "bn-BD" && !data?.service_provider_services_name_bn ? "" : (
        <Div style={cardStyle}>
            <Div sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", mb: 1 }}>
                <ProviderInfo
                    id={data?.provider_profile_id}
                    name={i18n.language == "bn-BD" ? data?.service_provider_info_name_bn : data?.service_provider_info_name}
                    website={data?.url}
                    image={data?.providerInfoLogoImageServer ? data?.providerInfoLogoImageServer : `${window.location.origin}/images/logo/noLogo.png`}
                />
                <Stack direction={"row"} alignItems={"center"} height={"100%"} >
                    <Chip label={<Typography fontFamily={getFont(i18n)}>
                        {i18n.language == "bn-BD" ? data?.category_name_bn : data?.category_name}
                    </Typography>} variant="outlined" size={"small"} />
                    {
                        data?.sub_category_name && (
                            <>
                                <ArrowForwardIos fontSize='16px' />
                                <Chip label={<Typography fontFamily={getFont(i18n)}>
                                    {i18n.language == "bn-BD" ? data?.sub_category_name_bn : data?.sub_category_name}
                                </Typography>} variant="outlined" size={"small"} />
                            </>
                        )
                    }
                </Stack>
            </Div>
            <Typography fontFamily={getFont(i18n)} variant='h6' >
                {i18n.language == "bn-BD" ? data?.service_provider_services_name_bn : data?.service_provider_services_name}
            </Typography>
            {
                data?.service_costing_details && (
                    <Stack direction={"row"} spacing={1} my={1} >
                        <img src={`${window.location.origin}/images/icons/taka.png`} height={"18px"} />
                        <Typography fontFamily={getFont(i18n)} fontSize={"12px"} color={"#0C4CA3"} >
                            {i18n.language == "bn-BD" ? data?.service_costing_details_bn : data?.service_costing_details}
                        </Typography>
                    </Stack>
                )
            }
            <Typography fontFamily={getFont(i18n)} variant='body1' color={"#999999"}>
                {i18n.language == "bn-BD" ? data?.short_description_bn : data?.short_description}
            </Typography>
            <Div sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", mt: 1 }} >
                {
                    data?.service_location?.length != 0 && (
                        <LocationOn sx={{ mr: 1 }} />
                    )
                }
                {
                    data?.service_location?.map((item, i) => (
                        <Chip key={i} label={<Typography fontFamily={getFont(i18n)}>
                            {i18n.language == "bn-BD" ? item?.general_district_bn : item?.general_district}
                        </Typography>} variant="outlined" size={"small"} sx={{ mr: 1 }} />
                    ))
                }
                <Button onClick={ViewDetails} variant="contained" sx={{ ml: "auto", mt: 1, fontFamily: getFont(i18n) }} >
                    {i18n.language == "bn-BD" ? "বিস্তারিত দেখুন" : "View Details"}
                </Button>
            </Div>
        </Div >
    )
}

export default ServiceCard;