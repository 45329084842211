import React from 'react';
import { Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { VisibilityOff } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import Header from './Header';
import { useJumboTheme } from '@jumbo/hooks';
import { useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import returneeServices from 'app/services/returnee.service';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import * as yup from "yup";
import { USER_ID } from 'app/config/localStorageConfig';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const userId = localStorage.getItem(USER_ID);

const MySwal = withReactContent(Swal)

const validationSchema = yup.object({
    passwordNew: yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .required('Password is required'),
    passwordCon: yup.string()
        .oneOf([yup.ref('passwordNew'), null], 'Passwords must match')
});

const ChangePassword = () => {

    const { t, i18n } = useTranslation();
    const { theme } = useJumboTheme();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [showPasswordOld, setShowPasswordOld] = React.useState(false);
    const [showPasswordNew, setShowPasswordNew] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

    const handleClickShowPassword = () => setShowPasswordOld((show) => !show);
    const handleClickShowPasswordNew = () => setShowPasswordNew((show) => !show);
    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { data, isLoading } = useQuery(['get-returnee-profile'], () => returneeServices.getReturneeDetails(userId))

    const { mutate } = useMutation(returneeServices.getReturneeChangePassword, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
            if (data?.statusCode == 200) {
                navigate(`/user/profile`)
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            passwordOld: "",
            passwordNew: "",
            passwordCon: ""
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log(values);
            delete values.passwordCon
            mutate(values)
        }
    })

    return (
        <JumboContentLayout
            header={<Header data={data?.content} isEdit={true} />}
            layoutOptions={{
                header: {
                    sx: {
                        mt: -4,
                        mb: -7.25,
                        mx: { xs: -4, lg: -6 },
                        p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                        //background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/profile-bg.jpg`,"1920x580")}) no-repeat center`,
                        // backgroundSize: 'cover',
                        backgroundImage: "linear-gradient(to right, #F53803, #F5D020)",
                        color: 'common.white',
                        position: 'relative',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            // backgroundColor: alpha(theme.palette.common.black, .65)
                        }
                    }
                },
                sidebar: {
                    sx: {
                        mr: 3.75,
                        width: { xs: '100%', lg: '33%' },
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0,
                            mr: 0,
                            order: 2
                        }
                    }
                },
                wrapper: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column'
                        }
                    }
                },
                main: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0
                        }
                    }
                }
            }}
        >
            <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto' }}>
                <Card
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        flexDirection: { xs: 'column', md: 'row' }
                    }}
                >
                    <CardContent sx={{ flex: 1, p: 4 }}>
                        <Typography fontFamily={getFont(i18n)} fontSize={20} variant={'body1'} color={'inherit'} mt={.5} mb={2}>{t("change-pass")}</Typography>

                        <Stack direction={"column"} sx={{ mb: 2, ml: 3 }} >
                            <Typography fontSize={16} variant={'caption'} color={'primary'}>Password Validation</Typography>
                            <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>1. Should be minimum 8 letters.</Typography>
                            <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>2. Require atleast a number.</Typography>
                            <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>3. Require atleast a lowercase letter.</Typography>
                            <Typography fontSize={13} variant={'caption'} color={'#A7A7A7'}>4. Require atleast an uppercase letter.</Typography>
                        </Stack>

                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                flex: 1,
                                flexDirection: 'column',
                                height: '100%'
                            }}
                        >
                            <form onSubmit={formik.handleSubmit} >
                                <Div sx={{ mb: 2 }}>
                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                        <InputLabel sx={{ fontFamily: getFont(i18n) }} htmlFor="outlined-adornment-password">{t("placeholder.change-password.old")}</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="outlined-adornment-password"
                                            type={showPasswordOld ? 'text' : 'password'}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={t("placeholder.change-password.old")}
                                            {...formik.getFieldProps('passwordOld')}
                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                        <InputLabel sx={{ fontFamily: getFont(i18n) }} htmlFor="outlined-adornment-password">{t("placeholder.change-password.new")}</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="outlined-adornment-password"
                                            type={showPasswordNew ? 'text' : 'password'}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPasswordNew}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={t("placeholder.change-password.new")}
                                            error={formik.touched.passwordNew && formik.errors.passwordNew ? true : false}
                                            helperText={formik.errors.passwordNew}
                                            {...formik.getFieldProps('passwordNew')}
                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                        <InputLabel sx={{ fontFamily: getFont(i18n) }} htmlFor="outlined-adornment-password">{t("placeholder.change-password.confirm")}</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="outlined-adornment-password"
                                            type={showPasswordConfirm ? 'text' : 'password'}
                                            inputProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontFamily: getFont(i18n)
                                                }
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPasswordConfirm}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={t("placeholder.change-password.confirm")}
                                            error={formik.touched.passwordCon && formik.errors.passwordCon ? true : false}
                                            helperText={formik.errors.passwordCon}
                                            {...formik.getFieldProps('passwordCon')}
                                        />
                                    </FormControl>
                                    <Button sx={{ m: 1, fontFamily: getFont(i18n) }} type='submit' variant="contained">{t("change-pass")}</Button>
                                </Div>
                            </form>
                        </Div>
                    </CardContent>
                </Card>

            </Div>
        </JumboContentLayout>
    );
};

export default ChangePassword;
