import Div from '@jumbo/shared/Div';
import { Grid, Typography, Link, Skeleton, useMediaQuery } from '@mui/material';
import { CATEGORY, LOCATION, L_TEXT, PROVIDER, SEARCH_TEXT } from 'app/config/localStorageConfig';
import { getBanglaNumber } from 'app/hooks/getBanglaNumber';
import { getFont } from 'app/hooks/getFont';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const ServiceCard = ({ data, isLoading, lng }) => {

    const [isHover, setIsHover] = useState(false);
    const isMobile = useMediaQuery('(max-width:500px)');

    const cardStyle = {
        background: "#FFFFFF",
        boxShadow: isHover ? "0px 8px 20px rgb(0 73 203 / 12%)" : "0px 0px 2px rgba(0, 0, 0, 0.25)",
        border: isHover ? "1px solid rgba(0, 0, 0, 0.15)" : "1px solid white",
        borderRadius: "10px",
        padding: isMobile ? "10px" : "15px",
        height: "100%"
    }

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return isLoading ? (
        <Div style={cardStyle}>
            <Grid container sx={{ alignItems: "center" }}>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                    <Skeleton variant="circular" width={25} height={25} />
                </Grid>
                <Grid item xs={9} >
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.875rem' }} />
                </Grid>
            </Grid>
        </Div>
    ) : (
        <Link to={`/service-list`} onClick={() => {
            localStorage.setItem(CATEGORY, data?.c_id);
            localStorage.setItem(PROVIDER, 0);
            localStorage.setItem(LOCATION, 0);
            localStorage.setItem(SEARCH_TEXT, "");
            localStorage.setItem(L_TEXT, "");
        }} component={RouterLink} underline="none"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Div style={cardStyle}>
                <Grid container spacing={isMobile ? 4 : 2} alignItems={"flex-start"} justifyContent={"flex-start"} >
                    <Grid item xs={3} sx={{ textAlign: "center" }}>
                        <img src={`${window.location.origin}/images/category/${data?.c_id}.png`} height={isMobile ? "20px" : "25px"} />
                    </Grid>
                    <Grid item xs={9} >
                        <Typography fontFamily={getFont(lng)} variant={isMobile ? 'body2' : 'h5'} color={"#0C4CA3"} style={{ wordBreak: "break-word" }} >
                            {lng.language == "bn-BD" ? data?.nameBn : data?.name}
                        </Typography>
                        {lng.language == "bn-BD" ? (
                            <Typography fontFamily={getFont(lng)} variant='h6' color={"#3F3F46"} lineHeight={"10px"} >
                                {getBanglaNumber(data?.count_id)} {data?.count_id > 1 ? ` টি সেবা` : ` টি সেবা`}
                            </Typography>
                        ) : (
                            <Typography variant='h6' color={"#3F3F46"} lineHeight={"10px"} >
                                {data?.count_id} {data?.count_id > 1 ? ` services` : ` service`}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Div>
        </Link>
    )
}

export default ServiceCard;