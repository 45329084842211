import React from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { routesForAuthenticatedOnly, routesForNotAuthenticatedOnly, routesForPublic } from "../../../app/routes";
import useRoutePathMatch from "@jumbo/hooks/useRoutePathMatch";
import { removeToken, storeToken } from "./authHelpers";
import { config } from "../../../app/config/main";
import { AuthContext } from "@jumbo/components/JumboAuthProvider/JumboAuthContext";
import { ACCESS_TOKEN, AUTHORITY } from 'app/config/localStorageConfig';

import { matchRoutes, useLocation } from "react-router-dom";
import { buildRoutes } from "@jumbo/utils";


const storedToken = localStorage.getItem(ACCESS_TOKEN);
let firstTimePageLoad = true;

const init = () => {
    let authUser = null;

    if (!config?.authSetting) {
        throw Error(`You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`);
    }

    if (storedToken) {
        storeToken(storedToken); // also sets axios header
    }

    return {
        authToken: storedToken ?? null,
        authUser: authUser,
        isLoading: false,
        fallbackPath: config.authSetting.fallbackPath,
    }
};

const authReducer = (state, action) => {
    switch (action.type) {
        case "set-auth-data":
            return {
                ...state,
                ...action.payload,
            };

        case "start-loading":
            return {
                ...state,
                isLoading: true,
            };

        case "stop-loading":
            return {
                ...state,
                isLoading: false,
            };
    }
};

const routePathMatch = (routes, searchPath) => {

    if (Array.isArray(routes) && routes.length > 0 && searchPath) {
        const generatedRoutes = buildRoutes(routes);
        const matchedRoutes = matchRoutes(generatedRoutes, searchPath);
        return matchedRoutes && matchedRoutes.length > 0;
    }

    return false;
};


const JumboAuthProvider = ({ children, ...restProps }) => {
    const [authOptions, setAuthOptions] = React.useReducer(authReducer, restProps, init);
    const [logout, setLogout] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (localStorage.getItem(ACCESS_TOKEN)) {
            setLogout(false);
        } else {
            if (logout) {
                removeToken();
                setAuthOptions({
                    type: "set-auth-data",
                    payload: { authToken: null, authUser: null, isLoading: false }
                });
                setLogout(false);
            }
        }
    }, [logout]);

    const setAuthToken = React.useCallback(async (token) => {
        setAuthOptions({ type: "start-loading" });
        if (!token) {
            setLogout(true);
            removeToken();
            return;
        }

        storeToken(token);
        try {

            // const authUser = await config?.authSetting?.getAuthUserService();
            const authUser = "Saidur Rahman";
            if (authUser) {
                setAuthOptions({
                    type: "set-auth-data",
                    payload: { authToken: token, authUser: authUser, isLoading: false }
                });
                return;
            }
            setLogout(true);

        } catch (error) {
            setLogout(true);
            console.error(error);
        }
    }, []);

    //todo: maybe in next version
    const setRedirectPath = React.useCallback((redirectPath) => {
        setAuthOptions({ type: "set-redirect-path", payload: { redirectPath } });
    }, []);

    const setAuthData = React.useCallback((data) => {
        setAuthOptions({ type: "set-auth-data", payload: data });
    }, []);

    const contextValue = React.useMemo(() => {

        return {
            ...authOptions,
            setAuthData,
            setRedirectPath,
            setAuthToken,
            setAuthOptions,
        }
    }, [authOptions]);

    React.useEffect(() => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        const role = localStorage.getItem(AUTHORITY);

        const isAuthenticatedRouteOnly = routePathMatch(routesForAuthenticatedOnly, location.pathname);
        const isNotAuthenticatedRouteOnly = routePathMatch(routesForNotAuthenticatedOnly, location.pathname);

        if (!token && !role) {
            if (!isAuthenticatedRouteOnly) {
                <Outlet />
            } else {
                navigate("/auth/login", { state: isAuthenticatedRouteOnly ? "You are not signed in. Please, sign in." : null });
            }
        } else {
            if (!isNotAuthenticatedRouteOnly) {
                <Outlet />
            } else {
                navigate("/user/profile");
            }
        }
    }, []);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default JumboAuthProvider;