import VerticalDefault from "../../layouts/vertical-default/VerticalDefault";
import SoloPage from "../../layouts/solo-page/SoloPage";
import PublicPage from "app/layouts/public-page/PublicPage";

const LAYOUT_NAMES = {
    VERTICAL_DEFAULT: "vertical-default",
    SOLO_PAGE: "solo-page",
    PUBLIC_PAGE: "public-page",
};

const LAYOUTS = [
    {
        name: LAYOUT_NAMES.VERTICAL_DEFAULT,
        label: "Vertical Default",
        component: VerticalDefault,
    },
    {
        name: LAYOUT_NAMES.SOLO_PAGE,
        label: "Solo Page",
        component: SoloPage
    },
    {
        name: LAYOUT_NAMES.PUBLIC_PAGE,
        label: "Public Page",
        component: PublicPage,
    }
];

export { LAYOUTS, LAYOUT_NAMES };