import Div from '@jumbo/shared/Div';
import Span from '@jumbo/shared/Span';
import { Button, Container, Grid, Grow, Slide, Typography, useMediaQuery } from '@mui/material';
import SearchBox from 'app/shared/SearchBox';
import React, { useState, useEffect } from 'react';
import NewsSection from './components/NewsSection';
import OrganisationList from './components/OrganisationList';
import ServiceCategory from './components/ServiceCategory';
import { useNavigate } from 'react-router-dom';
import { useQueries } from 'react-query';
import publicServices from 'app/services/public/public.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import Caricuture from 'app/shared/Caricuture/Caricuture';

const bgStyle = {
    background: `url("./images/bg-banner.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop: "-80px",
    width: "100%"
}

const bgCategoryStyle = {
    // background: `url("./images/bg-category.png")`,
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    padding: "0vw 8vw 2vw 8vw",
    width: "100%",
}

const bgServiceStyle = {
    background: `url("./images/bg-2.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // padding: "0vw 8vw",
    width: "100%",
}

const bgBlogStyle = {
    background: `url("./images/bg-blog.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "2vw 0",
    width: "100%"
}


const Home = () => {
    const isMobile = useMediaQuery('(max-width:575px)');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [categoryList, setcategoryList] = useState();

    const results = useQueries([
        {
            queryKey: ["get-category-home"],
            queryFn: () => publicServices.getCategoryListHome(),
            onSuccess: (data) => setcategoryList(data?.content)
        }
    ]);

    const isLoading = results.some(result => result.isLoading)

    const CaricutureData = [
        {
            title: "আপনি যদি সবগুলো সেবা দেখতে চান?",
            titleLink: "তাহলে এখানে ক্লিক করুন।",
            link: "/service-list"
        },
        {
            title: "আপনি যদি সবগুলো সেবা প্রদানকারী প্রতিষ্ঠান দেখতে চান?",
            titleLink: "তাহলে এখানে ক্লিক করুন।",
            link: "/serviceProvider/list"
        },
    ];

    return (
        <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
            <Div sx={{ background: "#FFFFFF" }}>
                <Div style={bgStyle}>
                    <Container maxWidth="xl">
                        <Grid container spacing={3.75} sx={{ alignContent: "center" }}>
                            <Grid item xs={12} md={12} lg={6}>
                                <Div sx={{ margin: isMobile ? "calc(100px + 4vw) 0 8vw 0" : "calc(100px + 4vw) 0 8vw 0" }}>
                                    <Slide in={true} appear={true} direction={"right"} timeout={{ enter: 1000 }}>
                                        <Div>
                                            {
                                                i18n.language == "bn-BD" ? (
                                                    <>
                                                        <Typography fontFamily={"'Baloo Da 2',cursive"} fontSize={"34px"} fontWeight={600} color={"#0C4CA3"} lineHeight={"40px"}>
                                                            <Span className='heading2'>সঠিক তথ্য পেতে</Span> সবচেয়ে <Span className='heading1'>সহজ</Span> উপায়
                                                        </Typography>
                                                        {/* <Typography fontFamily={"'Baloo Da 2',cursive"} fontSize={"34px"} fontWeight={600} color={"#0C4CA3"} lineHeight={"40px"}>
                                                         
                                                    </Typography> */}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography fontSize={"34px"} fontWeight={600} color={"#0C4CA3"} letterSpacing={"-1px"} lineHeight={"35px"}>
                                                            The <Span className='heading1'>Easiest</Span> Way
                                                        </Typography>
                                                        <Typography className='heading2' fontSize={"34px"} fontWeight={600} letterSpacing={"-1px"} lineHeight={"35px"}>
                                                            to Get Your Right Information
                                                        </Typography>
                                                    </>
                                                )
                                            }

                                            <Typography variant='h5' mt={2} sx={{ fontFamily: getFont(i18n), lineHeight: "20px" }}>
                                                <strong>{t("title")}</strong>{t("home.description")}
                                            </Typography>
                                        </Div>
                                    </Slide>
                                    <Grow in={true} appear={true} timeout={{ enter: 1000 }}>
                                        <Div sx={{ mt: 5 }}>
                                            <SearchBox />
                                        </Div>
                                    </Grow>

                                </Div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <Div sx={{ margin: "8vw" }}>
                                    <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={{ enter: 1000 }}>
                                        <img src={"./images/home-banner.png"} height={isMobile ? "250px" : "350px"} />
                                    </Grow>
                                </Div>
                            </Grid>
                        </Grid>
                    </Container>
                </Div>

                <Container maxWidth="xl">
                    <ServiceCategory list={categoryList} isLoading={isLoading} />
                </Container>

                <Div style={bgServiceStyle}>
                    <Container maxWidth="xl">
                        <OrganisationList categoryList={categoryList} length={10} />
                        <Button onClick={() => navigate(`/serviceProvider/list`)} size={"medium"} variant="contained" sx={{ display: "flex", mx: "auto", mt: 3, mb: 7, borderRadius: "20px", fontFamily: getFont(i18n) }}>
                            {t("button.view-all")}
                        </Button>
                    </Container>
                </Div>

                <Div style={bgBlogStyle}>
                    <Container maxWidth="xl">
                        <NewsSection />
                    </Container>
                </Div>

                <Caricuture data={CaricutureData} />

            </Div>
        </Grow>
    );
};

export default Home;