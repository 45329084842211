import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useMutation, useQuery } from "react-query";
import { mailServices } from "../../../../../services/mail-services";
import ConversationDetail from "./ConversationDetail";
import IconButton from "@mui/material/IconButton";
import LabelsSelectControl from "../LabelsSelectControl";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import moment from 'moment';
import Chip from "@mui/material/Chip";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useMailsApp from "../../hooks/useMailsApp";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Span from "@jumbo/shared/Span";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useSnackbar } from "notistack";
import { ReplyOutlined } from '@mui/icons-material';
import ComposeForm from '../ComposeForm';
import messageServices from 'app/services/message.service';

const MailDetail = ({ mailID }) => {
    const { hideDialog, showDialog } = useJumboDialog();
    const { category, id } = useParams();
    const navigate = useNavigate();

    const [isUpdated, setisUpdated] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const {
        data,
        isLoading,
        isError,
        error
    } = useQuery(["content-details", mailID, isUpdated], () => messageServices.getOneMessage({ type: category == "inbox" ? "IN_BOX" : "SEND_BOX", id: mailID }));

    if (isLoading) {
        return (
            <Div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: theme => theme.spacing(3),
                    m: 'auto',
                }}
            >
                <CircularProgress />
                <Typography variant={'h6'} color={'text.secondary'} mt={2}>Loading...</Typography>
            </Div>
        )
    }
    if (isError) {
        return error.message;
    }
    const handleNavigateItem = () => {
        if (category || id)
            return navigate(`/app/${category}`)
        return navigate(`/app/inbox`)
    }

    const handleComposeForm = () => {
        showDialog({
            title: "Compose Message",
            content: <ComposeForm mailItem={data?.content} />
        })
    };

    return (
        <Card>
            <CardHeader
                title={
                    <IconButton onClick={handleNavigateItem} sx={{ ml: -1.25 }}>
                        <ArrowBackIcon />
                    </IconButton>
                }
                action={
                    <Stack direction={"row"} sx={{ backgroundColor: 'transparent' }}>
                        {/* <Tooltip title={"Report Spam"}>
                            <IconButton onClick={() => handleComposeForm(mailID)}>
                                <ReplyOutlined />
                            </IconButton>
                        </Tooltip> */}
                        {/* <Tooltip title={"Archive"}>
                            <IconButton onClick={() => mailMoveToArchived(mailID)}>
                                <ArchiveOutlinedIcon/>
                            </IconButton>
                        </Tooltip>
                        <LabelsSelectControl onDone={applyLabels}/>
                        <Tooltip title={"Delete"}>
                            <IconButton onClick={() => mailMoveToTrash(mailID)}>
                                <DeleteOutlineIcon/>
                            </IconButton>
                        </Tooltip> */}
                    </Stack>
                }
            />
            <CardContent sx={{ p: 0 }}>
                {/* <JumboScrollbar
                    style={{ minHeight: 380 }}
                    autoHide autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                > */}
                    <Div
                        sx={{
                            px: 3,
                            display: 'flex',
                            minHeight: '100%',
                            flexDirection: 'column',
                            minWidth: 0,
                        }}
                    >
                        <Div sx={{ mb: 1 }}>
                            <Typography variant={"h2"} mb={2}>{data?.content?.[0]?.communicationSubject}</Typography>
                            <Div
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Avatar src={`${window.location.origin}/images/logo/${data?.content?.[0]?.communicationToObject?.providerLogo}`} sx={{ width: 44, height: 44 }} />
                                <Div sx={{ ml: 2, flex: 1 }}>
                                    <Typography
                                        variant={'body1'}
                                        fontSize={16}
                                        sx={{
                                            display: 'flex',
                                            minWidth: 0,
                                            alignItems: 'center'
                                        }}
                                    >
                                        {data?.content?.[0]?.communicationFromObject?.returneeName ? data?.content?.[0]?.communicationFromObject?.returneeName : data?.content?.[0]?.communicationFromObject?.providerName}
                                    </Typography>

                                    <Typography sx={{ color: "text.secondary" }}>to {data?.content?.[0]?.communicationToObject?.providerName}</Typography>
                                </Div>
                            </Div>
                        </Div>
                        <Div sx={{ flex: 1 }}>
                            <Div
                                sx={{
                                    position: 'relative',
                                    textAlign: 'center',
                                    mb: 2,
                                    '&:after': {
                                        display: 'inline-block',
                                        content: "''",
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        height: '1px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        backgroundColor: 'divider',
                                    },
                                }}
                            >
                                <Chip
                                    label={data?.content?.[0]?.createdAt}
                                    variant="outlined"
                                    sx={{
                                        position: 'relative',
                                        zIndex: 1,
                                        bgcolor: theme => theme.palette.background.paper,
                                        borderColor: 'divider',
                                        borderRadius: 2
                                    }}
                                />
                            </Div>
                            <Typography>{data?.content?.[0]?.communicationBody}</Typography>
                        </Div>
                        <Div sx={{ mt: 1 }}>
                            <ConversationDetail mailID={mailID} conversation={data?.content} setisUpdated={setisUpdated} />
                        </Div>
                    </Div>
                {/* </JumboScrollbar> */}
            </CardContent>
        </Card>
    );
};

export default MailDetail;
