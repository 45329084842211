import React from "react";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { BuildCircleOutlined, Business, Feed, InsertDriveFileOutlined, PasswordOutlined, WorkOutline } from "@mui/icons-material";
import { USER_ID } from "app/config/localStorageConfig";

const menusProvider = [
    {
        uri: `/service-provider/profile`,
        label: 'sidebar.menuItem.profile',
        type: "nav-item",
        icon: <Business sx={{ fontSize: 20 }} />
    },
    {
        uri: `/service-provider/my-account`,
        label: 'sidebar.menuItem.account',
        type: "nav-item",
        icon: <AccountBoxOutlinedIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/service-provider/service",
        label: 'sidebar.menuItem.service',
        type: "nav-item",
        icon: <WorkOutline sx={{ fontSize: 20 }} />
    },
    {
        uri: "/service-provider/application-form",
        label: 'sidebar.menuItem.application-form',
        type: "nav-item",
        icon: <InsertDriveFileOutlined sx={{ fontSize: 20 }} />
    },
    {
        uri: "/app/inbox",
        label: 'sidebar.menuItem.mail',
        type: "nav-item",
        icon: <EmailOutlinedIcon sx={{ fontSize: 20 }} />
    },
    // {
    //     uri: "/service-provider/blog",
    //     label: 'sidebar.menuItem.blog',
    //     type: "nav-item",
    //     icon: <Feed sx={{ fontSize: 20 }} />
    // },
];

export default menusProvider;
