import React from 'react';
import Div from "@jumbo/shared/Div";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import useChatApp from "../../../hooks/useChatApp";
import moment from 'moment';

const ReceivedMessageContent = ({ message, sent_at }) => {
    const { activeConversation } = useChatApp();
    return (
        <Div
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                px: 3,
                '& .Message-item': {
                    marginTop: '2px'
                }
            }}
        >
            {
                message?.messageType === 'text' ? (
                    <div className="Message-item">
                        <Paper elevation={0} sx={{ p: theme => theme.spacing(1.5, 2), bgcolor: 'primary.main', color: 'common.white' }}>
                            <Typography variant={"body1"}>{message}</Typography>
                        </Paper>
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="Message-root">
                            <Typography
                                variant={"body1"}
                                color={"text.secondary"}
                                fontSize={"smaller"}
                                mb={.5}
                            >
                                {moment(sent_at).format("DD MMMM")}
                            </Typography>
                            <div className="Message-item">
                                <Paper
                                    elevation={0}
                                    sx={{ p: theme => theme.spacing(1.5, 2), bgcolor: 'primary.main', color: 'common.white' }}
                                >
                                    <Typography variant={"body1"}>{message}</Typography>
                                </Paper>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        </Div>
    );
};

export default ReceivedMessageContent;
