import { axiosIns } from "app/config/apiConfig";

const getServiceList = async () => {
  const response = await axiosIns.get(`/services/all`);
  return response.data;
};

const getAllService = async () => {
  const response = await axiosIns.get(`/services/all-list`);
  return response.data;
};

const getOneService = async (id) => {
  const response = await axiosIns.get(`/icmpd/services/all/${id}`);
  return response.data;
};

const getShortServiceList = async (data) => {
  const response = await axiosIns.post(`/icmpd/services/all-short`, data);
  return response.data;
};

const createService = async (data) => {
  const response = await axiosIns.post(`/services/save`, data);
  return response.data;
};

const updateService = async ({ id, data }) => {
  const response = await axiosIns.post(`/services/save/${id}`, data);
  return response.data;
};

const getBannerImage = async (url) => {
  const response = await axiosIns.get(`${url}`);
  return response.data;
};

const deleteService = async (id) => {
  const response = await axiosIns.post(`services/delete/${id}`);
  return response.data;
};

const statusChangeService = async ({ id, status }) => {
  const response = await axiosIns.post(
    `activity/change/service/${id}/${status}`
  );
  return response.data;
};

const serviceServices = {
  getServiceList,
  getAllService,
  createService,
  updateService,
  getShortServiceList,
  getOneService,
  getBannerImage,
  deleteService,
  statusChangeService,
};

export default serviceServices;
