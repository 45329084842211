import Div from "@jumbo/shared/Div";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import OrganisationCard from "./OrganisationCard";
import SectionTitle from "./SectionTitle";
import { useQuery } from "react-query";
import providerServices from "app/services/provider/provider.service";
import { useTranslation } from "react-i18next";
import { getFont } from "app/hooks/getFont";

const OrganisationList = ({ categoryList, length }) => {
  const { t, i18n } = useTranslation();

  const [categorySelected, setcategorySelected] = useState(0);
  const { data, isLoading } = useQuery(
    ["get-service-provider-list-homepage", categorySelected, i18n.language],
    () =>
      providerServices.getProviderListHome(
        categorySelected,
        length < 11 ? "home" : "all",
        i18n.language == "bn-BD" ? 2 : 1
      )
  );

  return (
    <Div sx={{ mb: 3 }}>
      {length < 11 && (
        <>
          <SectionTitle title={t("home.body.title.service-provider")} />
          <Div
            sx={{
              my: 3,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <a onClick={() => setcategorySelected(0)}>
              <Chip
                label={i18n.language == "bn-BD" ? "সবগুলো" : "All"}
                variant="outlined"
                sx={{
                  fontFamily: getFont(i18n),
                  mr: 1,
                  mb: 1,
                  background: categorySelected == 0 ? "#FFF1ED" : "#FFFFFF",
                  borderColor: "#A7A7A7",
                }}
              />
            </a>
            {categoryList?.map((item, i) => (
              <a key={i} onClick={() => setcategorySelected(item?.c_id)}>
                <Chip
                  label={i18n.language == "bn-BD" ? item?.nameBn : item?.name}
                  variant="outlined"
                  sx={{
                    fontFamily: getFont(i18n),
                    mr: 1,
                    mb: 1,
                    background:
                      categorySelected == item?.c_id ? "#FFF1ED" : "#FFFFFF",
                    borderColor: "#A7A7A7",
                  }}
                />
              </a>
            ))}
          </Div>
        </>
      )}

      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, md: 12, lg: 16, xl: 20 }}
      >
        {isLoading ? (
          <Stack
            direction={"row"}
            justifyContent={"center"}
            sx={{ width: "100%", my: 2 }}
          >
            <CircularProgress />
          </Stack>
        ) : data?.content?.length != 0 ? (
          data?.content?.map((item, index) => (
            <Grid item xs={2} sm={4} md={4} key={index} alignItems={"stretch"}>
              <OrganisationCard
                data={item}
                page={length < 11 ? "home" : "all"}
              />
            </Grid>
          ))
        ) : (
          <Stack
            width={"100%"}
            sx={{ my: 3 }}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="h5" textAlign={"center"}>
              No Service Provider Found!
            </Typography>
          </Stack>
        )}
      </Grid>
    </Div>
  );
};

export default OrganisationList;
