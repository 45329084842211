import { axiosIns } from "app/config/apiConfig";

const getInboxMesssages = async () => {
    const response = await axiosIns.get(`/communication/all/IN_BOX`);
    return response.data;
};

const getSendMessages = async () => {
    const response = await axiosIns.get(`/communication/all/SEND_BOX`);
    return response.data;
};

const getOneMessage = async ({ type, id }) => {
    const response = await axiosIns.get(`/communication/all/${type}/${id}`);
    return response.data;
};

const composeMessage = async (data) => {
    const response = await axiosIns.post(`/communication/send/save`, data);
    return response.data;
};

const composeReply = async (data) => {
    const response = await axiosIns.post(`/communication/replay/save`, data);
    return response.data;
};

const messageServices = {
    composeMessage,
    getInboxMesssages,
    getSendMessages,
    getOneMessage,
    composeReply
};

export default messageServices;