import React from 'react';
import { Chip, Typography } from "@mui/material";
import Div from '@jumbo/shared/Div/Div';
import moment from 'moment';

const ConversationMessage = ({ reply }) => {
    
    return (
        <React.Fragment>
            <Div
                sx={{
                    position: 'relative',
                    textAlign: 'center',
                    mb: 2,
                    '&:after': {
                        display: 'inline-block',
                        content: "''",
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        height: '1px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'divider',
                    },
                }}
            >
                <Chip
                    label={reply?.createdAt}
                    variant="outlined"
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        bgcolor: theme => theme.palette.background.paper,
                        borderColor: 'divider',
                        borderRadius: 2
                    }}
                />
            </Div>
            <Typography variant='caption' color={"#a7a7a7"} mb={2}>{reply?.communicationFromObject?.returneeName ? reply?.communicationFromObject?.returneeName : reply?.communicationFromObject?.providerName}</Typography>
            <Typography mb={2}>{reply?.communicationBody}</Typography>
        </React.Fragment>
    );
};

export default ConversationMessage;
