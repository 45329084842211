import Div from '@jumbo/shared/Div';
import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import SectionTitle from './SectionTitle';
import ServiceCard from './ServiceCard';
import { useTranslation } from 'react-i18next';

const ServiceCategory = ({ list, isLoading }) => {

    const { t, i18n } = useTranslation();

    return (
        <Div sx={{ mb: 10 }}>
            <SectionTitle title={t("home.body.title.service-category")} />
            <Grid container alignItems="stretch" spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, md: 12, lg: 16, xl: 20 }}>
                {list?.map((item, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                        <ServiceCard lng={i18n} data={item} isLoading={isLoading} />
                    </Grid>
                ))}
            </Grid>
        </Div>
    )
}

export default ServiceCategory;