import React from 'react';
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import { AvatarGroup, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import { ASSET_AVATARS } from "../../../../utils/constants/paths";
import { getAssetPath } from "../../../../utils/appHelpers";
import { BadgeOutlined, FlightOutlined, WcOutlined, WorkOutline } from '@mui/icons-material';
import { PassportIcon } from 'app/utils/icon/icon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));
const About = ({ data }) => {
    const { t, i18n } = useTranslation();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <JumboCardQuick
            title={t("user.profile.about.title")}
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{ mb: 3.75 }}

        >
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                }}
            >
                <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '50%' }
                    }}
                >
                    <StyledListItemIcon>
                        <BadgeOutlined fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("user.profile.about.nid")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">
                            {data?.nidNo ? data?.nidNo : "---"}</Typography>}
                    />
                </ListItem>

                <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '50%' }
                    }}
                >
                    <StyledListItemIcon>
                        <PassportIcon color={"#7352C7"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("user.profile.about.passport")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">
                            {data?.passportNo ? data?.passportNo : "---"}</Typography>}
                    />
                </ListItem>

                <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '50%' }
                    }}
                >
                    <StyledListItemIcon>
                        <CakeOutlinedIcon fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary"
                            mb={.5}>{t("user.profile.about.birthday")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.dob ? moment(data?.dob).format("DD MMMM YYYY") : "---"}</Typography>}
                    />
                </ListItem>

                <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '33.33%' }
                    }}
                >
                    <StyledListItemIcon>
                        <WcOutlined fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("user.profile.about.gender")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">
                            {data?.gender ? data?.gender : "---"}</Typography>}
                    />
                </ListItem>

                {/* <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '33.33%' }
                    }}
                >
                    <StyledListItemIcon>
                        <FlightOutlined fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>Return from</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">Saudia Arab</Typography>}
                    />
                </ListItem>

                <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '33.33%' }
                    }}
                >
                    <StyledListItemIcon>
                        <WorkOutline fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>Profession
                        </Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">Painter</Typography>}
                    />
                </ListItem> */}
            </List>
        </JumboCardQuick>
    );
};

export default About;
