import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import Div from '@jumbo/shared/Div/Div';
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import React, { useState, useEffect } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { AddCircleOutline, CheckBox, RemoveCircleOutline } from '@mui/icons-material';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import serviceServices from 'app/services/service.service';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import adminServices from 'app/services/admin.service';
import publicServices from 'app/services/public/public.service';

const MySwal = withReactContent(Swal)

const FAQupdate = () => {

    const [isUpdate, setisUpdate] = useState(0);

    const [dySection, setdySection] = useState([
        {
            sectionHeaderTitle: "",
            sectionHeaderTitleBn: "",
            sectionBody: "",
            sectionBodyBn: "",
            sectionPriority: "",
            isActive: 1,
            id: 0
        }
    ]);

    const updateState = (index, field, value) => {
        const newArray = dySection.map((item, i) => {
            if (index === i) {
                return { ...item, [field]: value };
            } else {
                return item;
            }
        });
        setdySection(newArray);
    };

    const addSection = (index) => {
        setdySection(current => [
            ...current.slice(0, index),
            {
                sectionHeaderTitle: "",
                sectionBody: "",
                sectionHeaderTitleBn: "",
                sectionBodyBn: "",
                sectionPriority: 0,
                isActive: 1,
                id: 0
            },
            ...current.slice(index),
        ]);
    }

    const removeSection = (index) => {

        setdySection((prevState, i) => {
            const newState = JSON.parse(JSON.stringify(prevState));
            newState[index].isActive = 0;
            return newState;
        });
    }


    const queryClient = useQueryClient();

    const { data: dataService, isLoading } = useQuery(['get-faq-adimn', isUpdate], () => publicServices.getfaq(), {
        onSuccess: data => {
            if (data?.content?.length > 0) {
                setdySection(data?.content)
            }
        },
    })


    const { mutate, isLoading: isLoadingSubmit } = useMutation(adminServices.updateFaq, {
        onSuccess: (data) => {
            setisUpdate(prev => prev + 1)
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });


    return (
        <Div>
            {
                ((isLoading) || isLoadingSubmit) ? (
                    <Div sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "100%",
                        minHeight: "20vh"
                    }}>
                        <CircularProgress color={"warning"} sx={{ m: '-40px auto 0' }} />
                    </Div>
                ) : (

                    <JumboContentLayout
                        header={
                            <Grid container style={{
                                position: "fixed",
                                zIndex: 1000,
                                width: "82%",
                                background: "white",
                                marginLeft: "-50px",
                                padding: "30px 50px 0px",
                                marginTop: "-33px"
                            }}>
                                <Grid item sm={12} md={6}>
                                    <PageHeader
                                        title={"Frequently Asked Questions"}
                                        noMargin={true} />
                                </Grid>
                                <Grid item sm={12} md={6} >
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        <Button onClick={() => mutate(dySection)} variant="contained" >
                                            Update
                                        </Button>
                                    </Stack>

                                </Grid>
                            </Grid>
                        }
                    >


                        <Typography variant='h5' sx={{ mb: 3, mt: 3 }}>
                            Dynamic Section
                        </Typography>

                        {
                            dySection?.map((item, i) => {
                                if (item?.isActive == 1) {
                                    return (
                                        <Div key={i} sx={{
                                            mb: 3,
                                            border: "1px solid lightgray",
                                            borderRadius: "10px",
                                            padding: "20px",
                                        }}>
                                            <TextField
                                                fullWidth
                                                label={"Section Title"}
                                                sx={{ mb: 3 }}
                                                value={item.sectionHeaderTitle}
                                                onChange={(e) => updateState(i, "sectionHeaderTitle", e.target.value)}
                                            />

                                            <TextField
                                                fullWidth
                                                label={"Section Title (Bangla)"}
                                                sx={{ mb: 3 }}
                                                value={item.sectionHeaderTitleBn}
                                                InputProps={{ style: { background: "#e4fde4" } }}
                                                onChange={(e) => updateState(i, "sectionHeaderTitleBn", e.target.value)}
                                            />
                                            <TextField
                                                fullWidth
                                                type='number'
                                                label={"Section Priority"}
                                                sx={{ mb: 3 }}
                                                value={item.sectionPriority}
                                                onChange={(e) => updateState(i, "sectionPriority", parseInt(e.target.value))}
                                            />
                                            <Div sx={{ border: "1px solid lightgray", borderRadius: "6px" }}>
                                                <Typography sx={{ p: 1 }}>Section Body</Typography>
                                                <CKEditor
                                                    editor={Editor}
                                                    data={item?.sectionBody}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        console.log({ event, editor, data });
                                                        updateState(i, "sectionBody", data)
                                                    }}
                                                />
                                            </Div>
                                            <Div className='bodyBn' sx={{ border: "1px solid lightgray", borderRadius: "6px", mt: 3 }}>
                                                <Typography sx={{ p: 1 }}>Section Body (Bangla)</Typography>
                                                <CKEditor
                                                    editor={Editor}
                                                    data={item?.sectionBodyBn}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        console.log({ event, editor, data });
                                                        updateState(i, "sectionBodyBn", data)
                                                    }}
                                                />
                                            </Div>
                                            <Div sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                                <Button
                                                    sx={{ mr: 2 }}
                                                    variant='contained'
                                                    color='error'
                                                    startIcon={<RemoveCircleOutline />}
                                                    disabled={dySection?.filter(item => item?.isActive != 0)?.length == 1 ? true : false}
                                                    onClick={() => removeSection(i)}
                                                >
                                                    Remove
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    startIcon={<AddCircleOutline />}
                                                    onClick={() => addSection(i + 1)}
                                                >
                                                    Add
                                                </Button>
                                            </Div>
                                        </Div>
                                    )
                                }
                            })
                        }


                    </JumboContentLayout>

                )
            }

        </Div>
    )
}

export default FAQupdate;