import React from 'react';
import { Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../../../../utils/constants/paths";
import { getAssetPath } from "../../../../../../utils/appHelpers";
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const ContentPlaceholder = () => {
    const { t, i18n } = useTranslation();

    return (
        <Div
            sx={{
                textAlign: 'center',
                margin: 'auto',
                p: 3
            }}
        >
            <Typography variant={"h2"} mt={2} fontFamily={getFont(i18n)} color={"text.primary"}>{i18n.language == "bn-BD" ? "চ্যাটিং শুরু করুন" : "Start chatting"}</Typography>
        </Div>
    );
};

export default ContentPlaceholder;
