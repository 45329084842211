import React from 'react';
import { Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const PageHeader = ({ title, subheader, noMargin }) => {
    const { t, i18n } = useTranslation();
    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: noMargin ? 0 : 4
            }}
        >
            <Typography fontFamily={getFont(i18n)} variant={"h2"}>{title}</Typography>
            <Typography fontFamily={getFont(i18n)} variant={"body1"} mb={2} color={"text.secondary"}>{subheader}</Typography>
        </Div>
    );
};

export default PageHeader;