import Div from '@jumbo/shared/Div';
import { SearchOutlined } from '@mui/icons-material';
import { Button, InputBase, useMediaQuery } from '@mui/material';
import { CATEGORY, LOCATION, L_TEXT, PROVIDER, SEARCH_TEXT } from 'app/config/localStorageConfig';
import { getFont } from 'app/hooks/getFont';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const containerStyle = {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    height: "65px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    '&:hover': {
        background: '#475259',
    }
}

const SearchBox = () => {
    const isMobile = useMediaQuery('(max-width:575px)');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [search, setsearch] = useState("");
    const [location, setlocation] = useState("");

    const sText = localStorage.getItem(SEARCH_TEXT)
    const lText = localStorage.getItem(L_TEXT)

    useEffect(() => {
        !!sText && setsearch(sText)
        !!L_TEXT && setlocation(lText)
    }, [sText, lText]);

    return (
        <Div style={containerStyle}>
            <Div>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={t("placeholder.service")}
                    inputlabelprops={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    inputProps={{
                        'aria-label': 'Write Service',
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    value={search ? search : ""}
                    onChange={(e) => {
                        setsearch(e.target.value)
                    }}
                />
            </Div>
            {!isMobile && (
                <Div style={{ borderLeft: "1px solid #D9D9D9" }}>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        inputlabelprops={{
                            style: {
                                fontFamily: getFont(i18n)
                            }
                        }}
                        placeholder={t("placeholder.location")}
                        inputProps={{
                            'aria-label': 'Write Location',
                            style: {
                                fontFamily: getFont(i18n)
                            }
                        }}
                        value={location ? location : ""}
                        onChange={(e) => {
                            setlocation(e.target.value)
                        }}
                    />
                </Div>
            )}
            {
                ((search && search !== "") || (location && location !== "")) && (
                    <Button onClick={() => {

                        localStorage.setItem(CATEGORY, 0)
                        localStorage.setItem(LOCATION, 0)
                        localStorage.setItem(PROVIDER, 0)
                        localStorage.setItem(SEARCH_TEXT, "");
                        localStorage.setItem(L_TEXT, "");
                        setlocation("");
                        setsearch("");
                        if (window.location.pathname == "/service-list") {
                            navigate("/service-list")
                        }
                    }} color='inherit' size='small' sx={{ fontFamily: getFont(i18n) }}>
                        {t("button.clear")}
                    </Button>
                )
            }
            <Button onClick={() => {
                navigate("/service-list")
                localStorage.setItem(SEARCH_TEXT, search)
                localStorage.setItem(L_TEXT, !!location ? location : "")
                localStorage.setItem(CATEGORY, 0)
                localStorage.setItem(LOCATION, 0)
                localStorage.setItem(PROVIDER, 0)
            }} variant="contained" startIcon={<SearchOutlined />} sx={{ fontFamily: getFont(i18n) }}>
                {t("button.search")}
            </Button>
        </Div>
    )
}

export default SearchBox;