import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import JumboDdPopover from '@jumbo/components/JumboDdPopover/JumboDdPopover';
import Div from '@jumbo/shared/Div/Div';
import { AddCircleOutline, Cancel, CheckCircle, DeleteForeverOutlined, InfoOutlined, ModeEditOutline, MoreVertOutlined, TextSnippet } from '@mui/icons-material';
import { Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import applicationServices from 'app/services/application.service';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const Applications = () => {

    const [isUpdate, setisUpdate] = useState(0);

    const { data, isLoading } = useQuery(['get-application-form-list', isUpdate], () => applicationServices.getApplicationList())

    const columns = [
        {
            name: 'Serial No.',
            width: "100px",
            selector: (row, index) => index + 1,
        },
        {
            name: 'Name',
            selector: row => row.templateName,
        },
        {
            name: 'Status',
            selector: row => row.is_active,
            width: "110px",
            cell: (row) => {
                if (row.is_active) {
                    return <Chip label="Active" size="small" color="success" />
                } else {
                    return <Chip label="Inactive" size="small" color="error" />
                }
            }
        },
        {
            name: 'No. of Submission',
            selector: row => row.applicant_count,
            center: true
        },
        {
            name: 'Created at',
            selector: row => row.createdAt,
            cell: row => {
                return moment(row.createdAt).format("DD/MM/yyy")
            }
        },
        {
            name: 'Action',
            cell: (row, index, column, id) => {
                return (
                    <JumboDdPopover
                        triggerButton={
                            <IconButton>
                                <MoreVertOutlined />
                            </IconButton>
                        }
                    >
                        <List disablePadding sx={{ pb: 1 }}>
                            <ListItemButton>
                                <Link sx={{ display: 'flex' }} to={`/application-form/${row?.id}/0`} color={"inherit"} component={RouterLink} underline={"none"}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <InfoOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Details" sx={{ my: 0 }} />
                                </Link>
                            </ListItemButton>
                            <ListItemButton sx={{ display: 'flex' }}>
                                <Link sx={{ display: 'flex' }} to={`/service-provider/application-form/submitted-data/${row?.id}`} color={"inherit"} component={RouterLink} underline={"none"}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <TextSnippet />
                                    </ListItemIcon>
                                    <ListItemText sx={{ my: 0 }} primary="Submitted Data" />
                                </Link>
                            </ListItemButton>
                            <ListItemButton sx={{ display: 'flex' }} onClick={() => onStatusChange(row?.id, row.is_active ? 0 : 1)} >
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    {row.is_active ? <Cancel /> : <CheckCircle />}
                                </ListItemIcon>
                                <ListItemText primary={row.is_active ? "Inactive" : "Active"} />
                            </ListItemButton>
                            {
                                row.isEditable && (
                                    <ListItemButton>
                                        <Link sx={{ display: 'flex' }} to={`/service-provider/application-form/update/${row?.id}`} color={"inherit"} component={RouterLink} underline={"none"}>
                                            <ListItemIcon sx={{ minWidth: 36 }}>
                                                <ModeEditOutline />
                                            </ListItemIcon>
                                            <ListItemText primary="Edit" sx={{ my: 0 }} />
                                        </Link>
                                    </ListItemButton>
                                )
                            }
                        </List>
                    </JumboDdPopover >
                )

            }
        },
    ];

    const { mutate: mutateStatus } = useMutation(applicationServices.statusChangeApplication, {
        onSuccess: data => {
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })

            if (data?.statusCode == 200) {
                setisUpdate(isUpdate + 1)
            }
        }
    })

    const onStatusChange = (id, status) => {
        MySwal.fire({
            icon: 'warning',
            showConfirmButton: true,
            html: <strong>Are you sure you want to change status?</strong>,
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                mutateStatus({
                    id: id,
                    status: status
                })
            }
        })
    }

    const CreateExportTable = () => {

        var css = `<style type="text/css" media="print">
        @page { margin: 20; }
        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }
          
          td, th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }
        </style>`

        var header = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title></title>${css}</head><body><h2>Application Summary</h2>`;

        var footer = "</body></html>";

        var html = header + document.getElementById("contentToPrint").innerHTML + footer;

        return html;


    }

    const exportHTMLtoPDF = () => {

        var sourceHTML = CreateExportTable();
        var displayHTML = sourceHTML.replace('none', 'block');
        
        if (sourceHTML) {
            var win = window.open('', '', 'height=3508 ,width=2480');
            win.document.write(displayHTML);
            win.document.close();

            win.print();

        } else {
            alert("কোনো তথ্য পাওয়া যায়নি");
        }

    }


    const exportHTMLtoExcel = () => {

        var sourceHTML = CreateExportTable();
        var tableSelect = document.getElementById("contentToPrint");
        var tableHTML = tableSelect.innerHTML.replace(/ /g, '%20');

        if (sourceHTML) {
            var source = `data:application/vnd.ms-excel, ` + tableHTML;
            var fileDownload = document.createElement("a");
            document.body.appendChild(fileDownload);
            fileDownload.href = source;
            fileDownload.download = 'Application_summary.xls';
            fileDownload.click();
            document.body.removeChild(fileDownload);
        } else {
            alert("কোনো তথ্য পাওয়া যায়নি");
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Div>
            <JumboContentLayout
                header={
                    <Grid container>
                        <Grid item sm={12} md={6}>
                            <PageHeader
                                title={"Application Forms List"}
                                subheader={"All application forms are here"} />
                        </Grid>
                        <Grid item sm={12} md={6} textAlign={"end"}>
                            <Link to={"/service-provider/application-form/new-form"} color={"inherit"} component={RouterLink} underline={"none"}>
                                <Button variant="contained" startIcon={<AddCircleOutline />}>
                                    Add New Form
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                }

            >

                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                    <Typography ml={3} mt={1}>Export as: </Typography>
                    <ButtonGroup variant="text" size='sm' aria-label="text button group">
                        <Button sx={{ ml: 1, mt: 1 }} onClick={exportHTMLtoPDF}>
                            PDF
                        </Button>
                        <Button sx={{ ml: 1, mt: 1 }} onClick={exportHTMLtoExcel}>
                            EXCEL
                        </Button>
                    </ButtonGroup>
                </Stack>

                <Div>
                    <div id='contentToPrint'>
                        <table style={{ display: "none" }}>
                            <tr>
                                <th>Serial No.</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>No. of Submission</th>
                                <th>Created at</th>
                            </tr>
                            {
                                data?.content?.map((row, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{row.templateName}</td>
                                        <td>{row.is_active ? "Active" : "Inactive"}</td>
                                        <td>{row.applicant_count ? row.applicant_count : "---"}</td>
                                        <td>{moment(row.createdAt).format("DD/MM/yyy")}</td>
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                    {
                        isLoading ? (
                            <Div sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minWidth: "100%",
                                minHeight: "20vh"
                            }}>
                                <CircularProgress color={"warning"} sx={{ m: '-40px auto 0' }} />
                            </Div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={data?.content}
                            />
                        )
                    }
                </Div>


            </JumboContentLayout>
        </Div>
    )
}

export default Applications;