import { ASSET_AVATARS } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";

export const authUser = {
    email: "saidurrahman@gmail.com",
    name: "Saidur Rahman",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar6.png`, `60x60`),
    handle: "saidurrahman@gmail.com",
    job_title: "Creative Head"
};
