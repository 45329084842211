import { axiosIns } from "app/config/apiConfig";

const getCategoryListHome = async () => {
    const response = await axiosIns.get(`/icmpd/services/category/list-home`);
    return response.data;
};

const getDivision = async () => {
    const response = await axiosIns.get(`/icmpd/services/division`);
    return response.data;
};

const getDistrict = async (division = 0) => {
    const response = await axiosIns.get(`/icmpd/services/district/${division}`);
    return response.data;
};

const getCountry = async () => {
    const response = await axiosIns.get(`/icmpd/services/country`);
    return response.data;
};

const getImage = async (url) => {
    const response = await axiosIns.get(`${url}`);
    return response.data;
};

const getMode = async () => {
    const response = await axiosIns.get(`/auth/dev-stage`);
    return response.data;
};

const getfaq = async () => {
    const response = await axiosIns.get(`/icmpd/faq/get/all`);
    return response.data;
};

const publicServices = {
    getCategoryListHome,
    getDivision,
    getDistrict,
    getCountry,
    getImage,
    getMode,
    getfaq
};

export default publicServices;