import React from 'react';
import Avatar from "@mui/material/Avatar";
import { Button, CircularProgress, useMediaQuery, Box, LinearProgress } from "@mui/material";
import ContentHeader from "../../../layouts/shared/headers/ContentHeader";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ASSET_AVATARS } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';
import PasswordOutlined from '@mui/icons-material/PasswordOutlined';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { PhonelinkSetupOutlined } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";
import Div from '@jumbo/shared/Div/Div';
import { useQuery } from 'react-query';
import returneeServices from 'app/services/returnee.service';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import FileUploader from 'app/shared/FileUploader/FileUploader';
import { USER_ID } from 'app/config/localStorageConfig';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal)

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(0, 1),

    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& .MuiTouchRipple-root': {
        display: 'none'
    }

}));

const Item = styled("div")({
    textAlign: 'center',
});

const useStyles = makeStyles((theme) => ({
    circle: {
        strokeLinecap: "round",
        transition: 'stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    }
}));


const Header = ({ data, isEdit }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery('(max-width:575px)');
    const [value, setvalue] = React.useState(!!parseInt(data?.profileProgress) ? parseInt(data?.profileProgress) : 0);
    const [progress, setProgress] = React.useState(0);
    const [uploadFile, setuploadFile] = React.useState(null);

    // const { data: dataPP } = useQuery(['get-profile-picture', data?.profileLogoUrl], () => returneeServices.getReturneeProfilePicture(data?.profileLogoUrl), {
    //     enabled: !!data?.profileLogoUrl
    // })

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= value ? value : prevProgress + 1));
        }, 50);
        return () => {
            clearInterval(timer);
        };
    }, []);

    React.useEffect(() => {
        if (uploadFile) {
            const formData = new FormData();
            formData.append("file", uploadFile)
            try {
                returneeServices.uploadReturneeProfilePicture({
                    id: localStorage.getItem(USER_ID),
                    data: formData
                }).then((data) => {
                    MySwal.fire({
                        icon: data?.statusCode == 200 ? 'success' : 'error',
                        showConfirmButton: false,
                        html: <i>{data?.message}</i>,
                        timer: 5000
                    }).then((result) => {
                        if (result.isDismissed && data?.statusCode == 200) {
                            window.location.assign('/user/profile')
                        }
                    })
                })
            } catch (error) {

            }
        }
    }, [uploadFile]);


    return (
        <ContentHeader
            avatar={
                <Stack position={"relative"}>
                    {
                        isEdit && <FileUploader setFile={setuploadFile} />
                    }
                    <Avatar
                        sx={{ width: 100, height: 100, background: "white" }}
                        alt={data?.name}
                        src={data?.profileLogoServer ? data?.profileLogoServer  : getAssetPath(`${ASSET_AVATARS}/avatar6.png`, "100X100")}
                    />
                </Stack>
            }
            title={
                <Typography fontFamily={getFont(i18n)} variant={'h1'} color={'white'}>
                    {data?.name ? i18n.language == "bn-BD" ? data?.nameBn : data?.name : "---"}
                </Typography>
            }
            subheader={
                <Box>
                    <Typography fontFamily={getFont(i18n)} fontSize={12} variant={'body1'} color={'white'} mt={.5}>
                        {`${data?.addressDistrict ? i18n.language == "bn-BD" ? data?.addressDistrictBn : data?.addressDistrict : "---"}, ${data?.addressDivision ? i18n.language == "bn-BD" ? data?.addressDivisionBn : data?.addressDivision : "---"}`}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: isMobile ? "column" : "row", alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress
                                sx={{
                                    height: "7px",
                                    borderRadius: "10px"
                                }}
                                variant="determinate"
                                value={progress}
                                color={progress < 50 ? "error" : progress == 100 ? "success" : "info"}
                            />
                        </Box>
                        <Box sx={{ minWidth: 150 }}>
                            <Typography fontFamily={getFont(i18n)} variant="body1" color="white">
                                {`${Math.round(
                                    progress,
                                )}% ${i18n.language == "bn-BD" ? "প্রোফাইল সম্পূর্ণ" : "profile complete"}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            }
            tabs={
                <></>
            }
            action={isEdit ? (
                <Link to={"/user/profile"} style={{ textDecoration: "none" }}>
                    <Button
                        disableRipple
                        variant="text"
                        startIcon={<ArrowBack />}
                        sx={{
                            color: 'white',
                            fontFamily: getFont(i18n),
                            textTransform: 'none',
                            fontSize: "15px",
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        {t("button.back-to-profile")}
                    </Button>
                </Link>
            ) : (
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent={"space-evenly"}
                    divider={!isMobile && <Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{
                        mx: 1
                    }}
                >
                    <Link to={"/user/edit-profile"} state={data} style={{ textDecoration: "none" }}>
                        <Button
                            disableRipple
                            variant="text"
                            startIcon={<EditOutlined />}
                            sx={{
                                color: 'white',
                                fontFamily: getFont(i18n),
                                textTransform: 'none',
                                fontSize: "15px",
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            {t("user.profile.edit-profile.title")}
                        </Button>
                    </Link>

                    <Link to={"/user/change-password"} style={{ textDecoration: "none" }}>
                        <Button
                            disableRipple
                            variant="text"
                            startIcon={<PasswordOutlined />}
                            sx={{
                                color: 'white',
                                fontFamily: getFont(i18n),
                                textTransform: 'none',
                                fontSize: "15px",
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            {t("change-pass")}
                        </Button>
                    </Link>
                    {
                        data?.isRegByMobile && (
                            <Link to={"/user/change-phone-number"} style={{ textDecoration: "none" }}>
                                <Button
                                    disableRipple
                                    variant="text"
                                    startIcon={<PhonelinkSetupOutlined />}
                                    sx={{
                                        fontFamily: getFont(i18n),
                                        color: 'white',
                                        textTransform: 'none',
                                        fontSize: "15px",
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                >
                                    {t("change-number")}
                                </Button>
                            </Link>
                        )
                    }

                </Stack>
            )}
            sx={{
                position: 'relative',
                zIndex: 1,

                '& .MuiCardHeader-action': {
                    alignSelf: 'center'
                }
            }}
        />
    );
};

export default Header;
