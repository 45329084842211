import { axiosIns } from "app/config/apiConfig";

const getBlogList = async () => {
    const response = await axiosIns.get(`/articles/all`);
    return response.data;
};

const getBlogListPublic = async () => {
    const response = await axiosIns.get(`/icmpd/articles/all`);
    return response.data;
};

const getABlog = async (id) => {
    const response = await axiosIns.get(`/icmpd/articles/${id}`);
    return response.data;
};

const getABlogAdmin = async (id) => {
    const response = await axiosIns.get(`/articles/${id}`);
    return response.data;
};

const createNewBlog = async (data) => {
    const response = await axiosIns.post(`/articles/save`, data);
    return response.data;
};

const updateNewBlog = async ({ id, data }) => {
    const response = await axiosIns.post(`/articles/save/${id}`, data);
    return response.data;
};

const blogServices = {
    getBlogList,
    getBlogListPublic,
    getABlog,
    getABlogAdmin,
    createNewBlog,
    updateNewBlog
};

export default blogServices;