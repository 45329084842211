import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TableContainer,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import serviceServices from "app/services/service.service";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid white",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const RelatedService = ({ handleClose, formik }) => {
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const [services, setservices] = useState([]);

  const { data } = useQuery(
    ["get-all-service-suto"],
    () => serviceServices.getAllService(),
    {
      onSuccess: (data) => {
        console.log({ data });
      },
    }
  );

  useEffect(() => {
    setservices(formik?.values?.relatedServices);
  }, [formik?.values?.relatedServices]);

  const updateState = (index, field, value) => {
    const newArray = services.map((item, i) => {
      if (index === i) {
        return { ...item, [field]: value };
      } else {
        return item;
      }
    });
    setservices(newArray);
  };

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h3"
        component="h2"
        sx={{ mb: 2 }}
      >
        Related Services
      </Typography>

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data?.content ? data?.content : []}
        getOptionLabel={(option) => (option?.label ? option?.label : "")}
        renderInput={(params) => <TextField {...params} label="Service Name" />}
        value={value}
        onChange={(event, newValue) => {
          if (newValue?.service_id) {
            setValue(newValue);
            setservices((prev) => [
              ...prev,
              {
                mapWithServiceId: newValue?.service_id,
                mapped_With_Service_name: newValue?.service_name,
                priority: newValue?.priority ? newValue?.priority : 0,
                isRemove: 0,
              },
            ]);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        sx={{ mb: 3 }}
      />

      <TableContainer sx={{
        
      }} component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Service Name</TableCell>
              <TableCell align="right">Priority</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services?.map((item, i) =>
              item?.isRemove != 1 ? (
                <TableRow>
                  <TableCell>{item?.mapped_With_Service_name}</TableCell>
                  <TableCell align="right">
                    <TextField
                      size="sm"
                      value={item?.priority}
                      onChange={(e) =>
                        updateState(i, "priority", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => updateState(i, "isRemove", 1)}>
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction={"row"} spacing={2} mt={3}>
        <Button
          fullWidth
          type="button"
          variant="contained"
          size="large"
          color="error"
          onClick={handleClose}
        >
          Close
        </Button>
        <LoadingButton
          onClick={() => {
            handleClose();
            formik.setFieldValue("relatedServices", services);
          }}
          fullWidth
          type="submit"
          variant="contained"
          size="large"
        >
          Done
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default RelatedService;
