import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import JumboContentLayout from '@jumbo/components/JumboContentLayout/JumboContentLayout';
import Div from '@jumbo/shared/Div/Div';
import { Edit, Password } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Grid, Link, List, ListItem, ListItemText, Modal, Stack, TextField, Typography } from '@mui/material';
import { getFont } from 'app/hooks/getFont';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import providerServices from 'app/services/provider/provider.service';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid white',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};


const ProviderAccount = () => {

    const { t, i18n } = useTranslation();
    const [open, setopen] = useState(false);
    const [isUpdated, setisUpdated] = useState(0);
    const queryClient = useQueryClient();

    const handleClose = () => setopen(false);

    const { data, isLoading } = useQuery(["get-represent-profile", isUpdated], () => providerServices.getRepresentativeProfile(), {
        onSuccess: (data) => {
            formik.setValues(data?.content);
            formik.setFieldValue("serviceProviderInfoId", data?.content?.serviceProviderInfo?.id);
        }
    });

    const { mutate } = useMutation(providerServices.updateRepresentativeProfile, {
        onSuccess: (data) => {

            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 5000
            })
            if (data?.statusCode == 200) {
                handleClose()
                setisUpdated(prev => prev + 1)
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            designation: "",
            phoneNumber: ""
        },
        onSubmit: values => {
            delete values?.serviceProviderInfo;
            mutate(values);
        }
    })

    return (
        <Div>
            <JumboContentLayout
                header={
                    <Grid container>
                        <Grid item sm={12} md={6}>
                            <PageHeader
                                title={"My Account"} />
                        </Grid>
                        <Grid item sm={12} md={6} textAlign={"end"}>
                            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} >
                                <Button variant="contained" startIcon={<Edit />} onClick={() => setopen(true)} >
                                    Edit Account
                                </Button>
                                <Link to={"/service-provider/my-account/change-password"} color={"inherit"} component={RouterLink} underline={"none"}>
                                    <Button variant="contained" startIcon={<Password />}>
                                        Change Password
                                    </Button>
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>
                }
            >
                {
                    isLoading ? (
                        <Stack direction={"row"} justifyContent={"center"} sx={{ width: "100%", my: 2 }} >
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <JumboCardQuick
                            title={"Personal Details"}
                            sx={{ mb: 2, mt: 2 }}
                        >
                            <List
                                disablePadding
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    margin: 0,
                                }}
                            >

                                <ListItem
                                    sx={{
                                        width: "100%"
                                    }}
                                >
                                    <ListItemText
                                        disableTypography={true}
                                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>{"Name"}</Typography>}
                                        secondary={
                                            <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">
                                                {data?.content?.name ? data?.content?.name : "---"}</Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem
                                    sx={{
                                        width: "100%"
                                    }}
                                >
                                    <ListItemText
                                        disableTypography={true}
                                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>{"Designation"}</Typography>}
                                        secondary={
                                            <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">
                                                {data?.content?.designation ? data?.content?.designation : "---"}</Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem
                                    sx={{
                                        width: "100%"
                                    }}
                                >
                                    <ListItemText
                                        disableTypography={true}
                                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>{"Organisation Name"}</Typography>}
                                        secondary={
                                            <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">
                                                {data?.content?.serviceProviderInfo?.description ? data?.content?.serviceProviderInfo?.description : "---"}</Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem
                                    sx={{
                                        width: "100%"
                                    }}
                                >
                                    <ListItemText
                                        disableTypography={true}
                                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>{"Phone Number"}</Typography>}
                                        secondary={
                                            <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">
                                                {data?.content?.phoneNumber ? data?.content?.phoneNumber : "---"}</Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem
                                    sx={{
                                        width: "100%"
                                    }}
                                >
                                    <ListItemText
                                        disableTypography={true}
                                        primary={<Typography fontFamily={getFont(i18n)} fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>{"Email Address"}</Typography>}
                                        secondary={
                                            <Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">
                                                {data?.content?.emailAddress ? data?.content?.emailAddress : "---"}</Typography>
                                        }
                                    />
                                </ListItem>

                            </List>
                        </JumboCardQuick>
                    )
                }

            </JumboContentLayout>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title-sr"
                aria-describedby="modal-modal-description-sr"
                disableEscapeKeyDown
            >
                <Box sx={style}>
                    <form onSubmit={formik.handleSubmit}>
                        <Typography id="modal-modal-title-sr" variant="h3" component="h2" sx={{ mb: 2 }}>
                            Edit Account
                        </Typography>
                        <Div sx={{ mt: 1, mb: 3 }}>
                            <TextField
                                fullWidth
                                label={`Name`}
                                {...formik.getFieldProps('name')}
                                required
                            />
                        </Div>
                        <Div sx={{ mt: 1, mb: 3 }}>
                            <TextField
                                fullWidth
                                label={`Designation`}
                                {...formik.getFieldProps('designation')}
                                required
                            />
                        </Div>
                        <Div sx={{ mt: 1, mb: 3 }}>
                            <TextField
                                fullWidth
                                label={`Phone Number`}
                                {...formik.getFieldProps('phoneNumber')}
                                required
                            />
                        </Div>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                fullWidth
                                type="button"
                                variant="contained"
                                size="large"
                                color='error'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                            >
                                Update
                            </LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </Div>
    )
}

export default ProviderAccount;