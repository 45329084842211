import { axiosIns } from "app/config/apiConfig";

const sendOTPtoMobile = async (data) => {
    const response = await axiosIns.post(`/icmpd/returnee/mobile-or-email`, data);
    return response.data;
};

const loginUser = async (data) => {
    const response = await axiosIns.post(`/auth/returnee/login`, data);
    return response.data;
};

const loginProvider = async (data) => {
    const response = await axiosIns.post(`/auth/admin/login`, data);
    return response.data;
};

const verifyOTP = async (data) => {
    const response = await axiosIns.post(`/icmpd/returnee/verify-otp`, data);
    return response.data;
};

const resendOTP = async (data) => {
    const response = await axiosIns.post(`/icmpd/returnee/resend-otp`, data);
    return response.data;
};

const createUser = async (data) => {
    const response = await axiosIns.post(`/icmpd/returnee/final-registration`, data);
    return response.data;
};

const createServiceProvider = async (data) => {
    const response = await axiosIns.post(`/icmpd/provider/reg/save`, data);
    return response.data;
};

const forgotPassword = async (data) => {
    const response = await axiosIns.post(`/auth/forgot-password`, data);
    return response.data;
};

const resetPassword = async (data) => {
    const response = await axiosIns.post(`/auth/reset-password`, data);
    return response.data;
};

const signOut = async () => {
    const response = await axiosIns.get(`/auth/logout`);
    return response.data;
};

const authServices = {
    sendOTPtoMobile,
    createUser,
    loginUser,
    loginProvider,
    forgotPassword,
    verifyOTP,
    resendOTP,
    resetPassword,
    createServiceProvider,
    signOut
};

export default authServices;
