import React from 'react';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import { alpha, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Divider from "@mui/material/Divider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import List from "@mui/material/List";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Stack from "@mui/material/Stack";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import Div from "@jumbo/shared/Div";
import { LocationOnOutlined, PhoneOutlined } from '@mui/icons-material';
import returneeServices from 'app/services/returnee.service';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import { getBanglaNumber } from 'app/hooks/getBanglaNumber';

const ContactDetail = ({ contact, onClose }) => {

    const { t, i18n } = useTranslation();

    console.log({ contact });

    const { data, isLoading } = useQuery(['get-returnee-profile-chat', contact], () => returneeServices.getOneReturnee(parseInt(contact)), {
        enabled: !!contact
    })

    // const { data: dataImage, isLoading: isLoadingImage } = useQuery(['get-returnee-profile-image', data?.content?.profileLogoUrl], () => returneeServices.getReturneeProfilePicture(data?.content?.profileLogoUrl), {
    //     enabled: !!data?.content?.profileLogoUrl
    // })

    return (
        <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
            <CardHeader
                title={i18n.language == "bn-BD" ? data?.content?.nameBn : data?.content?.name}
                subheader={contact?.job_title}
                avatar={<Avatar src={data?.content?.profileLogoServer ? data?.content?.profileLogoServer  : ""} />}
                action={
                    <IconButton onClick={onClose}><CloseIcon /></IconButton>
                }
            />
            <List disablePadding >
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <PhoneOutlined sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} variant={"body1"} color={"text.secondary"} mb={.5}>{t("placeholder.edit-sp-profile.phone")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant={"h5"} mb={0}>{i18n.language == "bn-BD" ? getBanglaNumber(data?.content?.phoneNumber) : data?.content?.phoneNumber}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <MailOutlineIcon sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} fontFamily={getFont(i18n)} color={"text.secondary"} mb={.5}>{t("user.profile.ReturnInfo.from")}</Typography>}
                        secondary={<Typography variant={"h5"} fontFamily={getFont(i18n)} mb={0}>{data?.content?.returnFromCountry}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"} />
                <ListItem sx={{ px: 4 }}>
                    <ListItemAvatar sx={{ minWidth: 66 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <LocationOnOutlined sx={{ color: 'primary.light' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} variant={"body1"} color={"text.secondary"} mb={.5}>{t("user.profile.contacts.address-section.address")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant={"h5"} mb={0}>{data?.content?.addressFull}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"} />
            </List>
            {/* <Stack spacing={1} direction={"row"} sx={{px: 4, py: 2}}>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
            </Stack> */}
        </Div>
    );
};

export default ContactDetail;
