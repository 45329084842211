import React, { useState, useEffect } from 'react';
import { Card, CardContent, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { VisibilityOff } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import Header from './Header';
import { useJumboTheme } from '@jumbo/hooks';
import { AUTHORITY, USER_ID } from 'app/config/localStorageConfig';
import returneeServices from 'app/services/returnee.service';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import authServices from 'app/services/auth/auth.service';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';
import { LoadingButton } from '@mui/lab';

const MySwal = withReactContent(Swal)

const userId = localStorage.getItem(USER_ID);

const ChangePhoneNumber = () => {

    const { t, i18n } = useTranslation();
    const { theme } = useJumboTheme();
    const { setAuthToken } = useJumboAuth();
    const [isLoading, setisLoading] = useState(false);
    const [isRequested, setisRequested] = useState({
        "otpId": 0,
        "otpCode": "",
        "otpSecretKey": "",
        "otpExpTime": 5,
        "otpExpTimeUnit": "",
        "mobileNo": "",
        "changeHistoryId": 0
    });

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { data } = useQuery(['get-returnee-profile'], () => returneeServices.getReturneeDetails(userId))

    const queryClient = useQueryClient();

    const { mutate } = useMutation(returneeServices.returneeChangePhoneNumber, {
        onSuccess: (data) => {
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                setisRequested(data?.content)
                setisLoading(false);
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
            setisLoading(false);
        }
    });

    const { mutate: mutateUpdate } = useMutation(returneeServices.returneeChangePhoneNumberVerify, {
        onSuccess: (data) => {
            
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: true,
                html: <i>{data?.message}</i>,
            }).then(async (result) => {
                if (result.isConfirmed && data?.statusCode == 200) {
                    try {
                        const response = await authServices.signOut();
                        localStorage.removeItem(AUTHORITY);
                        window.location.assign("/");
                        setAuthToken(null);

                    } catch (error) {
                        console.log(error);
                    }
                }
            })
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('update');
            setisLoading(false);
        }
    });

    const formik = useFormik({
        initialValues: {
            "mobileNoOld": "",
            "mobileNoNew": "",
            "reasonOfChange": "",
            "otpCode": ""
        },
        onSubmit: values => {
            setisLoading(true);
            if (isRequested.otpSecretKey != "") {
                isRequested.otpCode = values.otpCode;
                mutateUpdate({ changeHistoryId: isRequested.changeHistoryId, data: isRequested })
            } else {
                MySwal.fire({
                    icon: "warning",
                    showConfirmButton: true,
                    html: <i>{"Your mobile number is your user id, to change this user id also changed. do you want to change?"}</i>,
                }).then((result) => {
                    if (result.isConfirmed) {
                        delete values.otpCode;
                        mutate(values)
                    }
                })

            }
        }
    })

    return (
        <JumboContentLayout
            header={<Header data={data?.content} isEdit={true} />}
            layoutOptions={{
                header: {
                    sx: {
                        mt: -4,
                        mb: -7.25,
                        mx: { xs: -4, lg: -6 },
                        p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                        //background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/profile-bg.jpg`,"1920x580")}) no-repeat center`,
                        // backgroundSize: 'cover',
                        backgroundImage: "linear-gradient(to right, #F53803, #F5D020)",
                        color: 'common.white',
                        position: 'relative',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            // backgroundColor: alpha(theme.palette.common.black, .65)
                        }
                    }
                },
                sidebar: {
                    sx: {
                        mr: 3.75,
                        width: { xs: '100%', lg: '33%' },
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0,
                            mr: 0,
                            order: 2
                        }
                    }
                },
                wrapper: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column'
                        }
                    }
                },
                main: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0
                        }
                    }
                }
            }}
        >
            <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto' }}>
                <Card
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        flexDirection: { xs: 'column', md: 'row' }
                    }}
                >
                    <CardContent sx={{ flex: 1, p: 4 }}>
                        <Typography fontFamily={getFont(i18n)} fontSize={20} variant={'body1'} color={'inherit'} mt={.5} mb={2}>
                            {t("change-number")}
                        </Typography>
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                flex: 1,
                                flexDirection: 'column',
                                height: '100%',
                                mb: 2
                            }}
                        >
                            <form onSubmit={formik.handleSubmit}>

                                {
                                    isRequested.otpSecretKey != "" ? (
                                        <>
                                            <Typography fontFamily={getFont(i18n)} variant='caption' color={"#A2B2C3"}>
                                                {t("signup.col2.p2")}
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                type={"text"}
                                                label={t("label.otp")}
                                                placeholder={t("placeholder.otp")}
                                                sx={{ mt: 2 }}
                                                inputProps={{
                                                    style: {
                                                        fontFamily: getFont(i18n)
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        fontFamily: getFont(i18n)
                                                    }
                                                }}
                                                {...formik.getFieldProps('otpCode')}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    label={t("placeholder.change-number.old")}
                                                    placeholder={t("placeholder.change-number.old")}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('mobileNoOld')}
                                                />
                                            </Div>

                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    label={t("placeholder.change-number.new")}
                                                    placeholder={t("placeholder.change-number.new")}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('mobileNoNew')}
                                                />
                                            </Div>
                                            <Div sx={{ mt: 1, mb: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    label={t("placeholder.change-number.reason")}
                                                    placeholder={t("placeholder.change-number.reason")}
                                                    inputProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily: getFont(i18n)
                                                        }
                                                    }}
                                                    {...formik.getFieldProps('reasonOfChange')}
                                                />
                                            </Div>
                                        </>
                                    )
                                }

                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ my: 3, fontFamily: getFont(i18n) }}
                                    disabled={!formik.dirty || formik.errors.otpCode}
                                    loading={isLoading}>
                                    {isRequested.otpSecretKey ? t("change-number") : t("button.proceed")}
                                </LoadingButton>
                            </form>
                        </Div>
                    </CardContent>
                </Card>

            </Div>
        </JumboContentLayout>
    );
};

export default ChangePhoneNumber;
