import Div from '@jumbo/shared/Div';
import { Grid, List, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import NewsCard from '../home/components/NewsCard';
import SectionTitle from '../home/components/SectionTitle';
import NewsList from './components/NewsList';
import { useQuery } from 'react-query';
import blogServices from 'app/services/blog.service';

const cardStyle = {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 2px",
    border: "1px solid white",
    borderRadius: "10px",
    padding: "10px",
    marginBottom: "18px"
}

const NewsandEvent = () => {

    const { data, isLoading } = useQuery(['get-blog-list'], () => blogServices.getBlogListPublic())

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Div sx={{ mx: "8vw", background: "#FFFFFF", mb: 2 }}>
            <SectionTitle title={"News and Blog"} />
            <Grid container spacing={{ xs: 2, md: 2 }} >
                <Grid item xs={12} >
                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ sm: 4, md: 8, lg: 8, xl: 12 }}>
                        {
                            !isLoading ? data?.content && data?.content?.map((item, i) => (
                                <Grid item xs={2} sm={4} md={4} key={i}>
                                    <NewsCard
                                        data={item}
                                    />
                                </Grid>
                            )) :
                                Array(3).fill(null).map((item, i) => (
                                    <Grid item xs={2} sm={4} md={4} key={i}>
                                        <NewsCard
                                            isLoading={isLoading}
                                        />
                                    </Grid>
                                ))
                        }

                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={3} md={3}>

                    <Div style={cardStyle}>
                        <Typography variant='h3' sx={{ ml: 1, mt: 1, mb: 0 }}>
                            Important Posts
                        </Typography>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {Array.from(Array(1)).map((_, index) => (
                                <NewsList key={index} />
                            ))}
                        </List>
                    </Div>

                    <Div style={cardStyle}>
                        <Typography variant='h3' sx={{ ml: 1, mt: 1, mb: 0 }}>
                            Recent Posts
                        </Typography>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {Array.from(Array(1)).map((_, index) => (
                                <NewsList key={index} />
                            ))}
                        </List>
                    </Div>

                </Grid> */}
            </Grid>
        </Div>
    )
}

export default NewsandEvent