import React from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import { mailServices } from "../../../../../services/mail-services";
import ConversationMessage from "./ConversationMessage";
import { useMutation } from "react-query";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import useMailsApp from "../../hooks/useMailsApp";
import Div from "@jumbo/shared/Div";
import messageServices from 'app/services/message.service';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const ConversationDetail = ({ mailID, conversation, setisUpdated }) => {
    const Swal = useSwalWrapper();
    const { t, i18n } = useTranslation();
    const [message, setMessage] = React.useState("");
    const { activeConversation } = useMailsApp();

    const renderConversationMessage = React.useCallback((conversation) => {
        const replyData = conversation;
        return replyData?.length != 0 && replyData?.map((item, i) => i !== 0 && (
            <ConversationMessage key={i} reply={item} />
        ))
    }, []);

    const mailConversationMutation = useMutation(messageServices.composeReply, {
        onSuccess: (data) => {
            setisUpdated(prev => prev + 1)
            activeConversation?.current?.refresh();
            Swal.fire({
                position: 'center',
                icon: data?.statusCode == 200 ? 'success' : 'error',
                title: data?.message,
                showConfirmButton: false,
                timer: 1500
            });
            setMessage("");
        }
    });

    const handleConversationMail = React.useCallback(() => {
        const lastMsg = (conversation?.length - 1)
        mailConversationMutation.mutate({
            id: mailID,
            providerInfoIdFrom: conversation?.[lastMsg]?.isProviderToProviderCommunication ? conversation?.[lastMsg]?.providerInfoFrom?.id : null,
            providerInfoIdTo: conversation?.[lastMsg]?.providerInfoTo?.id,
            returneeRegistrationId: !conversation?.[lastMsg]?.isProviderToProviderCommunication ? conversation?.[lastMsg]?.returneeRegistration?.id : null,
            communicationBody: message
        });
    });

    return (
        <React.Fragment>
            {/* <Div sx={{
                height: "150px",
                overflow: "auto"
            }}>
                {renderConversationMessage(conversation)}
            </Div> */}
            {renderConversationMessage(conversation)}
            <Div
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    alignItems: 'center',
                    paddingTop: "15px"
                }}
            >
                <TextField
                    placeholder={i18n.language == "bn-BD" ? "উত্তর পাঠান...." : "Send a reply...."}
                    size={"small"}
                    sx={{ flex: 1, }}
                    value={message}
                    inputProps={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            fontFamily: getFont(i18n)
                        }
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <Button variant={"contained"} sx={{ ml: 2, fontFamily: getFont(i18n) }} onClick={handleConversationMail}>{t("button.send")}</Button>
            </Div>
        </React.Fragment>
    );
};

export default ConversationDetail;
