import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import configureStore, { history } from './redux/store';
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import JumboAuthProvider from "@jumbo/components/JumboAuthProvider";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AUTHORITY } from "./config/localStorageConfig";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const store = configureStore();

function App() {

    React.useEffect(() => {
        const role = localStorage.getItem(AUTHORITY);
        const path = window.location.pathname;
        if (role == "RETURNEE_USER") {
            if (path?.includes("/admin/") || path?.includes("/service-provider/")) {
                window.location.assign("/")
            }
        }
        // else if (role == "ADMIN") {
        //     if (path?.includes("/user/") || path?.includes("/service-provider/")) {
        //         window.location.assign("/")
        //     }
        // } 
        else if (role == "PROVIDER") {
            if (path?.includes("/admin/") || path?.includes("/user/")) {
                window.location.assign("/")
            }
        }
    }, []);


    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter history={history}>
                    <AppProvider>
                        <JumboApp activeLayout={config.defaultLayout}>
                            <JumboTheme init={config.theme}>
                                <JumboRTL>
                                    <JumboDialogProvider>
                                        <JumboDialog />
                                        <SnackbarProvider
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            maxSnack={3}>
                                            <JumboAuthProvider>
                                                <React.StrictMode>
                                                    <AppLayout>
                                                        <Suspense
                                                            fallback={
                                                                <Div
                                                                    sx={{
                                                                        display: 'flex',
                                                                        minWidth: 0,
                                                                        alignItems: 'center',
                                                                        alignContent: 'center',
                                                                        height: '100%',
                                                                    }}
                                                                >
                                                                    <CircularProgress color={"warning"} sx={{ m: '-40px auto 0' }} />
                                                                </Div>
                                                            }
                                                        >
                                                            <DndProvider backend={HTML5Backend} >
                                                                <AppRoutes />
                                                            </DndProvider>
                                                        </Suspense>
                                                    </AppLayout>
                                                </React.StrictMode>
                                            </JumboAuthProvider>
                                        </SnackbarProvider>
                                    </JumboDialogProvider>
                                </JumboRTL>
                            </JumboTheme>
                        </JumboApp>
                    </AppProvider>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
