import Div from "@jumbo/shared/Div";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Chip, Link, Typography } from "@mui/material";
import { CATEGORY, PROVIDER } from "app/config/localStorageConfig";
import { getFont } from "app/hooks/getFont";
import publicServices from "app/services/public/public.service";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const OrganisationCard = ({ data, page }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isHover, setIsHover] = useState(false);
  const [subCategoryList, setsubCategoryList] = useState([]);

  // const { data: dataLogo, isLoading } = useQuery(["get-provider-logo-image", data?.logoImageUrl], () => publicServices.getImage(data?.logoImageUrl), {
  //     enabled: !!data?.logoImageUrl
  // });

  const cardStyle = {
    background: "#FFFFFF",
    boxShadow: isHover
      ? "0px 8px 20px rgb(0 73 203 / 12%)"
      : "0px 0px 2px rgba(0, 0, 0, 0.25)",
    border: isHover ? "2px solid #FBAD1D" : "2px solid white",
    borderRadius: "10px",
    padding: "15px",
    textAlign: "center",
    height: "100%",
    overflow: "hidden",
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <Div
      style={cardStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Div className="square-image">
        <img
          src={
            data?.logoImageServer
              ? data?.logoImageServer
              : `${window.location.origin}/images/logo/noLogo.png`
          }
          height={"40px"}
          loading="lazy"
        />
      </Div>
      <Link
        to={data?.profileId ? `/serviceProvider/${data?.profileId}` : ""}
        component={RouterLink}
        underline={"none"}
        sx={{ display: "flex", justifyContent: "center", mt: 1 }}
      >
        <Typography variant="h5" fontFamily={getFont(i18n)} color={"#A7A7A7"}>
          {i18n.language == "bn-BD" ? data?.info_name_bn : data?.info_name}
        </Typography>
        {data?.profileId && (
          <ArrowForwardIosOutlined
            sx={{
              fill: "#A7A7A7",
              fontSize: "13px",
              marginTop: "0.15rem",
              marginLeft: "0.3rem",
            }}
          />
        )}
      </Link>
      {(i18n.language == "bn-BD"
        ? data?.service_category_name_bn
        : data?.service_category_name) && (
        <Div sx={{ display: "flex", flexWrap: "wrap" }}>
          {[...new Set(data?.service_category_obj?.map((item) => item.name))]
            .map((name) => {
              return data?.service_category_obj?.find(
                (item) => item.name === name
              );
            })
            .map((item, i) =>
              i < 3 ? (
                <Chip
                  key={i}
                  label={i18n.language == "bn-BD" ? item?.nameBn : item?.name}
                  variant="outlined"
                  size="small"
                  sx={{
                    fontFamily: getFont(i18n),
                    mr: 1,
                    mb: 1,
                    borderColor: "#A7A7A7",
                  }}
                  onClick={() => {
                    navigate(`/service-list`);
                    localStorage.setItem(PROVIDER, data?.info_id);
                    localStorage.setItem(CATEGORY, item?.cat_id);
                  }}
                />
              ) : (
                ""
              )
            )}
        </Div>
      )}
    </Div>
  );
};

export default OrganisationCard;
