import { Table, TableContainer, Typography, TableHead, TableRow, TableCell, TableBody, Paper, Stack, Button, Modal, Box, FormControl, TextField, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import providerServices from 'app/services/provider/provider.service';
import { AddCircleOutline, Delete, Edit, People } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Div from '@jumbo/shared/Div/Div';
import JumboSearch from '@jumbo/components/JumboSearch/JumboSearch';
import { useFormik } from 'formik';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import adminServices from 'app/services/admin.service';
import moment from 'moment';

const MySwal = withReactContent(Swal)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid white',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

const UserList = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [list, setlist] = useState([]);
    const [searchText, setsearchText] = useState("");
    const [isedit, setisedit] = useState(null);
    const [isUpdated, setisUpdated] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const queryClient = useQueryClient();

    const { mutate } = useMutation(providerServices.createProvider, {
        onSuccess: (data) => {
            handleClose()
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                setisUpdated(prev => prev + 1)
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const { mutate: mutateUpdate } = useMutation(providerServices.updateProvider, {
        onSuccess: (data) => {
            handleClose()
            MySwal.fire({
                icon: data?.statusCode == 200 ? 'success' : 'error',
                showConfirmButton: false,
                html: <i>{data?.message}</i>,
                timer: 1500
            })
            if (data?.statusCode == 200) {
                setisUpdated(prev => prev + 1)
            }
        },
        onError: () => {
            MySwal.fire({
                title: <strong>{"There was an error"}</strong>,
                html: <i>{"Please, contact with customer support."}</i>,
                icon: 'error',
                allowOutsideClick: false
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        }
    });

    const { data } = useQuery(['get-user-list', isUpdated], () => adminServices.getAllUserList(), {
        onSuccess: data => {
            setlist(data?.content)
        }
    })

    const SearchAnything = (value) => {
        setsearchText(value);
        try {
            const result = data?.content?.filter((obj) =>
                JSON.stringify(obj)?.toLowerCase()?.includes(value.toLowerCase())
            )
            setlist(result);
        } catch (error) {

        }
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
        },
        onSubmit: values => {
            console.log(values);
            if (isedit) {
                mutateUpdate({ id: isedit, data: values })
            } else {
                mutate(values)
            }
        }
    })

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            event.stopPropagation();
            return false;
        } else {
            setOpen(false);
            formik.resetForm();
            setisedit(null);
        }

    };

    return (
        <div>
            <Stack sx={{ mb: 2 }} direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                <Typography variant={'h2'} mb={3}>User List</Typography>
                <Div>
                    <Button variant="contained" startIcon={<AddCircleOutline />} onClick={handleOpen} >
                        Add New
                    </Button>
                </Div>
            </Stack>
            <TableContainer component={Paper}>
                <Stack justifyContent={"space-between"} alignItems={"center"}>
                    <Div>

                    </Div>
                    <JumboSearch
                        sx={{ ml: "auto", my: 1, mr: 1, width: "25%" }}
                        onChange={SearchAnything}
                    />
                </Stack>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>id#</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Registered By</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Registered Time</TableCell>
                            <TableCell>Status</TableCell>
                            {/* <TableCell align='center'>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.userId}
                                </TableCell>
                                <TableCell >{row.username}</TableCell>
                                <TableCell>{row.usernameBy}</TableCell>
                                <TableCell>{row.authority}</TableCell>
                                <TableCell>{moment(row.createdAt).format("DD MMMM YYYY, h:mm:ss a")}</TableCell>
                                <TableCell>{row.isActive ? (
                                    <Chip label="Active" size="small" color="success" />
                                ) : (
                                    <Chip label="Inactive" size="small" color="error" />
                                )}</TableCell>
                                {/* <TableCell align='center'>
                                    <Button
                                        startIcon={<Delete />}
                                        onClick={() => {
                                            setisedit(row.id);
                                        }}
                                        color='error'
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        startIcon={<Edit />}
                                        sx={{ mx: 2 }}
                                        onClick={() => {
                                            formik.setValues(row);
                                            handleOpen();
                                            setisedit(row.id);
                                        }}>
                                        Edit
                                    </Button>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ mb: 2 }}>
                        {isedit ? "Update User" : "Add New User"}
                    </Typography>
                    <form onSubmit={formik.handleSubmit} >
                        <Div sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                label="Name"
                                {...formik.getFieldProps('username')}
                            />
                        </Div>
                        <Div sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                label="Description"
                                {...formik.getFieldProps('description')}
                            />
                        </Div>

                        <Stack direction={"row"} spacing={2}>
                            <Button
                                fullWidth
                                type="button"
                                variant="contained"
                                size="large"
                                color='error'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                            >
                                {isedit ? "Update" : "Add"}
                            </LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Modal>

        </div>
    )
}

export default UserList;