import React from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from 'react-i18next';
import { getFont } from 'app/hooks/getFont';

const Biography = ({ data }) => {
    const { t, i18n } = useTranslation();
    return (
        <JumboCardQuick
            title={i18n.language == "bn-BD" ? "পারিবারিক তথ্য" : "Family Information"}
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{ mb: 3.75 }}
        >
            <List style={{ marginTop: "-15px" }} disablePadding>
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary">{t("user.profile.biography.Father's Name")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.fathersName ? data?.fathersName : "---"}</Typography>}
                    />
                </ListItem>

                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary">{t("user.profile.biography.Mother's Name")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.mothersName ? data?.mothersName : "---"}</Typography>}
                    />
                </ListItem>

                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.secondary">{t("user.profile.biography.Spouse's Name")}</Typography>}
                        secondary={<Typography fontFamily={getFont(i18n)} variant="body1" color="text.primary">{data?.spouseName ? data?.spouseName : "---"}</Typography>}
                    />
                </ListItem>

            </List>
        </JumboCardQuick>
    );
};

export default Biography;
