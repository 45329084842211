import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Div from "@jumbo/shared/Div";
import Span from "@jumbo/shared/Span";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Grow,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SearchBox from "app/shared/SearchBox";
import React, { useState, useEffect } from "react";
import ServiceCard from "./components/ServiceCard";
import ServiceSidebar from "./components/ServiceSidebar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useJumboTheme } from "@jumbo/hooks";
import { useSearchParams } from "react-router-dom";
import {
  CATEGORY,
  LOCATION,
  L_TEXT,
  PROVIDER,
  SEARCH_TEXT,
  SELECTED_SUBCATEGORY,
  SUBCATEGORY,
} from "app/config/localStorageConfig";
import { useQuery } from "react-query";
import serviceServices from "app/services/service.service";
import { useTranslation } from "react-i18next";
import { getFont } from "app/hooks/getFont";
import { LNG } from "app/config/localStorageConfig";
import { bgBlogStyle } from "../home/Home";
import Caricuture from "app/shared/Caricuture/Caricuture";

const ServiceList = () => {
  const { theme } = useJumboTheme();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery("(max-width:575px)");
  let [searchParams, setSearchParams] = useSearchParams();

  const subCategory = JSON.parse(localStorage.getItem(SUBCATEGORY));
  const category = localStorage.getItem(CATEGORY);
  const select_subcategory = localStorage.getItem(SELECTED_SUBCATEGORY);
  const provider = localStorage.getItem(PROVIDER);
  const location = localStorage.getItem(LOCATION);
  const searchText = localStorage.getItem(SEARCH_TEXT);
  const locationText = localStorage.getItem(L_TEXT);
  const language = JSON.parse(localStorage.getItem(LNG));

  const [subCategoryList, setsubCategoryList] = useState([]);
  const [selectedSubCategory, setselectedSubCategory] = useState(0);
  const [selectedCategory, setselectedCategory] = useState(0);
  const [selectedProvider, setselectedProvider] = useState(0);
  const [selectedLocation, setselectedLocation] = useState(0);
  const [selectedLanguage, setselectedLanguage] = useState(1);
  const [categoryFilter, setcategoryFilter] = useState("");
  const [providerFilter, setproviderFilter] = useState("");
  const [locationFilter, setlocationFilter] = useState("");
  const [isRender, setisRender] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const CaricutureData = [
    {
      title:
        'আপনি যদি কোনো সেবার বিস্তারিত দেখতে চান, তাহলে "View Details" বাটনে ক্লিক করুন।',
      titleLink: "",
      link: "",
    },
    // {
    //     title: "আপনি যদি সবগুলো সেবা প্রদানকারী প্রতিষ্ঠান দেখতে চান?",
    //     titleLink: "তাহলে এখানে ক্লিক করুন।",
    //     link: "/serviceProvider/list"
    // },
  ];

  const { data, isLoading } = useQuery(
    [
      "get-service-short",
      selectedSubCategory,
      selectedCategory,
      selectedProvider,
      selectedLocation,
      selectedLanguage,
      searchText,
      locationText,
      limit,
      page,
    ],
    () =>
      serviceServices.getShortServiceList({
        isFilter: 0,
        locationId: parseInt(selectedLocation) ? parseInt(selectedLocation) : 0,
        categoryId: parseInt(selectedCategory) ? parseInt(selectedCategory) : 0,
        subcategoryId: parseInt(selectedSubCategory)
          ? parseInt(selectedSubCategory)
          : 0,
        providerInfoId: parseInt(selectedProvider)
          ? parseInt(selectedProvider)
          : 0,
        locationName: locationText ? locationText : "",
        serviceName: searchText ? searchText : "",
        serviceLanguage: selectedLanguage,
        offset: page,
        max: limit,
      }),
    {
      enabled: isRender,
    }
  );

  React.useEffect(() => {
    setsubCategoryList(subCategory);
    setselectedCategory(category);
    setselectedProvider(provider);
    setselectedLocation(location);
    if (language?.id) {
      setselectedLanguage(language?.id);
    }
    setisRender(true);
  }, [subCategory, category, provider, location, language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
      <Div sx={{ mx: "8vw", background: "#FFFFFF" }}>
        <Div
          sx={{
            px: isMobile ? "2vw" : "18vw",
            py: "50px",
            mb: 5,
            background: "#F2F6FD",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mx: "auto",
            width: "100%",
          }}
        >
          {i18n.language == "bn-BD" ? (
            <>
              <Typography
                fontFamily={"'Baloo Da 2',cursive"}
                fontSize={"34px"}
                fontWeight={600}
                color={"#0C4CA3"}
                lineHeight={"40px"}
              >
                <Span className="heading2">সঠিক তথ্য পেতে</Span> সবচেয়ে{" "}
                <Span className="heading1">সহজ</Span> উপায়
              </Typography>
            </>
          ) : (
            <>
              <Typography
                fontSize={"34px"}
                fontWeight={600}
                color={"#0C4CA3"}
                letterSpacing={"-1px"}
                lineHeight={"35px"}
              >
                The <Span className="heading1">Easiest</Span> Way
              </Typography>
              <Typography
                className="heading2"
                fontSize={"34px"}
                fontWeight={600}
                letterSpacing={"-1px"}
                lineHeight={"35px"}
              >
                to Get Your Right Information
              </Typography>
            </>
          )}
          <Typography
            textAlign={"center"}
            variant="h5"
            mt={2}
            sx={{ fontFamily: getFont(i18n), lineHeight: "20px" }}
          >
            <strong>{t("title")}</strong>
            {t("home.description")}
          </Typography>
          <Div sx={{ mt: 5 }}>
            <SearchBox />
          </Div>
        </Div>

        <div className="stickySide">
          <JumboContentLayout
            sidebar={
              isMobile ? (
                ""
              ) : (
                <ServiceSidebar
                  setcategoryFilter={setcategoryFilter}
                  setproviderFilter={setproviderFilter}
                  setlocationFilter={setlocationFilter}
                />
              )
            }
            layoutOptions={{
              header: {
                sx: {
                  mt: -4,
                  mb: -7.25,
                  mx: { xs: -4, lg: -6 },
                  p: {
                    xs: theme.spacing(6, 4, 11),
                    lg: theme.spacing(6, 6, 11),
                  },
                  color: "common.white",
                  position: "relative",

                  "&::after": {
                    display: "inline-block",
                    position: "absolute",
                    content: `''`,
                    inset: 0,
                  },
                },
              },
              sidebar: {
                sx: {
                  mr: 3.75,
                  width: { xs: "100%", lg: "25%" },
                  [theme.breakpoints.down("lg")]: {
                    minHeight: 0,
                    mr: 0,
                    order: 1,
                  },
                },
              },
              wrapper: {
                sx: {
                  [theme.breakpoints.down("lg")]: {
                    flexDirection: "column",
                  },
                },
              },
              main: {
                sx: {
                  [theme.breakpoints.down("lg")]: {
                    minHeight: 0,
                  },
                },
              },
            }}
          >
            <Div
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontFamily={getFont(i18n)}
                variant="body2"
                color={"#999999"}
              >
                {i18n.language == "bn-BD"
                  ? `মোট ${data?.content?.length}টি সেবা`
                  : `Total ${data?.content?.length} services`}
              </Typography>
              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={1}
                alignItems={"center"}
              >
                <Typography fontFamily={getFont(i18n)} variant="body2">
                  {i18n.language == "bn-BD" ? "ফিল্টারডঃ" : "Filtered:"}
                </Typography>
                <Chip
                  color="warning"
                  variant={locationFilter ? "filled" : "outlined"}
                  label={
                    <Typography fontFamily={getFont(i18n)}>
                      {locationFilter
                        ? locationFilter
                        : i18n.language == "bn-BD"
                        ? "যেকোনো এলাকা"
                        : "Any Location"}
                    </Typography>
                  }
                />
                <Chip
                  color="warning"
                  variant={categoryFilter ? "filled" : "outlined"}
                  label={
                    <Typography fontFamily={getFont(i18n)}>
                      {categoryFilter
                        ? categoryFilter
                        : i18n.language == "bn-BD"
                        ? "যেকোনো ক্যাটাগরি"
                        : "All Categories"}
                    </Typography>
                  }
                />
                <Chip
                  color="warning"
                  variant={providerFilter ? "filled" : "outlined"}
                  label={
                    <Typography fontFamily={getFont(i18n)}>
                      {providerFilter
                        ? providerFilter
                        : i18n.language == "bn-BD"
                        ? "যেকোনো সেবা প্রদানকারী"
                        : "All Providers"}
                    </Typography>
                  }
                />
              </Stack>
            </Div>

            <Divider sx={{ my: 2 }} />

            <Div>
              {category != 0 &&
                subCategoryList?.length != 0 &&
                subCategoryList?.map((item, i) => (
                  <a key={i} onClick={() => setselectedSubCategory(item?.id)}>
                    <Chip
                      label={
                        <Typography fontFamily={getFont(i18n)}>
                          {i18n.language == "bn-BD" ? item?.nameBn : item?.name}
                        </Typography>
                      }
                      sx={{
                        background:
                          selectedSubCategory == item?.id && "#FBAD1D",
                        color: selectedSubCategory == item?.id && "white",
                        mr: 1,
                        mb: 1,
                      }}
                    />
                  </a>
                ))}
            </Div>

            <Stack width={"100%"} direction={"row"} justifyContent={"flex-end"}>
              <FormControl sx={{ mb: 2, width: 80 }} size="small">
                <InputLabel id="demo-select-small-label">Limit</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={limit}
                  label="Limit"
                  onChange={handleLimitChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Grid
              container
              spacing={{ xs: 2 }}
              columns={{ xs: 2 }}
              sx={{ mb: 3 }}
            >
              {isLoading ? (
                Array(4)
                  .fill(null)
                  .map((_, i) => (
                    <Grid item xs={2} sm={4} md={4} key={i}>
                      <ServiceCard data={null} isLoading={true} />
                    </Grid>
                  ))
              ) : data?.content?.length != 0 ? (
                data?.content?.map((item, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <ServiceCard data={item} />
                  </Grid>
                ))
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minWidth="100%"
                  minHeight="20vh"
                >
                  <Typography variant="h4">No data found!</Typography>
                </Box>
              )}

              <Stack width={"100%"} direction={"row"} my={2}>
                <Pagination
                  count={data?.totalCount}
                  page={page}
                  onChange={handleChange}
                  sx={{ marginX: "auto" }}
                />
              </Stack>
            </Grid>
          </JumboContentLayout>
        </div>
        <Caricuture data={CaricutureData} />
      </Div>
    </Grow>
  );
};

export default ServiceList;
