import React, { Suspense, useState, useEffect } from 'react';
import { Button, IconButton, Link, Typography } from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import { Link as RouterLink } from 'react-router-dom';
import { SidebarExpandIcon, SidebarShrinkIcon } from 'app/utils/icon/icon';
import menusProvider from './menusProvider';
import menusAdmin from './menusAdmin';
import { AUTHORITY } from 'app/config/localStorageConfig';

const Sidebar = () => {

    const [isSidebarOpen, setisSidebarOpen] = useState(false);
    const [userRole, setuserRole] = useState(0);

    useEffect(() => {
        const role = localStorage.getItem(AUTHORITY);
        setuserRole(role);
    }, []);

    return (
        <React.Fragment>
            {/* <SidebarHeader/> */}
            <Div sx={{ height: "90px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Link to={"/"} component={RouterLink} underline="none">
                    {/* <Typography fontSize={"25px"} color={"#FBAD1D"} fontWeight={600} >
                        বিদেশ ফেরত
                    </Typography> */}
                    <img src={`${window.location.origin}/images/logo.png`} height={40} />
                </Link>
                {/* <IconButton sx={{ ml: 2 }} onClick={()=>setisSidebarOpen(!isSidebarOpen)}>
                    {isSidebarOpen ? <SidebarShrinkIcon /> : <SidebarExpandIcon />}
                </IconButton> */}
            </Div>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3
                            }}
                        >
                            <SidebarSkeleton />
                        </Div>
                    }
                >
                    <JumboVerticalNavbar translate items={userRole == "PROVIDER" ? menusProvider : userRole == "ADMIN" ? menusAdmin : menus} />
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
    const { sidebarTheme } = useJumboSidebarTheme();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);


    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader>
                    <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
                    {
                        sidebarOptions?.view !== SIDEBAR_VIEWS.MINI &&
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ ml: 0, mr: -1.5 }}
                                onClick={() => setSidebarOptions({ open: false })}
                            >
                                <MenuOpenIcon />
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};

export default Sidebar;
