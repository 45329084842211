import React from 'react';
import Div from "@jumbo/shared/Div";
import { Link, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link as RouterLink } from 'react-router-dom';

const Error404 = () => {

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            <Typography
                variant={"h1"}
                fontWeight="500"
                sx={{ fontSize: 150, textShadow: '1rem 0.6rem 1rem rgba(0, 0, 0, 0.35)', mb: 1 }}
            >404</Typography>
            <Typography
                component={"h2"}
                variant={"h1"}
                color={"text.secondary"}
                mb={4}
            >Sorry, No page found! </Typography>
            <Link component={RouterLink} to={"/"} underline='none'>
                <Button variant="contained">Go to home</Button>
            </Link>
        </Div>
    );
};

export default Error404;
