import Div from '@jumbo/shared/Div';
import { Typography } from '@mui/material';
import { getFont } from 'app/hooks/getFont';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const titleStyle = {
    '&::before': {
        content: '""',
        height: "3px",
        width: "80px",
        borderRadius: "3px",
        backgroundColor: "#0C4CA3",
        position: "absolute",
        bottom: "-10px",
        left: "0px",
        right: "0px",
        marginLeft: "auto",
        marginRight: "auto"
    }
}

const SectionTitle = ({ title }) => {

    const { t, i18n } = useTranslation();

    return (
        <Div sx={{ my: 3, position: "relative" }}>
            <Typography fontFamily={getFont(i18n)} sx={titleStyle} textAlign={"center"} fontSize={"22px"} fontWeight={500}>
                {title}
            </Typography>
        </Div>
    )
}

export default SectionTitle;